import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { About, Home, NotFound, References } from "features";
import { PagesRoutes } from "features/constants";
import {
  Glossario,
  Post01,
  Post02,
  Post03,
  Post04,
  Post05,
  Post06,
  Post07,
  Quizz,
} from "features/Posts";

export const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={PagesRoutes.HOME} element={<Home />} />
      <Route path={PagesRoutes.ABOUT} element={<About />} />
      <Route path={PagesRoutes.REFERENCES} element={<References />} />
      <Route path={PagesRoutes.POST01} element={<Post01 />} />
      <Route path={PagesRoutes.POST02} element={<Post02 />} />
      <Route path={PagesRoutes.POST03} element={<Post03 />} />
      <Route path={PagesRoutes.POST04} element={<Post04 />} />
      <Route path={PagesRoutes.POST05} element={<Post05 />} />
      <Route path={PagesRoutes.POST06} element={<Post06 />} />
      <Route path={PagesRoutes.POST07} element={<Post07 />} />
      <Route path={PagesRoutes.QUIZZ} element={<Quizz />} />
      <Route path={PagesRoutes.GLOSSARIO} element={<Glossario />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
