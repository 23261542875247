import { styled } from "@mui/system";
import { Box } from "@mui/material";
import ImageBG from "assets/images/header-bg.png";
import "utils/variables.css";

export const HeaderWrapper = styled(Box)({
  backgroundImage: `url(${ImageBG})`,
  zIndex: 0,
  backgroundRepeat: "no-repeat",
  position: "relative",
  // filter: "brightness(0.8)",
  top: 0,
  left: 0,
  right: 0,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  height: "98vh",
  "@media (max-width: 1000px)": {
    height: "80vh",
  },
});
export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 100px)",
  justifyContent: "center",
  padding: "0rem 4rem",
  filter: "brightness(1)",
  "@media (max-width: 1000px)": {
    // padding: "0rem 2rem",
  },
  "@media (max-width: 780px)": {
    maxWidth: "90vw",
    padding: "0rem 2rem",
  },
  "@media (max-width: 350px)": {
    // padding: "0rem 1rem",
  },
});
export const Informs = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  // margin: "0rem 3rem",
  gap: "1.5rem",
  "& button": {
    fontFamily: 'Arial',
    maxWidth: "200px",
    minWidth: "120px",
    width: "50%",
  },
  "& .subtitle": {
    maxWidth: "35%",
    "@media (max-width: 850px)": {
      maxWidth: "40%",
    },
    "@media (max-width: 650px)": {
      maxWidth: "50%",
    },
    "@media (max-width: 500px)": {
      maxWidth: "60%",
    },
    "@media (max-width: 400px)": {
      maxWidth: "70%",
    },
    "@media (max-width: 350px)": {
      maxWidth: "80%",
    },
    "@media (max-width: 300px)": {
      maxWidth: "90%",
    },
    "@media (max-width: 250px)": {
      maxWidth: "100%",
    },
  },
});
