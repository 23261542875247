import { styled } from "@mui/system";
import { Box, IconButton, Menu } from "@mui/material";
// import Shadow from "assets/icons/shadow.png";
export const VideoWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  "@media (max-width: 500px)": {
    padding: "1rem",
  },
});
export const VideoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "80%",
  "@media (max-width: 500px)": {
    maxWidth: "100%",
  },
});
export const Player = styled("video")({
  zIndex: 1,
  maxWidth: "100%",
  maxHeight: "100%",
});
export const ControlsContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  height: "7vh",
  gap: "1rem",
  boxShadow: "0px 0px 6px 1px rgba(0,0,0,0.5)",
  position: "relative",
  zIndex: 2,
  "@media (max-width: 800px)": {
    gap: "0.5rem",
  },
  "@media (max-width: 500px)": {
    gap: "0.2rem",
  },
  "@media (max-width: 300px)": {
    gap: "0rem",
  },
  "@media (min-width: 781px)": {
    // "&:after": {
    //   zIndex: 1,
    //   content: '""',
    //   position: "absolute",
    //   bottom: "-7vh",
    //   left: 0,
    //   right: 0,
    //   width: "100%",
    //   height: "100%",
    //   backgroundImage: `url(${Shadow})`,
    //   backgroundPosition: "center",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "90vw 10vh",
    // },
  },
});

export const ButtonWithIcon = styled(IconButton)({
  color: "#000000",
  "&.white": {
    color: "#ffffff",
  },
  "& svg": {
    fontSize: "2.5rem",
    "@media (max-width: 680px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width: 300px)": {
      fontSize: "1rem",
    },
  },
});

export const MenuVol = styled(Menu)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "50vw",
  maxHeight: "50vh",
  "& .MuiMenu-paper": {
    backgroundColor: "transparent",
  },
  "& .MuiMenuItem-root#volume": {
    color: "white",
    borderRadius: "10px",
    padding: "10px",
    height: "100px",
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiMenu-paper .MuiList-padding": {
    backgroundColor: "rgba(0,0,0,0.8)",
    color: "white",
    borderRadius: "10px",
  },
});
export const SubMenu = styled(Menu)({
  backgroundColor: "transparent",
  "& .MuiMenu-paper": {
    backgroundColor: "rgba(255,255,255,0.7)",
    borderRadius: "10px",
  },
});
export const SpeedButton = styled("button")({
  color: "#000000",
  textAlign: "center",
  width: "100%",
  border: "none",
  backgroundColor: "transparent",
  "&.white": {
    color: "#ffffff",
  },
});
