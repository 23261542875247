import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";



export const PostWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  height: "100%",
  backgroundColor: "var(--gray-bg)",
  padding: "2rem 0rem",
});

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "2rem",
  width: "calc(90% - 6rem)",
  backgroundColor: "var(--light-gb)",
  padding: "3rem",
  "&.forum": {
    alignItems: "center",
  },
  "@media (max-width: 780px)": {
    width: "calc(90% - 4rem)",
    padding: "2rem",
  },
  "@media (max-width: 550px)": {
    width: "calc(100% - 4rem)",
    padding: "2rem",
    borderRadius: "30px 30px 0px 0px",
  },
})


export const WrapperGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  maxWidth: "90%",
  padding: "2rem 0px",
  textAlign: "justify",
  gridAutoRows: "minmax(50px, auto)",
  rowGap: "1.5rem",
  justifyItems: "center",
  "& h1": {
    gridRow: "1/2",
    gridColumn: "1/2",
    textAlign: "left",
    "&.right-top": {
      gridColumn: "2/3",
    },
  },"&.left": {
    maxWidth: "100%",
    gridTemplateColumns: "3fr 1fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
      "& .image-container": {
        gridRow: "1/2",
        justifySelf: "center",

      }
    }
  },
  "&.right": {
    maxWidth: "100%",
    gridTemplateColumns: "1fr 3fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
    }
  },
  "&.left p and .right p": {
    textAlign: "justify",
    alignSelf: "center",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr",
    "& h1": {
      textAlign: "center",
      "&.right-top": {
        gridColumn: "1/2",
      },
    },
    
    "& .text-container": {
      gridRow: "3/4",
      gridColumn: "1/2",
    },
  },
  "@media (max-width: 480px)": {
    "& .image": {
      display: "none",
    },
  },
});

export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  maxWidth: "50%",
  minWidth: "500px",
  height: "100%",
  gap: "1rem",
  "& .container": {
    padding: "0px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
  },
});

export const Image = styled("img")({
  width: "100%",
  maxHeight: "500px",
  maxWidth: "500px",
  cursor: "pointer",
  alignSelf:"center",
  "&.modal": {
    height: "100%",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
});

export const NoticesCardsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "2rem",
  maxWidth: "100%",
  padding: "2rem 5%",
  background: "var(--gray-bg)",
  "&.about-me": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    padding: "2rem 10rem",
    "@media (max-width: 1500px)": {
      padding: "2rem 5rem",
    },
    "@media (max-width: 1200px)": {
      padding: "2rem 2rem",
    },
  },
  "& .title-pub": {
    justifySelf: "center",
    gridColumn: "1/4",
    padding: "2rem 0px",
    width: "60%",
  },
  "& .button-center": {
    justifySelf: "center",
    gridColumn: "1/4",
  },
  "@media (max-width: 880px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    "& .title-pub": {
      gridColumn: "1/3",
      width: "100%",
    },
    "& .button-center": {
      justifySelf: "center",
      gridColumn: "1/3",
    },
  },
  "@media (max-width: 550px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    "& .title-pub": {
      gridColumn: "1/2",
    },
    "& .button-center": {
      gridColumn: "1/2",
    },
  },
});

export const List = styled("ul")({
  listStyle: "disc",
  paddingLeft: "1rem",
});
export const ListItem = styled("li")({
  fontFamily: "var(--font)",
    color: "var(--text-dark)",
    fontSize: "var(--text-size)",
    fontWeight: "var(--text-weight)",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    padding: "1rem",
    "&.bold":{ fontWeigh: "bold"},
    "&.title": {
        fontWeight: "var(--title-weight)",
    },
    "&.title-left": {
        fontWeight: "var(--title-weight)",
        textAlign: "left",
    },
    "&.white": {
        color: "var(--light-textcolor)",
    },
    "&.center": {
        textAlign: "center",
    },
    "&.center-justify-grid": {
        textAlign: "justify",
        alignSelf: "center",
    },
    "&.justify": {
        textAlign: "justify",
    },
    "@media (min-width: 2000px)": {
        fontSize: "3.5rem",
    },
    "@media (min-width: 1600px)": {
        fontSize: "2.5rem",
    },
    "@media (min-width: 1500px)": {
        fontSize: "2rem",
    },
    "@media (min-width: 1400px)": {
        fontSize: "1.7rem",
    },
    "@media (min-width: 1300px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 630px)": {
        fontSize: "1.3rem",
    },
    "@media (max-width: 500px)": {
        textAlign: "center",
    },
});




// export const MonitoramentoWrapper = styled(Box)({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "flex-start",
//   padding: "2rem 2rem",
//   gap: "1rem",
//   "@media (max-width: 820px)": {
//     padding: "2rem 2rem",
//     flexDirection: "column",
//     alignItems: "center",
//   },
// });
// export const TextWrapper = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "flex-start",
//   alignItems: "flex-start",
//   maxWidth: "50%",
//   minWidth: "500px",
//   height: "100%",
//   gap: "1rem",
//   "& .container": {
//     padding: "0px",
//     margin: "0px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//   },
//   "@media (max-width: 820px)": {
//     width: "90%",
//     minWidth: "90%",
//     alignItems: "center",
//   },
// });
// export const Title = styled(Typography)({
//   fontFamily: "Avenir, sans-serif",
//   fontSize: "2rem",
//   fontWeight: "bold",
//   color: "#605E5E",
//   textAlign: "left",
//   "@media (max-width: 820px)": {
//     textAlign: "center",
//   },
//   "@media (max-width: 620px)": {
//     fontSize: "1.5rem",
//   },
//   "@media (max-width: 320px)": {
//     fontSize: "1.2rem",
//   },
// });
// export const Text = styled(Typography)({
//   fontFamily: "Avenir, sans-serif",
//   fontSize: "1rem",
//   fontWeight: "normal",
//   color: "#605E5E",
//   textAlign: "justify",
//   "&.bold": {
//     fontWeight: "bold",
//   },
//   "&.link":{
//     textAlign: "center",
//     padding: "1rem 0",
//     fontSize: "1.2rem",
//     fontWeight: 700,
//     "& a": {
//       color: "rgb(32, 126,169)",
//       textDecoration: "none",
//       "&:hover": {
//         textDecoration: "underline",
//       },
//     },
//   },
//   "& span.bold": {
//     fontWeight: "bold",
//   },
//   "@media (max-width: 820px)": {
//     textAlign: "center",
//   },
//   "@media (max-width: 620px)": {
//     fontSize: "0.9rem",
//   },
//   "@media (max-width: 320px)": {
//     fontSize: "0.8rem",
//   },
// });
// export const Image = styled("img")({
//   alignSelf: "center",
//   width: "100%",
//   "&.small": {
//     width: "30%",
//     "@media (max-width: 400px)": {
//       width: "50%",
//     },
//   },
//   "&.medium": {
//     width: "60%",
//     "@media (max-width: 400px)": {
//       width: "80%",
//     },
//   },
// });
// export const Table = styled("table")({
//   width: "100%",
//   borderStyle: "solid",
//   borderWidth: "1px",
//   borderColor: "#605E5E",
//   borderCollapse: "collapse",
//   "@media (max-width: 300px)": {
//     width: "90%",
//   },
//   "& th": {
//     borderStyle: "solid",
//     borderWidth: "1px",
//     borderColor: "#605E5E",
//     padding: "0.5rem",
//     textAlign: "center",
//     fontFamily: "Avenir, sans-serif",
//     fontSize: "1rem",
//     fontWeight: "bold",
//     color: "#605E5E",
//     "@media (max-width: 820px)": {
//       textAlign: "center",
//     },
//     "@media (max-width: 620px)": {
//       fontSize: "0.9rem",
//     },
//     "@media (max-width: 320px)": {
//       fontSize: "0.8rem",
//     },
//   },
//   "& td": {
//     borderStyle: "none solid",
//     borderWidth: "1px",
//     borderColor: "#605E5E",
//     padding: "0.5rem",
//     fontFamily: "Avenir, sans-serif",
//     fontSize: "1rem",
//     fontWeight: "normal",
//     color: "#605E5E",
//     "@media (max-width: 820px)": {
//       textAlign: "center",
//     },
//     "@media (max-width: 620px)": {
//       fontSize: "0.9rem",      
//       padding: "0.3rem",
//     },
//     "@media (max-width: 320px)": {
//       fontSize: "0.8rem",
//     },
//     "@media (max-width: 295px)": {
//       fontSize: "0.7rem",
//     }
//   }
// });
// export const List = styled("ul")({
//   listStyle: "disc",
//   paddingLeft: "1rem",
// });
// export const ListItem = styled("li")({
//   fontFamily: "Avenir, sans-serif",
//   fontSize: "1rem",
//   fontWeight: "normal",
//   color: "#605E5E",
//   textAlign: "justify",
//   paddingBottom: "0.8rem",
//   lineHeight: "1.3rem",
//   "&.bold": {
//     fontWeight: "bold",
//   },
//   "& span.bold": {
//     fontWeight: "bold",
//   },
//   "@media (max-width: 820px)": {
//     textAlign: "center",
//   },
//   "@media (max-width: 620px)": {
//     fontSize: "0.9rem",
//   },
//   "@media (max-width: 320px)": {
//     fontSize: "0.8rem",
//   },
// });

// export const ListNumber = styled("ol")({
//   listStyle: "decimal",
//   paddingLeft: "1rem",
// });

