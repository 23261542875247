import React from "react";
import Image01 from "assets/images/posts/post02/exercicio01.png";
import Image02 from "assets/images/posts/post02/exercicio02.png";
import Image03 from "assets/images/posts/post02/exercicio03.png";
import Image04 from "assets/images/posts/post02/exercicio04.png";
import Image05 from "assets/images/posts/post02/exercicio05.png";
import {
  AppBar,
  Footer,
  ListPosts,
  Text,
  Title,
  Modal,
  NoticeCard,
  Button,
  VejaTambem,
  Forum,
} from "components";
import {
  PostWrapper,
  TextWrapper,
  Content,
  WrapperGrid,
  Image,
  WrapperTable,
  NoticesCardsWrapper,
} from "./SeExercite.styles";
import { Box } from "@mui/material";
import { NoticesList } from "utils/NoticesList";
import { keyGenerator } from "utils/uniqueKey";

export const SeExercite: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);

  const refHome = React.useRef<HTMLDivElement | null>(null);
  const [maxPosts, setMaxPosts] = React.useState(3);

  const onLoadMorePostsClick = () => {
    setMaxPosts(6);
  };

  React.useEffect(() => {
    if (refHome.current) {
      if (window.location.hash === "#publicacoes") {
        refHome.current
          .getElementsByClassName("publicacoes")[0]
          .scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={1} />
        <Content>
          <Title>Praticar atividade física</Title>
          <Text>
            A atividade física é importante para o indivíduo ser saudável, pois
            proporciona diversos benefícios, como aumento da massa muscular,
            perda de peso, equilíbrio, controle da pressão arterial,
            fortalecimento do sistema imunológico, melhora da saúde mental e da
            sensação de bem estar. Para o diabetes, em especial, melhora a
            sensibilidade à insulina e captação da glicose e o controle
            glicêmico, permitindo a redução da glicemia.
          </Text>
          <WrapperGrid>
            <Image
              onClick={handleOpenImg1}
              src={Image01}
              alt="Pessoas se exercitando."
            />
            <Modal open={openImg01} onClose={handleCloseImg1}>
              <Image
                className="modal"
                src={Image01}
                alt="Pessoas se exercitando."
              />
            </Modal>
            <Text>
              Qualquer atividade física é melhor que o sedentarismo, até mesmo
              quinze minutos por dia é melhor do que nada. Não precisa ser em
              academias. Você pode subir a escada do seu apartamento, caminhar
              no seu bairro, ir à padaria ou mercado, fazer uma compra a pé. O
              importante é começar!
            </Text>
          </WrapperGrid>
          <Text className="title"> Aqui tem algumas dicas para você:</Text>

          <WrapperTable>
            <Box className="item">
              <Image
                onClick={handleOpenImg2}
                src={Image02}
                className="image_same_wrapper"
                alt="Mulher correndo na calçada com paisagem verde ao redor."
              />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image
                  src={Image02}
                  className="modal"
                  alt="Mulher correndo na calçada com paisagem verde ao redor."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  Comece devagar, optando por um exercício de sua preferência e
                  depois aumente a intensidade e duração. A recomendação é, no
                  mínimo, 30 minutos por dia, cinco vezes por semana,
                  equivalente a 150 minutos por semana.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg3}
                src={Image03}
                className="image_same_wrapper"
                alt="Um homen e uma mulher fazendo atividade física juntos."
              />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image
                  src={Image03}
                  className="modal"
                  alt="Um homen e uma mulher fazendo atividade física juntos."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  Alteração: Procure fazer atividade física com um amigo para
                  ter companhia e apoio e beba bastante água, pois é importante
                  para o equilíbrio do corpo.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg4}
                src={Image04}
                className="image_same_wrapper"
                alt="Pessoal comendo antes da atividade física."
              />
              <Modal open={openImg04} onClose={handleCloseImg4}>
                <Image
                  src={Image04}
                  className="modal"
                  alt="Pessoal comendo antes da atividade física."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  Evite fazer atividades se sua glicemia estiver acima de
                  250mg/dl. Se glicemias abaixo de 100mg/dl, coma uma fruta como
                  por exemplo, uma banana antes de iniciar atividade.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg5}
                src={Image05}
                className="image_same_wrapper"
                alt="Mulher consultando uma médica antes do início das atividades física."
              />
              <Modal open={openImg05} onClose={handleCloseImg5}>
                <Image
                  src={Image05}
                  className="modal"
                  alt="Mulher consultando uma médica antes do início das atividades física."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  Antes de começar a atividade física fale com seu médico para
                  verificar se há alguma restrição. Se você tiver amputação ou
                  ferida no pé, faça exercícios adaptados e procure informações
                  a respeito .
                </Text>
              </TextWrapper>
            </Box>
          </WrapperTable>
          <Text>
            Não faça atividade física, nem mesmo caminhada leve, com chinelos ou
            sandálias. Use um tênis confortável e calce-os com meias para evitar
            formação de bolhas por atrito.
          </Text>
          {/* <br></br>
          <Text className="title">​​→ Que tipo de atividade física você consegue fazer durante a semana e quanto tempo dura?​ </Text> */}
          <Box className="item">
            <br></br>
            <br></br>
            <Text className="title"> REFERÊNCIAS</Text>
            <br></br>
            <br></br>
            <Text>
              E-books SBD. Autocuidado e diabetes em tempos de COVID-19.
              Disponível em: E-books Público - Sociedade Brasileira de Diabetes.
            </Text>
            <br></br>
            <Text>
              AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
              diabetes care and education: revising the AADE7 Self-Care
              Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
            </Text>
            <br></br>
            <Text>
              https://levitazero.com.br/wp-content/uploads/2017/11/Atividade-Fisica-171126int.jpg
            </Text>
            <br></br>
            <Text>
              https://portal.fslf.edu.br/wp-content/uploads/2023/03/diferenca-entre-atividade-fisica-e-exercicio-fisico.png
            </Text>
            <br></br>
            <Text>
              https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE443kPRhetggXF38OgBb-37iGQSIBio4B7A&usqp=CAU
            </Text>
            <br></br>
            <Text>
              https://i1.wp.com/educa.cetrus.com.br/wp-content/uploads/2020/12/shutterstock_145398733.jpg
            </Text>
          </Box>
        </Content>
        <VejaTambem post={1} />
        <Content className="forum">
          <Forum
            title="Que tipo de atividade física você consegue fazer durante a semana e quanto tempo dura?​"
            postNumber={2}
          />
        </Content>
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
