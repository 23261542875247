import { Box, styled } from "@mui/material";

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  gap: "2rem",
});

export const Row = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "2rem",
  maxWidth: "90%",
  background: "var(--gray-bg)",
  "& .card-vejatambem": {
    "@media (max-width: 768px)": {
      flexDirection: "row",
      alignItems: "center",
      height: "auto",
      "& img": {
        width: "30%",
        height: "100%",
        objectFit: "cover",
      },
      "& .noticecard-textcontainer": {
        width: "70%",
        justifyContent: "space-evenly", 
        padding: "0.5rem 1rem",
        "& h6, h1, a": {
          fontSize: "1rem",
        },
      }
    },
    "@media (max-width: 400px)": {
      flexDirection: "column",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      "& .noticecard-textcontainer": {
        width: "90%",
        justifyContent: "space-evenly", 
        "& h6, h1, a": {
          fontSize: "1rem",
        },
      }
    }
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});
