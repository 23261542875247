import {
  AppBar,
  Button,
  Footer,
  ListPosts,
  PostLinkInPage,
  Text,
  Title,
  VejaTambem,
} from "components";
import React from "react";
import { PostWrapper, Content, TextWrapper } from "./Quizz.styles";

export const Quizz: React.FC = () => {
  const onButtonClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdk63tm0oPX0sxNmPJJHfS_UFVEt4b3K6ZBavQ0TJIBI4NDSg/viewform?usp=sf_link"
    );
  };
  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={7} />
        <Content>
          <TextWrapper className="text-center">
            <Title variant="h1">
              Quizz: Veja agora o que você aprendeu sobre o diabetes
            </Title>

            <Text>
              Veja agora como está os seus conhecimentos sobre o diabetes e seus
              cuidados. Vamos lá!!!
            </Text>
            <Button
              onClick={onButtonClick}
              className="button"
              bgColor="primary"
            >
              Clique aqui
            </Button>
          </TextWrapper>
          <Text className="title">REFERÊNCIAS</Text>
          <Text>
            https://lh3.googleusercontent.com/OH7t9_1usBIeeXcN_yrI8KFb1SaAkK9d7v9NHQJg2cQYlhfL-39HO4cG1eUhLiB5PL87mMJbGYgV5EILckXLCr3jh9BnJ2Q69TVHMeU
          </Text>
          <Text>
            https://lh3.googleusercontent.com/bVEJM7-eJAxJxsl1xKZb3J7Gr6TQXpIn5wNoGBX8Zg5WzFtAMoUjL8EFkVeWdOCcyatmBbftW9jl9ElwjXyF-7wupPnlQITm62VSdj4
          </Text>
          <Text>
            https://lh3.googleusercontent.com/CwK6squCVlgIjH1RFzAl8uhQ__OZ-HZWRIvoA9qZNmLvIkb8FzFYdEyB7OCtdEeASXRCM9oCy3SWHFGBdqAmWz8xPfqS-hgtbWEiFgbBAg
          </Text>
          <Text>
            https://nacionalsaude.com.br/wp-content/uploads/2022/01/blog-nacional.png
          </Text>
          <Text>
            https://s2.glbimg.com/oXELwoShqQMgVUZuODD6pG1ivjY=/0x0:1258x833/1000x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_bc8228b6673f488aa253bbcb03c80ec5/internal_photos/bs/2021/D/W/pYa3sATkuGLbWOrI9ing/corrida-calor-agua.jpg
          </Text>
          <Text>
            https://jornalsemanario.com.br/wp-content/uploads/2022/06/Medicamentos-devem-ser-comercializados-em-supermercados-indica-PL-768x506.jpeg
          </Text>
          <Text>
            https://conteudo.imguol.com.br/c/entretenimento/62/2022/11/14/estima-se-que-quase-17-milhoes-de-pessoas-vivam-com-diabetes-no-brasil-1668427628826_v2_900x506.png.webp
          </Text>
          <Text>
            https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQIFS9NsUt0c6pkEw5AFBUGTRagJUxUpDQOeiOTgKBFA4gv1QCy
          </Text>
          <Text>
            https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTgBdDGf89M1bmldQcRM8jV_aH5itajdLusonnErvsvCueDiD_h
          </Text>
          <Text>
            https://lh3.googleusercontent.com/gnHAvehJ7K-3ooeUplM3TQZW5xgd9B7vfrClhHzl1OzXysV9G4hSDzd_BNaEsF2H5ZD9glBcLBuu30m7eDpofza_2aZvruBVzdWbwnc
          </Text>
          <Text>
            https://lh3.googleusercontent.com/TJQ0lSeuSjl2rPVfZih8JU6MWGswbASjEoyheEDae97hu3yKJzTv-jxvj2mJ_XfDwuewnPvpFHOsw8jVhIoP_Mp7W_eLD3asX86nSZexlQ
          </Text>
          <Text>
            https://lh3.googleusercontent.com/siSL7LLMS3XxdRKOR3_0w0odjnW_QyVaQGbiIAukZ_ysIpIlRTXBxyciq4BZo92lfVlJJ941YniF6R460ZcOBWsV7w007T8PbRZ19yQ-
          </Text>
        </Content>
        <VejaTambem post={7} />
      </PostWrapper>
      {/* <QuizzWrapper component="section">
        <TextWrapper>
          <Title variant="h1">Quizz: Veja agora o que você aprendeu sobre o diabetes</Title>

          <Text>
            Veja agora como está os seus conhecimentos sobre o diabetes e seus
            cuidados. Vamos lá!!!
          </Text>
          <Button onClick={onButtonClick} className="button" bgColor="primary">
            Clique aqui
          </Button>
        </TextWrapper>
        <PostLinkInPage />
      </QuizzWrapper> */}
      <Footer page="publications" />
    </React.Fragment>
  );
};
