import { PagesRoutes } from "features/constants";
import React from "react";
import { NotFoundWrapper } from "./NotFound.styled";

export const NotFound: React.FC = () => {
  React.useEffect(() => {
    window.location.href = PagesRoutes.HOME;
  }, []);
  return (
    <NotFoundWrapper>
      <h1>Página não encontrada!</h1>
    </NotFoundWrapper>
  );
};
