import React from "react";
import VideoIntro from "assets/videos/video-introducao.mp4";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import CoverImage from "assets/images/cover.webp";
import {
  ControlsContainer,
  MenuVol,
  Player,
  VideoWrapper,
  ButtonWithIcon,
  VideoContainer,
  SubMenu,
  SpeedButton,
} from "./Video.styles";
import { MenuItem } from "@mui/material";
import Slider from "@mui/material/Slider";

interface videoStateProps {
  playing: boolean;
  muted: boolean;
  speed: number;
  currentTime: number;
  duration: number;
  volume: number;
}

export const Video: React.FC = () => {
  const [anchorVol, setAnchorVol] = React.useState<null | HTMLElement>(null);
  const [anchorSettings, setAnchorSettings] =
    React.useState<null | HTMLElement>(null);
  const [anchorSpeed, setAnchorSpeed] = React.useState<null | HTMLElement>(
    null
  );
  const openVol = Boolean(anchorVol);
  const openSettings = Boolean(anchorSettings);
  const openSpeed = Boolean(anchorSpeed);
  const handleClickVol = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorVol(event.currentTarget);
  };
  const handleCloseVol = () => {
    setAnchorVol(null);
  };
  const handleClickSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorSettings(null);
  };
  const handleClickSpeed = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSpeed(event.currentTarget);
  };
  const handleCloseSpeed = () => {
    setAnchorSpeed(null);
    handleCloseSettings();
  };

  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const videoPlayer = React.useRef<HTMLVideoElement>(null);

  const [playerState, setPlayerState] = React.useState<videoStateProps>({
    playing: false,
    muted: false,
    speed: 1,
    currentTime: 0,
    duration: 0,
    volume: 1,
  });

  const toggleVideoPlay = () => {
    setPlayerState({
      ...playerState,
      playing: !playerState.playing,
    });
  };
  React.useEffect(() => {
    if (videoPlayer.current) {
      if (playerState.playing) {
        videoPlayer.current.play();
      } else {
        videoPlayer.current.pause();
      }
    }
  }, [playerState.playing, videoPlayer]);

  const toggleVideoSpeed = (speed: number) => {
    setPlayerState({
      ...playerState,
      speed: speed,
    });
    handleCloseSpeed();
    handleCloseSettings();
  };
  React.useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.playbackRate = playerState.speed;
    }
  }, [playerState.speed, videoPlayer]);

  React.useEffect(() => {
    if (videoPlayer.current) {
      if (playerState.muted) {
        videoPlayer.current.muted = true;
      } else {
        videoPlayer.current.muted = false;
      }
    }
  }, [playerState.muted, videoPlayer]);

  const onInputRangeChange = (e: Event, value: number | number[]) => {
    if (videoPlayer.current) {
      setPlayerState({
        ...playerState,
        currentTime: Number(value),
      });
      videoPlayer.current.currentTime = playerState.currentTime;
    }
  };
  const onTimeUpdate = () => {
    setPlayerState({
      ...playerState,
      currentTime: videoPlayer.current?.currentTime || 0,
    });
  };

  const onDurationChange = () => {
    setPlayerState({
      ...playerState,
      duration: videoPlayer.current?.duration || 0,
    });
  };

  const onVideoForward = () => {
    if (videoPlayer.current) {
      videoPlayer.current.currentTime += 10;
      setPlayerState({
        ...playerState,
        currentTime: videoPlayer.current.currentTime,
      });
    }
  };

  const onVideoBackward = () => {
    if (videoPlayer.current) {
      videoPlayer.current.currentTime -= 10;
      setPlayerState({
        ...playerState,
        currentTime: videoPlayer.current.currentTime,
      });
    }
  };

  const onVolumeVideoRange = (e: Event, value: number | number[]) => {
    if (videoPlayer.current) {
      setPlayerState({
        ...playerState,
        volume: Number(value),
      });
    }
  };
  React.useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.volume = playerState.volume;
    }
  }, [playerState.volume]);

  const onVolumeMaxClick = () => {
    setPlayerState({
      ...playerState,
      volume: 1,
    });
  };
  const onVolumeMinClick = () => {
    setPlayerState({
      ...playerState,
      volume: 0.01,
    });
  };

  return (
    <VideoWrapper component="section">
      <VideoContainer>
        <Player
          ref={videoPlayer}
          className="video"
          src={VideoIntro}
          poster={CoverImage}
          lang="pt-BR"
          controls={false}
          onTimeUpdate={onTimeUpdate}
          onDurationChange={onDurationChange}
          onClick={toggleVideoPlay}
        />
        <ControlsContainer>
          <ButtonWithIcon onClick={toggleVideoPlay}>
            {playerState.playing ? (
              <PauseOutlinedIcon />
            ) : (
              <PlayArrowOutlinedIcon />
            )}
          </ButtonWithIcon>
          <ButtonWithIcon onClick={onVideoBackward}>
            <SkipPreviousOutlinedIcon />
          </ButtonWithIcon>
          <ButtonWithIcon onClick={onVideoForward}>
            <SkipNextOutlinedIcon />
          </ButtonWithIcon>
          <Slider
            size="small"
            min={0}
            max={playerState.duration}
            value={playerState.currentTime || 0}
            defaultValue={0}
            aria-valuetext="lais"
            valueLabelFormat={(value) => {
              return `${Math.floor(value / 60)}:${Math.floor(
                value % 60
              ).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}`;
            }}
            valueLabelDisplay="auto"
            aria-label="small steps"
            onChange={onInputRangeChange}
          />
          <ButtonWithIcon onClick={handleClickVol}>
            {playerState.volume <= 0.01 ? (
              <VolumeOffOutlinedIcon />
            ) : playerState.volume < 0.1 ? (
              <VolumeOffOutlinedIcon />
            ) : (
              <VolumeUpOutlinedIcon />
            )}
          </ButtonWithIcon>
          <MenuVol
            id="menu-mobile"
            anchorEl={anchorVol}
            open={openVol}
            onClose={handleCloseVol}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <ButtonWithIcon onClick={onVolumeMaxClick} className="white">
                <VolumeUpOutlinedIcon />
              </ButtonWithIcon>
            </MenuItem>
            <MenuItem id="volume">
              <Slider
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                }}
                orientation="vertical"
                defaultValue={30}
                aria-label="vertical-slider"
                valueLabelDisplay="auto"
                onKeyDown={preventHorizontalKeyboardNavigation}
                onChange={onVolumeVideoRange}
                value={playerState.volume || 1}
                min={0}
                max={1}
                step={0.01}
              />
            </MenuItem>
            <MenuItem>
              <ButtonWithIcon onClick={onVolumeMinClick} className="white">
                <VolumeOffOutlinedIcon />
              </ButtonWithIcon>
            </MenuItem>
          </MenuVol>
          <ButtonWithIcon onClick={handleClickSettings}>
            <SettingsIcon />
          </ButtonWithIcon>
          <MenuVol
            id="menu-mobile"
            anchorEl={anchorSettings}
            open={openSettings}
            onClose={handleCloseSettings}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <SpeedButton onClick={handleClickSpeed} className="white">
                Velocidade
              </SpeedButton>
              <SubMenu
                id="menu-mobile"
                anchorEl={anchorSpeed}
                open={openSpeed}
                onClose={handleCloseSpeed}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <SpeedButton onClick={() => toggleVideoSpeed(1)}>
                    1x
                  </SpeedButton>
                </MenuItem>
                <MenuItem>
                  <SpeedButton onClick={() => toggleVideoSpeed(1.25)}>
                    1.25x
                  </SpeedButton>
                </MenuItem>
                <MenuItem>
                  <SpeedButton onClick={() => toggleVideoSpeed(1.5)}>
                    1.5x
                  </SpeedButton>
                </MenuItem>
                <MenuItem>
                  <SpeedButton onClick={() => toggleVideoSpeed(1.75)}>
                    1.75x
                  </SpeedButton>
                </MenuItem>
                <MenuItem>
                  <SpeedButton onClick={() => toggleVideoSpeed(2)}>
                    2x
                  </SpeedButton>
                </MenuItem>
              </SubMenu>
            </MenuItem>
          </MenuVol>
        </ControlsContainer>
      </VideoContainer>
    </VideoWrapper>
  );
};
