import { Typography, styled } from "@mui/material";
import "utils/variables.css";

export const Text = styled(Typography)({
    fontFamily: "var(--font)",
    color: "var(--text-dark)",
    fontSize: "var(--title-size)",
    fontWeight: "var(--title-weight)",
    "&.white": {
        color: "var(--light-textcolor)",
    },
    "&.center": {
        textAlign: "center",
    },
    "&.justify": {
        textAlign: "justify",
    },
    "@media (min-width: 2000px)": {
        fontSize: "5rem",
    },
    "@media (min-width: 1600px)": {
        fontSize: "4rem",
    },
    "@media (min-width: 1500px)": {
        fontSize: "3.5rem",
    },
    "@media (min-width: 1400px)": {
        fontSize: "3rem",
    },
    "@media (min-width: 1300px)": {
        fontSize: "2.5rem",
    },
    "@media (max-width: 480px)": {
        fontSize: "2rem",
    },
    "@media (max-width: 380px)": {
        fontSize: "1.5rem",
    },
});