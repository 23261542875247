import React from "react";
import {
  Button,
  Card,
  Footer,
  Header,
  NoticeCard,
  Text,
  Title,
  Video,
  Modal,
} from "components";
import { Box } from "@mui/material";
import Image02 from "assets/images/home/home02.png";
import Image03 from "assets/images/home/home03.png";
import Image04 from "assets/images/home/home04.png";
import Image05 from "assets/images/home/home05.png";
import {
  CardsWrapper,
  DataWrapper,
  Image,
  ImageContainer,
  NoticesCardsWrapper,
  TextInHomeWrapper,
  TextWrapper,
  WrapperGrid,
  List,
  ListItem,
  FlexRow,
} from "./Home.styles";
import { keyGenerator } from "utils/uniqueKey";
import { NoticesListHome } from "utils/NoticesListHome";

export const Home: React.FC = () => {
  const refHome = React.useRef<HTMLDivElement | null>(null);
  const [maxPosts, setMaxPosts] = React.useState(3);

  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);

  const onLoadMorePostsClick = () => {
    if (maxPosts === 3) setMaxPosts(6);
    else setMaxPosts(9);
  };

  React.useEffect(() => {
    if (refHome.current) {
      if (window.location.hash === "#publicacoes") {
        refHome.current
          .getElementsByClassName("publicacoes")[0]
          .scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
      }
    }
  }, []);
  return (
    <Box ref={refHome}>
      <Header />
      <TextInHomeWrapper component="section">
        <TextWrapper>
          <Title className="text-center" variant="h1">
            Mas antes, vamos entender o que é diabetes
          </Title>
          <Text variant="body1" className="text">
            Diabetes é uma condição crônica, ou seja, não tem cura. É
            caracterizada pelo aumento de glicose (açúcar) no sangue. Isso
            acontece quando o corpo não produz ou não utiliza a insulina de
            forma adequada.
          </Text>
          <Text>
            A insulina é um hormônio responsável por controlar a entrada do
            açúcar nas células do corpo. Ela tem a função de colocar a glicose
            que circula no sangue, dentro das células do nosso corpo, o que nos
            dá energia!
          </Text>
        </TextWrapper>
        <CardsWrapper>
          <Card
            title="Diabetes tipo 1 (DM1)"
            text="É quando não há produção de insulina devido a um defeito do sistema  
            imunológico que ataca as células produtoras de insulina. Por isso, as pessoas 
            com o tipo 1 precisam usar injeções de insulina no tratamento medicamentoso."
          />
          <Card
            title="Diabetes tipo 2 (DM2)"
            text="Acontece quando existe uma dificuldade na ação da insulina, ou seja,  resistência 
            à insulina. Geralmente o tratamento medicamentoso se inicia com comprimidos, mas pode ser 
            associado com a injeção de insulina."
          />
          <Card
            title="Diabetes Gestacional"
            text="Acontece na gravidez por conta das alterações dos hormônios nesse período que faz 
            com que ocorra o aumento no nível de glicose no sangue durante o período gestacional. Os 
            sintomas são sede constante, vontade de fazer xixi diversas vezes ao dia, muita fome, perda de peso, fraqueza, mal estar, náusea, vômito."
          />
        </CardsWrapper>
        <FlexRow>
          <Box className="text-container">
            <Title className="center" variant="h1">
              Brasil é o 6º país com maior número de diabetes
            </Title>
            <Text variant="body1" className="text">
              Continue com a leitura para entender o que é o diabetes e como
              identificar. Caso já tenha a doença, confira nosso conteúdo para
              alcançar qualidade de vida vivendo com a doença.
            </Text>
          </Box>
          <ImageContainer className="image">
            <Image
              onClick={handleOpenImg1}
              src={Image02}
              alt="Imagem do mapa do Brasil"
            />
            <Modal open={openImg01} onClose={handleCloseImg1}>
              <Image
                className="modal"
                onClick={handleOpenImg1}
                src={Image02}
                alt="Imagem do mapa do Brasil"
              />
            </Modal>
          </ImageContainer>
        </FlexRow>
      </TextInHomeWrapper>
      <DataWrapper>
        <Title className="white">Vamos às dicas?</Title>
      </DataWrapper>
      <TextInHomeWrapper>
        <WrapperGrid sx={{padding:"0"}}>
          <Title className="text-left right-top" variant="h1">
            É possível ter diabetes e ter qualidade de vida?
          </Title>
          <ImageContainer className="image-possible">
            <Image
              onClick={handleOpenImg3}
              src={Image03}
              alt="Pessoas conversando e bebendo."
            />
            <Modal open={openImg03} onClose={handleCloseImg3}>
              <Image
                className="modal"
                src={Image03}
                alt="Pessoas conversando e bebendo."
              />
            </Modal>
          </ImageContainer>
          <Box className="text-container">
            <Text variant="body1" className="text">
              Em algum momento você teve medo ou preocupação sobre como viver
              com diabetes, não é mesmo? Mas calma, conhecer mais sobre o
              assunto é essencial para o processo de autocuidado. Para viver bem
              e com qualidade, é necessário contar com uma ferramenta essencial
              que é a <span className="bold">educação em diabetes</span>. Seus
              objetivos são facilitar a compreensão sobre o autocuidado,
              melhorar os resultados de saúde, prevenir ou retardar o diabetes e
              as suas complicações e proporcionar qualidade de vida.
            </Text>
            <br></br>
            {/* <Text>
              Os 7 comportamentos de autocuidado para o diabetes são
              fundamentais para ter sucesso no cuidado com a saúde e para o
              bem-estar da pessoa com diabetes: comer saudavelmente, praticar
              atividade física, vigiar as taxas, usar os medicamentos, resolver
              problemas, reduzir riscos e adaptar-se saudavelmente. Todos esses
              comportamentos estão integrados.
            </Text>
            <br></br>
            <Text>Vamos as dicas?</Text> */}
          </Box>
        </WrapperGrid>
        <TextWrapper className="referencias">
          <Text className="justify">
            Os 7 comportamentos de autocuidado para o diabetes são fundamentais
            para ter sucesso no cuidado com a saúde e para o bem-estar da pessoa
            com diabetes: comer saudavelmente, praticar atividade física, vigiar
            as taxas, usar os medicamentos, resolver problemas, reduzir riscos e
            adaptar-se saudavelmente. Todos esses comportamentos estão
            integrados.
          </Text>
          <Text className="center">Vamos às dicas?</Text>
        </TextWrapper>
      </TextInHomeWrapper>
      <TextInHomeWrapper component="section">
        <TextWrapper>
          <Text className="title" variant="h1">
            REFERÊNCIAS
          </Text>
          <Text variant="body1" className="text">
            <span className="bold">American Diabetes Association</span>.
            Disponível em:
            https://diabetes.org/healthy-living/recipes-nutrition. Acesso em 26
            set 2022.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. <span className="bold">The Diabetes Educator</span>, v.
            46, n. 2, p. 139-160, 2020.
          </Text>
          <Text>
            PINHEIRO, P. <span className="bold">Diabetes tipo 2</span>: causas e
            fatores de risco. 2022. Disponível em:
            https://www.mdsaude.com/endocrinologia/diabetes-tipo-2-causas/.
            Acesso em: 09 mai 2023.
          </Text>
        </TextWrapper>
      </TextInHomeWrapper>
      <WrapperGrid
        className="about publicacoes"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Title variant="h1" className="center title-pub">
          Sobre o projeto
        </Title>
        <TextWrapper className="text-in-home-center">
          <Text className="title-left">Adaptar-se saudavelmente</Text>
          <Text>
            Bem, talvez você que tem diabetes já tenha passado por alguma
            situação em que tenha sentido medo, preocupação ou até mesmo ficado
            estressado. Isso é normal! Não se desespere, você não está sozinho!
          </Text>
          <Text>
            Claro que você precisa ter cuidados e manter hábitos saudáveis. Mas
            não exagere nas autocobranças! É importante que você tenha ajuda
            para conviver bem com o diabetes. Por isso, é importante seguir as
            orientações dos profissionais de saúde que o acompanham, além de ter
            uma rede de apoio que ajude nas decisões e acompanhe no dia a dia.
            <br></br>
            <br></br>É possível ter diabetes e ter qualidade de vida !!
          </Text>
        </TextWrapper>
        <Video />
        <TextWrapper className="text-in-home-center">
          <Text className="title">Reforçando as dicas:</Text>
          <br></br>
          <List>
            <ListItem>
              Relaxe: escute alguma música ou faça alguma coisa que te deixe
              relaxado(a);
            </ListItem>
            <ListItem>
              Se você é uma pessoa que tem alguma crença, coloque em prática
              atividades que te deixe bem, espiritualmente; Vá a sua igreja,
              casa de oração, encontre com pessoas que compartilham da mesma fé,
              isso vai te ajudar a aceitar melhor a doença;
            </ListItem>
            <ListItem>
              Converse: seja ativo(a)! Converse com sua família, amigos e
              pessoas próximas sobre tudo, inclusive diabetes e suas principais
              dúvidas;
            </ListItem>
            <ListItem>
              Participe de grupos de convivência: nos postos de saúde existem
              grupos de convivência de mulheres, idosos, pessoas com doenças
              crônicas e que realizam muitas atividades. Se você está ocioso
              (a), tente se informar para ver se há movimento semelhante em sua
              unidade de saúde. Existem também os grupos de práticas corporais
              que se encontram para realizar atividade física. É uma ótima
              opção!!!
            </ListItem>
            <ListItem>
              Escute músicas de meditação que lhe ajude a relaxar, as vezes
              podem ser facilmente encontradas no youtube ou em aplicativos.
            </ListItem>
          </List>
        </TextWrapper>
        {/* <TextWrapper>
          <Text className="title-left">
            {" "}
            Quando você descobriu que tinha diabetes, o que achou mais difícil
            adaptar em sua rotina?​
          </Text>
        </TextWrapper> */}
        <TextWrapper>
          <TextWrapper className="textleft">
            <Text className="title" variant="h2">
              REFERÊNCIAS
            </Text>
          </TextWrapper>
          <br></br>
          <Text>
            E-books SBD.{" "}
            <span className="bold">
              Autocuidado e diabetes em tempos de COVID-19
            </span>
            . Disponível em:{" "}
            <a href="https://diabetes.org.br/e-books-publico/">
              E-books Público - Sociedade Brasileira de Diabetes
            </a>
            .
          </Text>
          <Text>
            American Diabetes Association. Disponível em:
            https://diabetes.org/healthy-living/recipes-nutrition. Acesso em 26
            set 2022.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
        </TextWrapper>
      </WrapperGrid>

      <NoticesCardsWrapper sx={{ backgroundColor: "#fff" }}>
        <Title variant="h1" className="center title-pub">
          7 dicas para ter qualidade de vida com o diabetes
        </Title>
        {NoticesListHome.map(
          (notice, index) =>
            index < maxPosts && (
              <NoticeCard
                key={keyGenerator(index)}
                title={notice.title}
                description={notice.description}
                image={notice.image}
                page={notice.page}
              />
            )
        )}
        {(maxPosts === 3 || maxPosts === 6) && (
          <Button
            className="button-center"
            bgColor="outline"
            onClick={onLoadMorePostsClick}
          >
            Carregar mais
          </Button>
        )}
      </NoticesCardsWrapper>

      <NoticesCardsWrapper className="about-me">
        <Box>
          <Title variant="h1">Sobre mim</Title>
        </Box>
        <WrapperGrid className="left">
          <Text variant="body1" className="center-justify-grid">
            Olá, sou Florência Gamileira Nascimento, enfermeira pela
            Universidade Estadual Vale do Acaraú (UVA), especialista em Educação
            Permanente: saúde e educação em uma perspectiva integradora pela
            Fundação Oswald Cruz (FIOCRUZ), mestranda do Programa de
            Pós-graduação em Cuidados Clínicos em Enfermagem e Saúde da
            Universidade Estadual do Ceará (UECE) e idealizadora do Informa
            Diabetes.
          </Text>
          <ImageContainer className="image-container">
            <Image
              onClick={handleOpenImg4}
              src={Image04}
              className="image_perfil"
              alt="Florência Gamileira Nascimento"
            />
            <Modal open={openImg04} onClose={handleCloseImg4}>
              <Image
                src={Image04}
                className="modal"
                alt="Florência Gamileira Nascimento"
              />
            </Modal>
          </ImageContainer>
        </WrapperGrid>
        <WrapperGrid className="right">
          <ImageContainer className="image-container">
            <Image
              onClick={handleOpenImg5}
              src={Image05}
              className="image_perfil"
              alt="Orientadora"
            />
            <Modal open={openImg05} onClose={handleCloseImg5}>
              <Image src={Image05} className="modal" alt="Orientadora" />
            </Modal>
          </ImageContainer>
          <Text variant="body1" className="center-justify-grid">
            O projeto também contou com a colaboração da Profa. Dra. Sherida
            Karanini Paz de Oliveira, que é enfermeira, educadora em diabetes e
            docente do programa de Pós-Graduação em Cuidados Clínicos em
            Enfermagem e Saúde da Universidade Estadual do Ceará (UECE).
            <a href="http://lattes.cnpq.br/6883820810036825">Lattes</a>
            <a href="https://orcid.org/0000-0003-3902-8046">ORCID</a>
          </Text>
        </WrapperGrid>
      </NoticesCardsWrapper>
      <Footer page="home" />
    </Box>
  );
};
