import React from "react";
import { NoticesListHome } from "utils/NoticesListHome";
import { keyGenerator } from "utils/uniqueKey";
import { NoticeCard, Title } from "components";
import { Container, Row } from "./VejaTambem.styles";

export const VejaTambem: React.FC<{ post: number }> = ({ post }) => {
  const generatePosts = (excep: number) => {
    let randomPosts: number[] = [];
    while (randomPosts.length < 3) {
      let randomNumber = Math.floor(Math.random() * 8);

      if (randomNumber !== excep && !randomPosts.includes(randomNumber)) {
        randomPosts.push(randomNumber);
      }
    }
    return randomPosts;
  };

  let posts = generatePosts(post);

  return (
    <Container>
      <Title>Veja Também</Title>
      <Row>
        {NoticesListHome.map(
          (notice, index) =>
            posts.includes(index) && (
              <NoticeCard
                className="card-vejatambem"
                key={keyGenerator(index)}
                title={notice.title}
                description={notice.description}
                image={notice.image}
                page={notice.page}
              />
            )
        )}
      </Row>
    </Container>
  );
};
