import { api } from "api/api";
import { Button, Text } from "components";
import React from "react";
import { CommentType } from "utils/Interface";
import { validateEmail } from "utils/regex";
import {
  CommentContainer,
  CommentInput,
  CommentWrapper,
  Container,
  HelperText,
  Input,
  ReplyContainer,
  ReplyWrapper,
  ResponseToggleButton,
} from "./Comment.styles";
import { ReactComponent as Profile } from "assets/icons/profile.svg";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

interface CommentProps {
  comment: CommentType;
  handleReload: (value: boolean) => void;
}
export const Comment: React.FC<CommentProps> = ({
  comment: {
    idComentario,
    nome_autor_comentario,
    texto_comentario,
    created_at,
    respostas,
  },
  handleReload,
}) => {
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [reply, setReply] = React.useState<string>("");
  const [showDiv, setShowDiv] = React.useState<boolean>(false);
  let emailValid = validateEmail(email);
  let nameValid = name.length > 0;
  let textValid = reply.length > 0;
  const emailHelp = React.useRef<HTMLParagraphElement>(null);
  const nameHelp = React.useRef<HTMLParagraphElement>(null);
  const textHelp = React.useRef<HTMLParagraphElement>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

  const handleOpenSnackbar = (value: boolean) => {
    setOpenSnackbar(value);
  };
  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleReplyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(event.target.value);
  };
  const onButtonClick = async () => {
    if (emailValid && nameValid && textValid) {
      let styleName = "";
      let separeName = name.split(" ");
      separeName.forEach((name) => {
        if (name) styleName += name[0].toUpperCase() + name.substring(1) + " ";
      });
      let styleEmail = email.toLowerCase();
      let styleReply = reply[0].toUpperCase() + reply.substring(1);
      const sendPost = {
        nome: styleName,
        email: styleEmail,
        texto_resposta: styleReply,
        idComentario: idComentario,
      };
      await api.post("respostas", sendPost).finally(() => {
        setEmail("");
        setName("");
        setReply("");
        handleReload(true);
        handleResponseToggle();
      });
    } else {
      handleOpenSnackbar(true);
    }
  };

  const getHourAndMinite = (date: string) => {
    let dateConvert = new Date(date);
    return `${dateConvert.getHours()}:${dateConvert.getMinutes()}`;
  };
  const getDate = (date: string) => {
    let dateConvert = new Date(date);
    let month = dateConvert.getMonth() + 1;
    let month2: string = "";
    let day = dateConvert.getDate();
    let day2: string = "";
    if (day < 10) {
      day2 = "0" + day;
    } else {
      day2 = day.toString();
    }
    if (month < 10) {
      month2 = "0" + month;
    } else {
      month2 = month.toString();
    }
    return `${day2}/${month2}/${dateConvert.getFullYear()}`;
  };
  const dateConvert = (date: string) => {
    return `${getDate(date)} ${getHourAndMinite(date)}`;
  };
  const handleResponseToggle = () => {
    setShowDiv(!showDiv);
  };

  return (
    <Container>
      <CommentWrapper>
        <CommentContainer>
          <Box className="comment-profile">
            <Profile />
            <Text className="title center comment">{nome_autor_comentario}</Text>
          </Box>
          <Box className="comment-text">
            <Text className="comment">{texto_comentario}</Text>
          </Box>
          <Box className="comment-time">
            <Text>{dateConvert(created_at)}</Text>
            <ResponseToggleButton onClick={handleResponseToggle}>
              responder
            </ResponseToggleButton>
          </Box>
        </CommentContainer>
      </CommentWrapper>
      <ReplyContainer>
        {showDiv && (
          <ReplyWrapper>
            <Input
              placeholder="Seu nome"
              value={name}
              onChange={handleNameChange}
              type="text"
              onBlur={(_) => {
                if (!nameValid) {
                  if (nameHelp.current) {
                    nameHelp.current.style.display = "block";
                  }
                } else {
                  if (nameHelp.current) {
                    nameHelp.current.style.display = "none";
                  }
                }
              }}
              onFocus={(_) => {
                if (nameHelp.current) {
                  nameHelp.current.style.display = "block";
                }
              }}
            />
            <HelperText
              ref={nameHelp}
              className={nameValid ? "valid" : "invalid"}
            >
              {nameValid ? "" : "nome invalido"}
            </HelperText>

            <Input
              placeholder="Seu email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              onBlur={(_) => {
                if (!emailValid) {
                  if (emailHelp.current) {
                    emailHelp.current.style.display = "block";
                  }
                } else {
                  if (emailHelp.current) {
                    emailHelp.current.style.display = "none";
                  }
                }
              }}
              onFocus={(_) => {
                if (emailHelp.current) {
                  emailHelp.current.style.display = "block";
                }
              }}
            />
            <HelperText
              ref={emailHelp}
              className={emailValid ? "valid" : "invalid"}
            >
              {emailValid ? "" : "email invalido"}
            </HelperText>

            <CommentInput
              placeholder="Sua resposta"
              value={reply}
              onChange={handleReplyChange}
              onBlur={(_) => {
                if (!textValid) {
                  if (textHelp.current) {
                    textHelp.current.style.display = "block";
                  }
                } else {
                  if (textHelp.current) {
                    textHelp.current.style.display = "none";
                  }
                }
              }}
              onFocus={(_) => {
                if (textHelp.current) {
                  textHelp.current.style.display = "block";
                }
              }}
            />
            <HelperText
              ref={textHelp}
              className={textValid ? "valid" : "invalid"}
            >
              {textValid ? "" : "resposta invalida"}
            </HelperText>
            <Button
              bgColor="comment"
              className="sendReply"
              onClick={onButtonClick}
            >
              Enviar
            </Button>
          </ReplyWrapper>
        )}
        {respostas.map((reply, index) => (
          <CommentContainer key={index}>
            <Box className="comment-profile">
              <Profile />
              <Text className="title center response">{reply.nome_autor_resposta}</Text>
            </Box>
            <Box className="comment-text">
              <Text className="comment">{reply.texto_resposta}</Text>
            </Box>
            <Box className="comment-time">
              <Text>{dateConvert(reply.created_at)}</Text>
            </Box>
          </CommentContainer>
        ))}
      </ReplyContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Preencha todas as informações corretamente!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="error"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};
