export enum PagesRoutes {
  HOME = "/",
  ABOUT = "/sobre-mim",
  REFERENCES = "/referencias",
  POST01 = "/posts/alimentacao",
  POST02 = "/posts/se-exercite",
  POST03 = "/posts/tomando-medicacao",
  POST04 = "/posts/monitoramento",
  POST05 = "/posts/reduza-riscos",
  POST06 = "/posts/resolver-problemas",
  POST07 = "/posts/voce-sabia",
  QUIZZ = "/posts/quizz",
  GLOSSARIO = "/posts/glossario",
}
