import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import "utils/variables.css";

export const ButtonWrapper = styled(Link)({
  display: "grid",
  placeItems: "center",
  textDecoration: "none",
  fontFamily: "var(--font)",
  fontSize: "var(--button-size)",
  fontWeight: "var(--button-weight)",
  width: "100%",
  height: "40px",
  color: "var(--text-light-dark)",
  backgroundColor: "var(--light-gb)",
  border: "1px solid var(--text-light-dark)",
  borderRadius: "var(--border-radius)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "rgb(240,240,240)",
  },
  "@media (max-width: 600px)": {
    // width: "120px",
    height: "35px",
  },
  "@media (max-width: 500px)": {
    // width: "100px",
    height: "30px",
  },
});
