import {
  AppBar,
  Footer,
  ListPosts,
  Text,
  Title,
  VejaTambem,
  Forum,
  Modal,
} from "components";
import { Box } from "@mui/material";
import React from "react";
import Image01 from "assets/images/posts/post01/alimentos09.png";
import Image02 from "assets/images/posts/post01/alimentos01.png";
import Image03 from "assets/images/posts/post01/alimentos02.png";
import Image04 from "assets/images/posts/post01/alimentos03.png";
import Image05 from "assets/images/posts/post01/alimentos04.png";
import Image06 from "assets/images/posts/post01/alimentos05.png";
// import Image07 from "assets/images/posts/post01/imagem_tabela_contagem_carboidratos.png";
// import Image08 from "assets/images/posts/post01/imagem08.png";
// import Image09 from "assets/images/posts/post01/imagem09.png";
import Rotulo from "assets/images/posts/post01/rotulo.png";
import Rotulo02 from "assets/images/posts/post01/rotulo_02.png";

import {
  PostWrapper,
  Content,
  Image,
  WrapperRow,
  List,
  ListItem,
  Table,
  TableMobileGrid,
} from "./Alimentacao.styles";

export const Alimentacao: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);
  const [openImg06, setOpenImg06] = React.useState(false);
  // const [openImg07, setOpenImg07] = React.useState(false);
  const [openImg08, setOpenImg08] = React.useState(false);
  const [openImg09, setOpenImg09] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);
  const handleOpenImg6 = () => setOpenImg06(true);
  // const handleOpenImg7 = () => setOpenImg07(true);
  const handleOpenImg8 = () => setOpenImg08(true);
  const handleOpenImg9 = () => setOpenImg09(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);
  const handleCloseImg6 = () => setOpenImg06(false);
  // const handleCloseImg7 = () => setOpenImg07(false);
  const handleCloseImg8 = () => setOpenImg08(false);
  const handleCloseImg9 = () => setOpenImg09(false);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={0} />
        <Content>
          <Title>Comer saudavelmente</Title>
          <Text>
            Para muitas pessoas, diabetes é sinônimo de restrição alimentar. Na
            verdade, ter diabetes não significa que a pessoa não pode comer seu
            alimento favorito ou ter uma dieta restritiva. Não há nada que não
            se possa comer!
          </Text>
          <Text>
            É necessário entender como os alimentos afetam a glicemia para{" "}
            <span className="bold">
              aprender a fazer escolhas alimentares saudáveis​​, prestando
              atenção ao conteúdo nutricional e tamanho das porções.
            </span>
          </Text>
          <Text>
            Você deve fazer refeições regulares, pensar na quantidade e fazer
            escolhas para ajudar a manejar melhor o diabetes e prevenir outros
            problemas de saúde.
          </Text>
          <Text>
            As mudanças devem acontecer aos poucos, inclusive a alimentação. Mas
            primeiro vamos conhecer os tipos de alimentos que existem e como
            eles influenciam a glicemia. Nos alimentos encontramos carboidratos,
            proteínas e gorduras, precisamos saber diferenciar o que é cada um
            desses componentes.
          </Text>

          <Table>
            <thead>
              <tr>
                <th>
                  <Text className="title">Carboidratos</Text>
                </th>
                <th>
                  <Text className="title">Proteínas</Text>
                </th>
                <th>
                  <Text className="title">Gorduras</Text>
                </th>
              </tr>
              <tr>
                <td>
                  <List>
                    <ListItem>
                      São nutrientes encontrados nos alimentos e existem três
                      tipos: amido, açúcares e fibras.
                    </ListItem>
                    <ListItem>
                      Causam alteração no nível de glicose, fazendo com que a
                      glicemia aumente rapidamente entre 15 minutos a 2 horas.
                      Praticamente 100% do que se consome, se transforma em
                      glicose.
                    </ListItem>
                    <ListItem>
                      Exemplos: pão, arroz, farinha, biscoito, cereais, massas,
                      batatas, grãos, leite, mel.
                    </ListItem>
                  </List>
                </td>
                <td>
                  <List>
                    <ListItem>
                      São componentes importantes para os órgãos, tecidos e
                      células.
                    </ListItem>
                    <ListItem>
                      Uma pequena parte (30-60%) é transformada em glicose,
                      porém a absorção é menos rápida do que os carboidratos. O
                      impacto na glicemia dura em média de 3-4 horas.
                    </ListItem>
                    <ListItem>
                      Exemplos: ovos, leite, carnes, frango, feijão.
                    </ListItem>
                  </List>
                </td>
                <td>
                  <List>
                    <ListItem>
                      São a principal fonte de energia do corpo e ajudam na
                      absorção de vitaminas. Existem de origem animal e vegetal.
                    </ListItem>
                    <ListItem>
                      São absorvidas de forma lenta pelo corpo, mas só uma
                      pequena parte é transformada em glicose (10%), o que
                      tardiamente afeta na glicemia (a partir de 5 horas).
                    </ListItem>
                    <ListItem>
                      Exemplos: manteiga, óleos vegetais, ,margarinas, maionese,
                      creme de leite.
                    </ListItem>
                  </List>
                </td>
              </tr>
            </thead>
          </Table>

          <TableMobileGrid>
            <Box className="title">
              <Text className="title">Carboidratos</Text>
            </Box>
            <Box className="main">
              <List>
                <ListItem>
                  São nutrientes encontrados nos alimentos e existem três tipos:
                  amido, açúcares e fibras.
                </ListItem>
                <ListItem>
                  Causam alteração no nível de glicose, fazendo com que a
                  glicemia aumente rapidamente entre 15 minutos a 2 horas.
                  Praticamente 100% do que se consome, se transforma em glicose.
                </ListItem>
                <ListItem>
                  Exemplos: pão, arroz, farinha, biscoito, cereais, massas,
                  batatas, grãos, leite, mel.
                </ListItem>
              </List>
            </Box>
            <Box className="title">
              <Text className="title">Proteínas</Text>
            </Box>
            <Box className="main">
              <List>
                <ListItem>
                  São componentes importantes para os órgãos, tecidos e células.
                </ListItem>
                <ListItem>
                  Uma pequena parte (30-60%) é transformada em glicose, porém a
                  absorção é menos rápida do que os carboidratos. O impacto na
                  glicemia dura em média de 3-4 horas.
                </ListItem>
                <ListItem>
                  Exemplos: ovos, leite, carnes, frango, feijão.
                </ListItem>
              </List>
            </Box>
            <Box className="title">
              <Text className="title">Gorduras</Text>
            </Box>
            <Box className="main">
              <List>
                <ListItem>
                  São a principal fonte de energia do corpo e ajudam na absorção
                  de vitaminas. Existem de origem animal e vegetal.
                </ListItem>
                <ListItem>
                  São absorvidas de forma lenta pelo corpo, mas só uma pequena
                  parte é transformada em glicose (10%), o que tardiamente afeta
                  na glicemia (a partir de 5 horas).
                </ListItem>
                <ListItem>
                  Exemplos: manteiga, óleos vegetais, ,margarinas, maionese,
                  creme de leite.
                </ListItem>
              </List>
            </Box>
          </TableMobileGrid>

          <br></br>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg1} src={Image01} alt="Imagem 01." />
              <Text className="font">
                Fonte: Guia alimentar para a população brasileira (2014).
              </Text>
              <Modal open={openImg01} onClose={handleCloseImg1}>
                <Image className="modal" src={Image01} alt="Imagem 01." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Os planos alimentares devem ser realizados com a ajuda do
                nutricionista após consulta e avaliação individualizada e
                centraliza-se na qualidade dos macronutrientes, padrões
                alimentares saudáveis, objetivos metabólicos e preferências
                alimentares de cada um. Por isso, é importante o acompanhamento
                com esse profissional.
              </Text>
              <Text>Seguem algumas dicas sobre alimentação:</Text>
              <List>
                <ListItem>
                  A maioria das pessoas pode se basear no método do prato que
                  consiste em “imitar” as mesmas quantidades da figura ao lado.
                </ListItem>
              </List>
            </Box>
          </WrapperRow>
          <Text className="title">Alimentos liberados</Text>
           <WrapperRow>
            <Box className="image-container">
              <Image
                onClick={handleOpenImg2}
                className="small_size"
                src={Image02}
                alt="Imagem 02."
              />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image className="modal" src={Image02} alt="Imagem 02." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Todas as folhas (alface, pepino, brócolis, rúcula, agrião,
                espinafre, couve), tomate, cebola, rabanete, brócolis,
                couve-flor, pimentão, beringela. Esses alimentos não contém
                muito carboidrato e não vão causar alteração na glicose,
                ajudando a controlá-la.
              </Text>
            </Box>
          </WrapperRow>
          <WrapperRow>
            <Box className="image-container">
              <Image
                onClick={handleOpenImg3}
                className="small_size"
                src={Image03}
                alt="Imagem 03."
              />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image className="modal" src={Image03} alt="Imagem 03." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Frutas são fontes de vitaminas, mas possuem açúcares naturais
                que se consumidos em excesso, podem elevar a glicemia. Consuma
                as frutas com moderação (3 frutas por dia de tamanho pequeno) e
                se possível acompanhadas de grãos como castanha. <span className="bold">Dê preferência
                pela fruta em si e não pelo suco</span>  e lembre-se que quanto mais
                maduras, mais doces são.
              </Text>
            </Box>
          </WrapperRow>
          <WrapperRow>
            <Box className="image-container">
              <Image
                onClick={handleOpenImg4}
                className="small_size"
                src={Image04}
                alt="Imagem 04."
              />
              <Modal open={openImg04} onClose={handleCloseImg4}>
                <Image className="modal" src={Image04} alt="Imagem 04." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Considere também tomate, cebola, rabanete, brócolis, couve-flor,
                pimentão e beringela.<span className="bold"> Todos esses alimentos não contém muito
                carboidrato e não vão causar alteração na glicose, ajudando a
                controlá-la.</span>
              </Text>
            </Box>
          </WrapperRow>
          {/* <Text className="title">Alimentos para diminuir</Text> */}
          <WrapperRow>
            <Box className="image-container">
              <Image
                onClick={handleOpenImg5}
                className="small_size"
                src={Image05}
                alt="Imagem 05."
              />
              <Modal open={openImg05} onClose={handleCloseImg5}>
                <Image className="modal" src={Image05} alt="Imagem 05." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Diminua o consumo de alimentos com carboidratos refinados e
                altamente processados ​​como cereais e bebidas açucaradas,
                refrigerante, pão branco, arroz branco, doces, salgadinhos,
                bolo, biscoitos e batatas fritas.
              </Text>
            </Box>
          </WrapperRow>
          <Text className="title">Mistura ou proteína</Text>
          <WrapperRow>
            <Box className="image-container">
              <Image
                onClick={handleOpenImg6}
                className="small_size"
                src={Image06}
                alt="Imagem 06."
              />
              <Modal open={openImg06} onClose={handleCloseImg6}>
                <Image className="modal" src={Image06} alt="Imagem 06." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Quanto à mistura ou proteína usada para montar o prato de
                alimentação, você pode usar como base o tamanho da palma da sua
                mão aberta que corresponde a uma porção da proteína a ser
                consumida. Lembre-se que existem proteínas de origem animal como
                carne de vaca, porco e aves (frango, pato) e proteínas de origem
                vegetal como feijão, soja e demais grãos.
              </Text>
            </Box>
          </WrapperRow>
          <Text className="title">Contagem dos carboidratos</Text>
          <Text>
            A contagem de carboidratos é uma estratégia que oferece maior
            flexibilidade para escolhas alimentares e facilita o controle
            glicêmico, podendo a pessoa com DM utilizar qualquer alimento,
            dentro de um plano alimentar saudável. Isso porque aplicamos a dose
            de insulina, conforme a quantidade de carboidratos consumida.
          </Text>

          {/* <PostWrapper className="postwrapper">
            <Image onClick={handleOpenImg7} src={Image07} alt="Imagem 07." />
            <Modal open={openImg07} onClose={handleCloseImg7}>
              <Image className="modal" src={Image07} alt="Imagem 07." />
            </Modal>
          </PostWrapper> */}

          {/* <Table>
            <thead>
              <tr>
                <th><Text className="title">TIPO</Text></th>
                <th><Text className="title">NOME</Text></th>
                <th><Text className="title">INICIO</Text></th>
                <th><Text className="title">PICO</Text></th>
                <th><Text className="title">DURAÇÃO</Text></th>
              </tr>
              <thead><Text className="title">Insulinas Basais</Text></thead>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text>Insulina intermediária</Text>
                </td>
                <td>
                  <Text>NPH</Text>
                </td>
                <td>
                  <Text>2-4h</Text>
                </td>
                <td>
                  <Text>4-10h</Text>
                </td>
                <td>
                  <Text>10-18h</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Análogo de ação longa</Text>
                </td>
                <td>
                  <Text>Glargina U100</Text>
                </td>
                <td>
                  <Text>2-4h</Text>
                </td>
                <td>
                  <Text>4</Text>
                </td>
                <td>
                  <Text>20-24h</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Análogo de ação intermediária</Text>
                </td>
                <td>
                  <Text>Detemir</Text>
                </td>
                <td>
                  <Text>1-3h</Text>
                </td>
                <td>
                  <Text>6-8h</Text>
                </td>
                <td>
                  <Text>18-22h</Text>
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  <Text>Análogo de ação ultra-longa</Text>
                </td>
                <td>
                  <Text>Glargina U300</Text>
                </td>
                <td>
                  <Text>6h</Text>
                </td>
                <td>
                  <Text>-</Text>
                </td>
                <td>
                  <Text>36h</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Degludeca</Text>
                </td>
                <td>
                  <Text>&#60;4h</Text>
                </td>
                <td>
                  <Text>-</Text>
                </td>
                <td>
                  <Text>42h</Text>
                </td>
              </tr>
            </tbody>
            <thead aria-colspan={5}><Text className="title">Insulinas Prandiais</Text></thead>
            <tbody>
              <tr>
                <td>
                  <Text>Insulina Rápida</Text>
                </td>
                <td>
                  <Text>Regular (Humulin R/Novolin R)</Text>
                </td>
                <td>
                  <Text>30-60min</Text>
                </td>
                <td>
                  <Text>2-3h</Text>
                </td>
                <td>
                  <Text>5-8h</Text>
                </td>
              </tr>
              <tr>
                <td rowSpan={5}>
                  <Text>Análogo de ação ultra-rápida</Text>
                </td>
                <td>
                  <Text>Asparte (Novorapid)</Text>
                </td>
                <td>
                  <Text>5-15min</Text>
                </td>
                <td>
                  <Text>30min-2h</Text>
                </td>
                <td>
                  <Text>3-5h</Text>
                </td>
              </tr>
              <tr>
                <td colSpan={1}>
                  <Text>Lispro (Humalog)</Text>
                </td>
                <td colSpan={1}></td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td colSpan={1}>
                  <Text>Glulisina (Apidra)</Text>
                </td>
                <td colSpan={1}></td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td>
                  <Text>Fast Asparte (Fiasp)</Text>
                </td>
                <td>
                  <Text>2-5min</Text>
                </td>
                <td>
                  <Text>1-3h</Text>
                </td>
                <td>
                  <Text>5h</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Inalada (Afrezza)</Text>
                </td>
                <td>
                  <Text>imediato</Text>
                </td>
                <td>
                  <Text>10-20min</Text>
                </td>
                <td>
                  <Text>1-2h</Text>
                </td>
              </tr>


            </tbody>
          </Table> */}
          <Text>
            <span className="bold">
              O Método de contagem de carboidratos em gramas
            </span>{" "}
            consiste em calcular e somar a quantidade de carboidratos (em
            gramas) de cada alimento ingerido por refeição. Esses valores são
            obtidos nos rótulos dos alimentos ou nas tabelas de contagem de
            carboidratos. Após saber a quantidade de carboidratos consumida, é
            possível ajustar a dose de insulina rápida ou ultrarrápida
            necessária para essa refeição. Para utilizar esse método, é
            necessário alguns dados prescritos pelo médico:
          </Text>

          <List>
            <ListItem>Relação insulina-carboidrato</ListItem>
            <ListItem>Fator de sensibilidade</ListItem>
          </List>
          <Text>
            A Sociedade Brasileira de Diabetes montou um manual com essas
            informações que você poderá acessá-lo clicando{" "}
            <a href="https://drive.google.com/file/d/1L26VRPdISDRshhJ5yw4THx0OCguxVBNh/view?usp=sharing">
              aqui
            </a>
            .
          </Text>
          <Text>
            Tenha sempre em mãos uma balança para pesar os alimentos e verificar
            a quantidade em gramas, por exemplo. Se você for comer uma maçã que
            pesa 70 gramas, deve verificar no manual a quantidade de
            carboidratos que existe e se a informação estiver maior ou menor do
            que o valor do alimento, deve fazer uma regra de 3 simples:
          </Text>
          <List>
            <ListItem>
              1 maçã de 90 gramas —14 gramas de carboidrato [Informações
              presente no manual]:
            </ListItem>
            <List>
              <ListItem>
                <p>Minha mação de 70 gramas — X</p>
                <br></br>
                <table>
                  <tr></tr>
                </table>
                <p>X = 70 * 14/90</p>
                <br></br>
                <p>X = 11 gramas</p>
              </ListItem>
            </List>
          </List>
          <Text>
            Assim estarei consumindo 11 gramas de carboidratos ao ingerir a
            maçã.
          </Text>
          <br></br>
          <Text className="title">Avaliando os rótulos dos alimentos</Text>
          <Text>
            Entender o rótulo e as informações nutricionais dos alimentos ajuda
            a tomar decisões sobre alimentação e bebida saudáveis ​​que melhoram
            a glicemia e a fazer melhores escolhas, especialmente em
            circunstâncias especiais, como refeições em restaurantes, festas,
            aniversários.
          </Text>
          <Text>
            Geralmente as informações dos carboidratos e outros elementos
            nutricionais ficam atrás do alimento na tabela nutricional e são
            colocadas em ordem decrescente da maior quantidade para a menor.
          </Text>
          <Text>
            A tabela nutricional apresenta os elementos que o alimento possui e
            são essenciais as informações sobre carboidratos, proteínas,
            gorduras, fibra alimentar e sódio. Fique atento às informações
            relacionadas aos carboidratos, pois irão afetar diretamente na
            glicemia e são os valores utilizados na contagem de{" "}
            <span className="bold">carboidratos</span>. A tabela deve ser
            semelhante ao modelo abaixo.
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image onClick={handleOpenImg8} src={Rotulo} alt="Imagem 08." />
            <Text className="font">
              A: nome do componente nutricional; B: peso em gramas; C:
              porcentagem dos valores diários fornecidos pela porção; D: porção
              em que foi retirada as informações daquele alimento. Fonte:
              Agência Nacional de Vigilância Sanitária (2022).
            </Text>
            <Modal open={openImg08} onClose={handleCloseImg8}>
              <Image className="modal" src={Rotulo} alt="Imagem 08." />
            </Modal>
          </Box>
          <Text>
            Os novos produtos também apresentarão mais informações visuais como
            o símbolo de uma <span className="bold">LUPA</span> que identifica
            alto conteúdo de determinados elementos por 100g de alimento.
          </Text>

          {/*<Text>
            Atente-se para os sucos prontos e observe que todo suco já vem com
            uma quantidade de açúcar natural da fruta e quando não há adição de
            açúcar, lê-se nos rótulos Sem adição de açúcar.
          </Text> */}
          <PostWrapper className="postwrapper">
            <Image onClick={handleOpenImg9} src={Rotulo02} alt="Imagem 09." />
            <Text className="font">
              Fonte: Agência Nacional de Vigilância Sanitária (2022).
            </Text>
            <Modal open={openImg09} onClose={handleCloseImg9}>
              <Image className="modal" src={Rotulo02} alt="Imagem 09." />
            </Modal>
          </PostWrapper>
          <Text>
            Atente-se para os sucos prontos e observe que todo suco já vem com
            uma quantidade de açúcar natural da fruta e quando não há adição de
            açúcar, lê- se nos rótulos{" "}
            <span className="bold">sem adição de açúcar</span>.
          </Text>
          <Text className="title" variant="h2">
            REFERÊNCIAS
          </Text>
          <Text>
            LARA, P; COONSOLI, M. Entendendo os rótulos dos alimentos. Folder da
            Sociedade Brasileira de Diabetes. Disponível em: E-books Público -
            Sociedade Brasileira de Diabetes.
          </Text>

          <Text>
            MINISTÉRIO DA SAÚDE (BR). Secretaria de Atenção Básica. Departamento
            de Atenção Básica. Guia alimentar para a população brasileira
            [Internet]. 2. ed. Brasília: Ministério da Saúde; 2014 [citado 2020
            jan 9]. 156 p. Disponível em: Disponível em:
            https://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdfhttps://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdf
          </Text>

          <Text>
            OLIVEIRA, S. M. S.; GUIMARÃES, D. B.; WARDI, M. M et al. Orientações
            Nutricionais para analfabetos. Instituto de ensino e pesquisa santa
            casa BH centro de diabetes - santa casa bh. E-book Sociedade
            Brasileira de Diabetes. Disponível em: Orientações nutricionais para
            analfabetos (diabetes.org.br). Acesso em 01 dez 2020.
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Bruno, L et al. Manual de
            Carboidratos para pessoas com diabetes. Departamento de Nutrição da
            Sociedade Brasileira de Diabetes. São Paulo: 2016.
          </Text>
          <Text>
            Agência Nacional de Vigilância Sanitária - ANVISA. Rotulagem
            nutricional : novas regras entram em vigor em 120 dias.Disponível
            em:
            https://www.gov.br/anvisa/pt-br/assuntos/noticias-anvisa/2022/rotulagem-nutricional-novas-regras-entram-em-vigor-em-120-dias.
            Acesso em 02 maio de 2023.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
          <Text>
            https://lh3.googleusercontent.com/QgpxQXDK9oWvwfKovsrl5K_KuHiaTHia-0YAeJDRoWFwVz4oe4DNDIlC0mlOea5kwSnW1sU8g6Y_p-Nee69eQm32SW_hpWtQnfeGvnA
          </Text>
          <Text>
            https://lh3.googleusercontent.com/QXsZMYmxby-vru3d5aPI3E14FX5MOVzxshsjKlyN6QUKVFU3XAHUJXfU7HV6X6mEiNuDXRA4JI_an94qnHn9hL1dStYvy6EZ4h017-Pg
          </Text>
          <Text>
            https://lh3.googleusercontent.com/w9e_cMZXRn4T4sqHa-wGSKuOOSXE_bqEszbLODrSJS2y9XglBd2ztVaZ2bvALEs_idZEkKrqDCBw7h4OKJ9R292enMgzG5YLhfnsorc
          </Text>
          <Text>
            https://lh3.googleusercontent.com/d2Px2ZuMruPZDggpIREFhXMcCXnSBnFe7D63oJX95lODDys_UyWk9ka2XdQJzDjNAltwadlhAJ5gHoDEUxJzk90PoQOoaLhHMItnI619
          </Text>
          <Text>
            https://lh3.googleusercontent.com/dDD1bPtLdSQmAGVmbeuh-y15Fb1siVnG7f1gB_mkaXbuOicqlVN0prGOFnjGE_5uzaXiAawPXmbKYaTABO2bfI9WXY5OYU_ZaE76QH8
          </Text>
        </Content>
        <VejaTambem post={0} />
        <Content className="forum">
          <Forum
            title="O que geralmente você come, quando não está em casa e está no momento de se alimentar?​"
            postNumber={1}
          />
        </Content>
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
