import React from "react";
import { Text as Textt } from "./Text.styles";

interface TextProps {
  className?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption";
  children: React.ReactNode;
}

export const Text: React.FC<TextProps> = ({
  className,
  variant,
  children,
}) => {
  return (
    <Textt variant={variant} className={className}>
      {children as React.ReactNode}
    </Textt>
  );
};
