import { styled } from "@mui/system"; 
import { Box } from "@mui/material";
import "utils/variables.css";

export const CardContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid var(--border-green)",
    borderRadius: "var(--border-radius)",
    padding: "1rem",
    width: "90%",
    "@media (max-width: 560px)": {
        flexDirection: "column",
        width: "90%",
    },
});

export const TextContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
});

export const Image = styled("img")({
    // width: "100%",
})