import React from "react";
import {
  CardContainer,
  TextContainer,
  Image,
} from "./Card.styles";
import image01 from "assets/images/home/home01.png";
import { Text } from "components";

interface CardProps {
  title: string;
  text: string;
  image?: string;
}

export const Card: React.FC<CardProps> = ({title, text, image}) => {
  return (
    <CardContainer>
      <Image src={image01} alt="Imagem de uma mulher com diabetes" />
      <TextContainer>
        <Text className="title" variant="h1">{title}</Text>
        <Text>
         {text}
        </Text>
      </TextContainer>
    </CardContainer>
  );
};
