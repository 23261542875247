import React from "react";
import { Text } from "./Title.styles";

interface TitleProps {
  className?: string;
  variant?:  "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" ;
  children: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({
  className,
  variant,
  children,
}) => {
  return (
    <Text variant={variant} className={className}>
      {children as React.ReactNode}
    </Text>
  );
};
