import { AppBar } from "components/AppBar";
import { Button } from "components/Button";
import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Container, HeaderWrapper, Informs } from "./Header.styles";
import { Title } from "components/Title";
import { Text } from "components/Text";

export const Header: React.FC = () => {
  const onButtonClick = () => {
    const viewPubs = document.getElementsByClassName("publicacoes")[0];
    viewPubs.scrollIntoView({
      behavior: "smooth",
    });
    document.location.hash = "#publicacoes";
  };

  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <React.Fragment>
      <AppBar active="inicio" id="mobile" />
      <HeaderWrapper id="header" component="section">
        <AppBar active="inicio" id="computer" />
        <Container component="header">
          <Informs data-aos="fade-right">
            <Title className="white" variant="h1">
              Viva bem com <br /> o Diabetes!
            </Title>
            <Text className="white subtitle" variant="h2">
              Aprenda a conviver melhor com sua doença, cuide-se e mantenha uma
              vida saudável
            </Text>
            <Button className="button" onClick={onButtonClick} bgColor="main">
              Saiba mais
            </Button>
          </Informs>
        </Container>
      </HeaderWrapper>
    </React.Fragment>
  );
};
