import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import "utils/variables.css";

export const FooterWrapper = styled(Box)({
  display: "grid",
  background: "var(--dark-bg)",
  gridTemplateColumns: "repeat(1, 1fr)",
  padding: "1rem 0.5rem 2rem 0.5rem",
  alignItems: "center",
  gap: "0rem",
  justifyContent: "center",
  justifyItems: "center",
  // "@media(max-width: 680px)": {
  //   gridTemplateColumns: "repeat(1, 1fr)",
  //   gap: "5rem",
  //   padding: "2.5rem 0.5rem 3.5rem 0.5rem",
  // },
});

// export const InfosContainer = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   gap: "1.2rem",
//   alignItems: "stretch",
//   maxWidth: "45%",
//   "@media (max-width: 720px)": {
//     maxWidth: "70%",
//     alignItems: "center",
//   },
// });

export const InfosContainer = styled(Box)({
  display: "grid",
  background: "var(--dark-bg)",
  gridTemplateColumns: "repeat(1, 1fr)",
  padding: "1rem 0.5rem 2rem 0.5rem",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "center",
  "@media(max-width: 680px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "1rem",
    //padding: "2.5rem 0.5rem 3.5rem 0.5rem",
  },
});

 export const Text = styled(Typography)({
   fontFamily: "Inter",
   fontSize: "1.5rem",
   fontWeight: "400",
   color: "#FFFFFF",
   textAlign: "center",
   "&.author": {
    fontWeight: "600",
    fontSize: "24px",
   },
   "&.copywriter": {
    fontWeight: "700",
    fontSize: "18px",
   },
   "@media (max-width: 720px)": {
     textAlign: "center",
   },
   "@media (max-width: 500px)": {
     fontSize: "0.8rem",
   },
 });


