import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  maxWidth: "100%",
  minWidth: "500px",
  padding: "1rem",
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
  },
});
export const CommentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.5rem",
  margin: "0.5rem 0",

  "& .comment-input": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "0.5rem",
  },
});
export const Row = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "1rem",
  padding: "0.5rem",
  "& .name": {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .email": {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "@media (max-width: 720px)": {
    flexDirection: "column",
    gap: "0rem",
    "& .name": {
      width: "100%",
    },
    "& .email": {
      width: "100%",
    },
  }
})
export const Input = styled("input")({
  width: "100%",
  fontFamily: "var(--font)",
  padding: "0.5rem 0",
  borderRadius: "var(--border-radius)",
  margin: "0.5rem 0",
  fontSize: "var(--text-size)"
});
export const CommentInput = styled("textarea")({
  width: "100%",
  fontFamily: "var(--font)",
  padding: "0.5rem 0",
  borderRadius: "var(--border-radius)",
  margin: "0.5rem 0",
  fontSize: "var(--text-size)",
});
export const HelperText = styled("span")({
  alignSelf: "flex-start",
  fontFamily: "var(--font)",
  color: "#454545",
  display: "none",
  fontSize: "calc(var(--text-size) * 0.8)",
  "&.valid": {
    color: "green",
  },
  "&.invalid": {
    color: "red",
  },
})