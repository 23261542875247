import React from "react";
import Image01 from "assets/images/posts/post03/imagem_01.png";
import Image02 from "assets/images/posts/post03/imagem_02.png";
import Image03 from "assets/images/posts/post03/imagem_03.png";
import Image04 from "assets/images/posts/post03/imagem_04.png";
import Image05 from "assets/images/posts/post03/imagem_05.png";
// import Image07 from "assets/images/posts/post03/image07.png";
// import Image08 from "assets/images/posts/post03/image08.png";

import Image01Seringa from "assets/images/posts/post03/imagem_seringa_01.png";
import Image02Seringa from "assets/images/posts/post03/imagem_seringa_02.png";
import Image03Seringa from "assets/images/posts/post03/imagem_seringa_03.png";
import Image04Seringa from "assets/images/posts/post03/imagem_seringa_04.png";

import Image01AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_01.png";
import Image02AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_02.png";
import Image03AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_03.png";
import Image04AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_04.png";
import Image05AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_05.png";
import Image06AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_06.png";
import Image07AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_07.png";
import Image08AplicacaoSeringa from "assets/images/posts/post03/imagem_aplicacao_seringa_08.png";

import Image01AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_01.png";
import Image02AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_02.png";
import Image03AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_03.png";
import Image04AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_04.png";
import Image05AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_05.png";
import Image06AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_06.png";
import Image07AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_07.png";
import Image08AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_08.png";
import Image09AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_09.png";
import Image10AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_10.png";
import Image11AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_11.png";
import Image12AplicacaoCaneta from "assets/images/posts/post03/imagem_aplicacao_caneta_12.png";

import {
  AppBar,
  Footer,
  ListPosts,
  Text,
  Title,
  Modal,
  VejaTambem,
  Forum,
} from "components";
import {
  Content,
  Image,
  PostWrapper,
  WrapperRow,
  WrapperImage,
  WrapperTable,
  TextWrapper,
  List,
  ListItem,
} from "./TomandoMedicacao.styles";
import { Box } from "@mui/material";

export const TomandoMedicacao: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);
  const [openImg06, setOpenImg06] = React.useState(false);
  const [openImg07, setOpenImg07] = React.useState(false);
  const [openImg08, setOpenImg08] = React.useState(false);
  const [openImg09, setOpenImg09] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);
  const handleOpenImg6 = () => setOpenImg06(true);
  const handleOpenImg7 = () => setOpenImg07(true);
  const handleOpenImg8 = () => setOpenImg08(true);
  const handleOpenImg9 = () => setOpenImg09(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);
  const handleCloseImg6 = () => setOpenImg06(false);
  const handleCloseImg7 = () => setOpenImg07(false);
  const handleCloseImg8 = () => setOpenImg08(false);
  const handleCloseImg9 = () => setOpenImg09(false);

  const [openImg10, setOpenImg10] = React.useState(false);
  const [openImg11, setOpenImg11] = React.useState(false);
  const [openImg12, setOpenImg12] = React.useState(false);
  const [openImg13, setOpenImg13] = React.useState(false);
  const [openImg14, setOpenImg14] = React.useState(false);
  const [openImg15, setOpenImg15] = React.useState(false);
  const [openImg16, setOpenImg16] = React.useState(false);
  const [openImg17, setOpenImg17] = React.useState(false);
  const [openImg18, setOpenImg18] = React.useState(false);

  const handleOpenImg10 = () => setOpenImg10(true);
  const handleOpenImg11 = () => setOpenImg11(true);
  const handleOpenImg12 = () => setOpenImg12(true);
  const handleOpenImg13 = () => setOpenImg13(true);
  const handleOpenImg14 = () => setOpenImg14(true);
  const handleOpenImg15 = () => setOpenImg15(true);
  const handleOpenImg16 = () => setOpenImg16(true);
  const handleOpenImg17 = () => setOpenImg17(true);
  const handleOpenImg18 = () => setOpenImg18(true);

  const handleCloseImg10 = () => setOpenImg10(false);
  const handleCloseImg11 = () => setOpenImg11(false);
  const handleCloseImg12 = () => setOpenImg12(false);
  const handleCloseImg13 = () => setOpenImg13(false);
  const handleCloseImg14 = () => setOpenImg14(false);
  const handleCloseImg15 = () => setOpenImg15(false);
  const handleCloseImg16 = () => setOpenImg16(false);
  const handleCloseImg17 = () => setOpenImg17(false);
  const handleCloseImg18 = () => setOpenImg18(false);

  const [openImg19, setOpenImg19] = React.useState(false);
  const [openImg20, setOpenImg20] = React.useState(false);
  const [openImg21, setOpenImg21] = React.useState(false);
  const [openImg22, setOpenImg22] = React.useState(false);
  const [openImg23, setOpenImg23] = React.useState(false);
  const [openImg24, setOpenImg24] = React.useState(false);
  const [openImg25, setOpenImg25] = React.useState(false);
  const [openImg26, setOpenImg26] = React.useState(false);
  const [openImg27, setOpenImg27] = React.useState(false);
  const [openImg28, setOpenImg28] = React.useState(false);
  const [openImg29, setOpenImg29] = React.useState(false);

  const handleOpenImg19 = () => setOpenImg19(true);
  const handleOpenImg20 = () => setOpenImg20(true);
  const handleOpenImg21 = () => setOpenImg21(true);
  const handleOpenImg22 = () => setOpenImg22(true);
  const handleOpenImg23 = () => setOpenImg23(true);
  const handleOpenImg24 = () => setOpenImg24(true);
  const handleOpenImg25 = () => setOpenImg25(true);
  const handleOpenImg26 = () => setOpenImg26(true);
  const handleOpenImg27 = () => setOpenImg27(true);
  const handleOpenImg28 = () => setOpenImg28(true);
  const handleOpenImg29 = () => setOpenImg29(true);

  const handleCloseImg19 = () => setOpenImg19(false);
  const handleCloseImg20 = () => setOpenImg20(false);
  const handleCloseImg21 = () => setOpenImg21(false);
  const handleCloseImg22 = () => setOpenImg22(false);
  const handleCloseImg23 = () => setOpenImg23(false);
  const handleCloseImg24 = () => setOpenImg24(false);
  const handleCloseImg25 = () => setOpenImg25(false);
  const handleCloseImg26 = () => setOpenImg26(false);
  const handleCloseImg27 = () => setOpenImg27(false);
  const handleCloseImg28 = () => setOpenImg28(false);
  const handleCloseImg29 = () => setOpenImg29(false);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={2} />
        <Content>
          <Title variant="h1">Usar os medicamentos </Title>
          <Text>
            Às vezes pode ser necessário usar medicamentos, comprimidos ou
            injeção de insulina que devem ser utilizados, de acordo com a
            prescrição do médico.
          </Text>
          <Text>
            É importante entender como cada medicamento afeta o organismo e a
            glicemia e informar sobre os medicamentos que está tomando,
            suplementos alimentares, vitaminas.
          </Text>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg1} src={Image01} alt="Imagem 01." />
              <Modal open={openImg01} onClose={handleCloseImg1}>
                <Image className="modal" src={Image01} alt="Imagem 01." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Os medicamentos antidiabéticos orais como a metformina têm a
                função de diminuir os níveis de glicose no sangue. A quantidade
                vai depender do nível de tratamento. É importante entender que
                cada medicamento afeta nosso corpo. Eles podem causar alguns
                efeitos colaterais como enjoos, vômito, coceira, dor de barriga;
                fraqueza, fadiga, vertigem, tontura, dor de cabeça. Caso observe
                algum destes sintomas, converse com os seus profissionais de
                saúde para decidir o que deve ser feito e fique atento às dicas
                a seguir.
              </Text>
            </Box>
          </WrapperRow>
          <Title> Vamos às dicas</Title>
          <WrapperRow>
            <Box className="text-container">
              {/*<Text>
                O remédio vencido deve ser descartado na Unidade de Saúde e não
                em lixo comum. Também não faça uso de nenhum medicamento, chá,
                ervas, sem orientação médica. A mesma orientação vale para
                agulhas, seringas e qualquer outro material ou medicamento
                usado.
            </Text> */}
              <List>
                <ListItem>
                  Todo medicamento deve ser administrado no mesmo horário todos os
                  dias.
                </ListItem>
                <ListItem>
                  Organize seus remédios para uso diário e respeite os horários
                  determinados pelo seu médico.
                </ListItem>
                <ListItem>
                  Quando esquecer algum medicamento, se for apenas uma vez ao dia, é
                  melhor administrar assim que lembrar.
                </ListItem>
                <ListItem>
                  Organize seus remédios em um único local da casa e observe a data
                  de validade. O remédio vencido deve ser descartado na Unidade de
                  Saúde e não em lixo comum.
                </ListItem>
                <ListItem>
                  Não faça uso de nenhum medicamento, chá, ervas, sem orientação
                  médica.
                </ListItem>
                <ListItem>
                  Não suspenda ou altere a dose do medicamento sem antes conversar
                  com seu médico ou profissional de saúde.
                </ListItem>
              </List>
            </Box>
            <Box className="image-container">
              <Image onClick={handleOpenImg3} src={Image03} alt="Imagem 03." />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image className="modal" src={Image03} alt="Imagem 03." />
              </Modal>

              <Image onClick={handleOpenImg4}  src={Image04} alt="Imagem 04." />
              <Modal open={openImg04} onClose={handleCloseImg4}>
                <Image className="modal" src={Image04} alt="Imagem 04." />
              </Modal>
            </Box>
          </WrapperRow>
          <WrapperRow>

            <Box className="text-container">
              {/*<Text>
                Todo medicamento deve ser administrado no mesmo horário todos os
                dias. Organize seus remédios para uso diário e respeite os
                horários determinados pelo seu médico. Quando esquecer algum
                medicamento, se for apenas uma vez ao dia, é melhor administrar
                assim que lembrar.
              </Text>*/}
              {/*<Text>
                Não suspenda ou altere a dose do medicamento sem antes conversar
                com seu médico ou profissional de saúde.
              </Text> */}
            </Box>
          </WrapperRow>
          <Title> Tipos de insulina</Title>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg2} src={Image02} alt="Imagem 02." />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image className="modal" src={Image02} alt="Imagem 02." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                A aplicação de insulina é realizada por injeção no tecido
                subcutâneo (na gordura) nas seguintes regiões: abdome, face
                posterior do braço, face anterolateral da coxa e região externa
                do glúteo, como mostra a figura ao lado. É importante realizar o
                rodízio entre os locais de aplicação de insulina para evitar
                alterações e nódulos decorrentes de aplicações repetidas no
                mesmo local.
              </Text>
            </Box>
          </WrapperRow>
          <Text>
            Existem alguns tipos de insulina, mas as mais conhecidas são a
            Regular e a NPH e que são distribuídas gratuitamente. Elas se
            diferenciam conforme seu funcionamento. Confira no quadro abaixo os
            tipos de insulinas e suas características, como nome da insulina, o
            início de ação (o tempo que a insulina irá começar a fazer efeito
            após a aplicação), o pico de ação (o tempo em que o efeito da
            insulina atinge seu pico mais alto de ação) e a duração (o tempo de
            efeito da insulina após sua aplicação).
          </Text>
          <Text>
            As insulinas rápidas, prandiais ou bolus são aquelas que devem ser
            aplicadas antes das refeições ou para corrigir uma hiperglicemia. E
            as insulinas basais servem para evitar liberação de glicose pelo
            fígado no período entre as refeições.
          </Text>
          <PostWrapper className="postwrapper">
            <Image onClick={handleOpenImg5} src={Image05} alt="Imagem 05." />
            <Text className="font">
              Fonte: Sociedade Brasileira de Diabetes (2023).
            </Text>
            <Modal open={openImg05} onClose={handleCloseImg5}>
              <Image className="modal" src={Image05} alt="Imagem 05." />
            </Modal>
          </PostWrapper>
          <Text>
            As seringas recomendadas para aplicar a insulina tem as agulhas
            acopladas e são graduadas em unidades, 30, 50 ou 100 Unidades. Elas
            possuem um tracinho e dependendo da seringa{" "}
            <span className="bold">
              cada tracinho pode medir 1 unidade (nas seringas de 30 U e de 50
              U) ou 2 unidades (na seringa de 100U)
            </span>
            (Ver figura abaixo).
          </Text>
          <WrapperImage>
            <Image
              onClick={handleOpenImg6}
              className="small_size"
              src={Image01Seringa}
              alt="Imagem 06."
            />
            <Modal open={openImg06} onClose={handleCloseImg6}>
              <Image className="modal" src={Image01Seringa} alt="Imagem 06." />
            </Modal>
            <Image
              onClick={handleOpenImg7}
              className="small_size"
              src={Image02Seringa}
              alt="Imagem 07."
            />
            <Modal open={openImg07} onClose={handleCloseImg7}>
              <Image className="modal" src={Image02Seringa} alt="Imagem 07." />
            </Modal>
            <Image
              onClick={handleOpenImg8}
              className="small_size"
              src={Image03Seringa}
              alt="Imagem 08."
            />
            <Modal open={openImg08} onClose={handleCloseImg8}>
              <Image className="modal" src={Image03Seringa} alt="Imagem 08." />
            </Modal>
            <Image
              onClick={handleOpenImg9}
              className="small_size"
              src={Image04Seringa}
              alt="Imagem 09."
            />
            <Modal open={openImg09} onClose={handleCloseImg9}>
              <Image className="modal" src={Image04Seringa} alt="Imagem 09." />
            </Modal>
          </WrapperImage>
          <br></br>
          <Title>Passo a passo para aplicação de insulina com seringa</Title>
          <WrapperTable>
            <Box className="item">
              <Image
                onClick={handleOpenImg10}
                className="small_size"
                src={Image01AplicacaoSeringa}
                alt="Imagem 10."
              />
              <Modal open={openImg10} onClose={handleCloseImg10}>
                <Image
                  className="modal"
                  src={Image01AplicacaoSeringa}
                  alt="Imagem 10."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">1-</span> Lave bem as mãos antes de
                  todas as aplicações para evitar infecções.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg11}
                className="small_size"
                src={Image02AplicacaoSeringa}
                alt="Imagem 11."
              />
              <Modal open={openImg11} onClose={handleCloseImg11}>
                <Image
                  className="modal"
                  src={Image02AplicacaoSeringa}
                  alt="Imagem 11."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">2-</span> Role o frasco do medicamento
                  entre as mãos. Faça 20 repetiçoes.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg12}
                className="small_size"
                src={Image03AplicacaoSeringa}
                alt="Imagem 12."
              />
              <Modal open={openImg12} onClose={handleCloseImg12}>
                <Image
                  className="modal"
                  src={Image03AplicacaoSeringa}
                  alt="Imagem 12."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">3- </span>Higienize a tampa do frasco
                  com álcool 70% para evitar qualquer tipo de contaminação.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg13}
                className="small_size"
                src={Image04AplicacaoSeringa}
                alt="Imagem 13."
              />
              <Modal open={openImg13} onClose={handleCloseImg13}>
                <Image
                  className="modal"
                  src={Image04AplicacaoSeringa}
                  alt="Imagem 13."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">4-</span>Coloque ar dentro da seringa
                  com o valor da quantidade da dose que você deve tomar.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg14}
                className="small_size"
                src={Image05AplicacaoSeringa}
                alt="Imagem 14."
              />
              <Modal open={openImg14} onClose={handleCloseImg14}>
                <Image
                  className="modal"
                  src={Image05AplicacaoSeringa}
                  alt="Imagem 14."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">5- </span>Adicione o ar dentro do
                  frasco.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg15}
                className="small_size"
                src={Image06AplicacaoSeringa}
                alt="Imagem 15."
              />
              <Modal open={openImg15} onClose={handleCloseImg15}>
                <Image
                  className="modal"
                  src={Image06AplicacaoSeringa}
                  alt="Imagem 15."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">6-</span>Vire o frasco para cima e puxe
                  a dose correta.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg16}
                className="small_size"
                src={Image07AplicacaoSeringa}
                alt="Imagem 16"
              />
              <Modal open={openImg16} onClose={handleCloseImg16}>
                <Image
                  className="modal"
                  src={Image07AplicacaoSeringa}
                  alt="Imagem 16"
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">7- </span>Escolha o local de aplicação
                  e em seguida faça a limpeza com álcool a 70%;
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg17}
                className="small_size"
                src={Image08AplicacaoSeringa}
                alt="Imagem 17."
              />
              <Modal open={openImg17} onClose={handleCloseImg17}>
                <Image
                  className="modal"
                  src={Image08AplicacaoSeringa}
                  alt="Imagem 17."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold"> 8- </span>Faça a prega e insira a
                  agulha em um ângulo de 90º. Após a aplicação da dose, mantenha
                  a agulha por 5 segundos;
                </Text>
              </TextWrapper>
            </Box>
          </WrapperTable>
          <Text className="title">Atenção!</Text>
          <List>
            <ListItem>
              Descarte as agulhas em um recipiente vazio e rígido como frasco de
              amaciante que tenha em casa e quando cheio, deve ser levado até
              unidade de saúde para o descarte apropriado.
            </ListItem>
            <ListItem>
              Em caso de usar duas insulinas (NPH e REGULAR), deve somar a dose
              das duas insulinas para aspirar o ar correspondente e seguir as
              mesmas instruções repassadas anteriormente;
            </ListItem>
            <ListItem>
              Injete primeiro ar na dose correspondente à insulina NPH, mas não
              aspire. Em seguida faça o mesmo processo para a dose de insulina
              Regular, mas agora aspirando e em seguida, com a mesma seringa,
              aspire a insulina NPH com o frasco de cabeça para baixo;
            </ListItem>
            <ListItem>
              A insulina nova deve ficar guardada na geladeira. Quando aberta, a
              insulina pode ser conservada em temperatura ambiente de até 30ºC.
            </ListItem>
          </List>
          <br></br>
          <Title> Passo a passo para aplicação com caneta de insulina</Title>
          <WrapperTable>
            <Box className="item">
              <Image
                onClick={handleOpenImg18}
                className="small_size"
                src={Image01AplicacaoCaneta}
                alt="Imagem 18."
              />
              <Modal open={openImg18} onClose={handleCloseImg18}>
                <Image
                  className="modal"
                  src={Image01AplicacaoCaneta}
                  alt="Imagem 18."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">1- </span>Lave bem as mãos antes de
                  todas as aplicações para evitar infecções.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg19}
                className="small_size"
                src={Image02AplicacaoCaneta}
                alt="Imagem 19."
              />
              <Modal open={openImg19} onClose={handleCloseImg19}>
                <Image
                  className="modal"
                  src={Image02AplicacaoCaneta}
                  alt="Imagem 19."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">2- </span>Retire a tampa e identifique
                  o tipo de insulina que você irá aplicar..
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg20}
                className="small_size"
                src={Image03AplicacaoCaneta}
                alt="Imagem 20."
              />
              <Modal open={openImg20} onClose={handleCloseImg20}>
                <Image
                  className="modal"
                  src={Image03AplicacaoCaneta}
                  alt="Imagem 20."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">3- </span>Pegue uma agulha nova e
                  remova o lacre.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg21}
                className="small_size"
                src={Image04AplicacaoCaneta}
                alt="Imagem 21."
              />
              <Modal open={openImg21} onClose={handleCloseImg21}>
                <Image
                  className="modal"
                  src={Image04AplicacaoCaneta}
                  alt="Imagem 21."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">4- </span>Rosquei a agulha na caneta
                  até que firme bem.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg22}
                className="small_size"
                src={Image05AplicacaoCaneta}
                alt="Imagem 22."
              />
              <Modal open={openImg22} onClose={handleCloseImg22}>
                <Image
                  className="modal"
                  src={Image05AplicacaoCaneta}
                  alt="Imagem 22."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">5- </span>Retire a tampa externa e
                  guarde para usar depois.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg23}
                className="small_size"
                src={Image06AplicacaoCaneta}
                alt="Imagem 23."
              />
              <Modal open={openImg23} onClose={handleCloseImg23}>
                <Image
                  className="modal"
                  src={Image06AplicacaoCaneta}
                  alt="Imagem 23."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">6- </span>Retire a tampa interna e
                  descarte.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg24}
                className="small_size"
                src={Image07AplicacaoCaneta}
                alt="Imagem 24."
              />
              <Modal open={openImg24} onClose={handleCloseImg24}>
                <Image
                  className="modal"
                  src={Image07AplicacaoCaneta}
                  alt="Imagem 24."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">7- </span>Para retirar o ar da caneta,
                  gire o seletor até o número 2.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg25}
                className="small_size"
                src={Image08AplicacaoCaneta}
                alt="Imagem 25."
              />
              <Modal open={openImg25} onClose={handleCloseImg25}>
                <Image
                  className="modal"
                  src={Image08AplicacaoCaneta}
                  alt="Imagem 25."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">8- </span>Segure a agulha para cima e
                  bata levemente com o dedo para as bolhas de ar subirem.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg26}
                className="small_size"
                src={Image09AplicacaoCaneta}
                alt="Imagem 26."
              />
              <Modal open={openImg26} onClose={handleCloseImg26}>
                <Image
                  className="modal"
                  src={Image09AplicacaoCaneta}
                  alt="Imagem 26."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">9- </span>Pressione o seletor para
                  retirar as bolhas até que saia uma gota. Depois o seletor deve
                  voltar a zero.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg27}
                className="small_size"
                src={Image10AplicacaoCaneta}
                alt="Imagem 27."
              />
              <Modal open={openImg27} onClose={handleCloseImg27}>
                <Image
                  className="modal"
                  src={Image10AplicacaoCaneta}
                  alt="Imagem 27."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">10- </span>Escolha o local de aplicação
                  e em seguida faça a limpeza com álcool a 70%;
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg28}
                className="small_size"
                src={Image11AplicacaoCaneta}
                alt="Imagem 28."
              />
              <Modal open={openImg28} onClose={handleCloseImg28}>
                <Image
                  className="modal"
                  src={Image11AplicacaoCaneta}
                  alt="Imagem 28."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">11- </span>Fazer a prega subcutânea, se
                  necessário, e introduzir a agulha. Pressionar o botão injector
                  da caneta e aguardar no mínimo 10 segundos.
                </Text>
              </TextWrapper>
            </Box>
            <Box className="item">
              <Image
                onClick={handleOpenImg29}
                className="small_size"
                src={Image12AplicacaoCaneta}
                alt="Imagem 29."
              />
              <Modal open={openImg29} onClose={handleCloseImg29}>
                <Image
                  className="modal"
                  src={Image12AplicacaoCaneta}
                  alt="Imagem 29."
                />
              </Modal>
              <TextWrapper>
                <Text>
                  <span className="bold">12- </span>Soltar a prega subcutânea.
                  Remover a agulha da caneta usando o protetor externo e
                  descartá-la em local adequado. Recolocar a tampa da caneta.
                </Text>
              </TextWrapper>
            </Box>
          </WrapperTable>
          <Text>
            A caneta de insulina já vem preenchida com insulina, é discreta,
            fácil de usar e transportar. Já estão disponíveis no SUS as canetas
            de insulina NPH e Regular. Para isso, é preciso a prescrição médica
            e ver quais os critérios para receber a caneta de insulina pelo SUS.
          </Text>

          <Text className="title">Atenção!</Text>
          <List>
            <ListItem>
              Usar a agulha da caneta ou seringa apenas uma vez, se possível.
              Dar preferência para agulha menores para evitar injeção no
              músculo.
            </ListItem>
            <ListItem>
              Muita atenção para as canetas para não trocá-las na hora da
              aplicação. Confira o nome da insulina e as cores do botão injetor
              que fica na ponta da caneta. Por exemplo, a NPH tem botão na cor
              verde e regular tem botão na cor amarela.
            </ListItem>
            <ListItem>
              Observe que a insulina NPH é branca (cor do líquido preenchido) e
              a Regular é incolor. Assim, a insulina NPH deve ser homogeneizada
              realizando movimentos leves de cima para baixo ou circulares (20
              vezes).
            </ListItem>
            <ListItem>
              A insulina lacrada, frasco ou caneta, deve ser armazenada na
              geladeira dentro de um recipiente de plástico, nas prateleiras ou
              na parte das verduras. Anote a data de início do uso para
              acompanhar sua validade. Após sua abertura e início de utilização,
              a caneta deve ser guardada em temperatura ambiente. Nenhuma
              insulina pode ser congelada e ficar exposta ao calor!
            </ListItem>
            <ListItem>
              Se deixar a insulina na geladeira, deve ser retirada alguns
              minutos antes de sua aplicação, pois a insulina gelada causa dor.
            </ListItem>
            <ListItem>
              Após aberta, a insulina tem validade de 4 a 8 semanas, sendo NPH e
              Regular (4-6 semanas) e análogas (até 8 semanas). Olhar sempre a
              bula.
            </ListItem>
          </List>

          <Title> Dicas de ouro!</Title>
          <Text>
            Se você chegou até aqui, temos algumas orientações bônus!!
          </Text>
          <Text>
            Uma dica simples e fácil é{" "}
            <span className="bold">
              {" "}
              organizar seus remédios em caixas conforme a necessidade e
              conforme os horários{" "}
            </span>{" "}
            que você geralmente usa os medicamentos. Pode ser em uma caixa de
            sapatos ou então em uma caixa menor, desde que caiba todos os
            remédios que você toma durante o dia.
          </Text>
          <Text>
            <span className="bold">Separe as divisórias </span> com pedaços de
            papelão, de acordo com os horários que geralmente usa os remédios e
            cole figuras que representem ou{" "}
            <span className="bold"> te façam lembrar dos horários </span>, como
            por exemplo: sol para determinar a manhã, um prato de comida para
            sinalizar os medicamentos antes ou após o almoço e uma lua para
            representar a noite. Assim fica mais fácil lembrar!
          </Text>
          <Text>
            Se você tiver um celular, também pode{" "}
            <span className="bold">
              colocar alarmes nos horários que deve usar os medicamentos
            </span>{" "}
            ou a insulina, conforme recomendação médica.
          </Text>
          <br></br>
          <Text className="title">REFERÊNCIAS</Text>
          <Text>
            BANCA, R. O; MARRONI, M. S; OLIVEIRA, M. C. et al. Técnicas de
            aplicação de insulina. Editor chefe: Marcello Bertoluci. Última
            revisão em: 03/02/2022[Quebra da Disposição de Texto]DOI:
            10.29327/557753.2022-4.
          </Text>
          <Text>
            O tamanho da agulha para a aplicação de insulina. Disponível em:
            https://www.diabetescenter.com.br/portaldiabetes/o-tamanho-da-agulha-para-a-aplicacao-de-insulina/.
            Acesso em: 03 maio 2023.
          </Text>
          <Text>
            Silva Júnior WS, Gabbay M, Lamounier R, Bertoluci M. Insulinoterapia
            no diabetes mellitus tipo 1 (DM1). Diretriz Oficial da Sociedade
            Brasileira de Diabetes (2022). DOI: 10.29327/557753.2022-5, ISBN:
            978-65-5941-622-6.
          </Text>
          <Text>
            SECRETARIA DE ESTADO DA SAÚDE DO RIO GRANDE DO SUL. Departamento de
            Assistência Farmacêutica. Programa Cuidar+. Campanha de Cuidado da
            Pessoa com Diabetes.Porto Alegre: 2021.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
          <Text>
            https://lh3.googleusercontent.com/UKeIFoE5IdrrqZ0urzQ3jKWQn2jD2D__UutE9qV4
            Q-iTtjWxDbPuH-7Qd0uBG7a6anBNl-xatLW8zopsEx6GjM4eGbkNkKgK-K5x-u58Lg
          </Text>
          <Text>
            https://lh3.googleusercontent.com/urvsI7JVnZFoRbrjjZaFzgrMbsy3aNmALuGvlkDsN
            eOJ__auiq0Yge55Ooy8gjyGFr-l2el-RyBvz2VXa09Y-U-lp9Wzj3Klb4epdOVAnQ
          </Text>
          <Text>
            https://lh3.googleusercontent.com/o5U6i9eP2TCcU6S0MiRYMWKbTM623V4M6VS
            LR1K-
            JfIAnm_vI4lUkYF6sPl0BNTwO7LINBzMksTxnLHox3o1EpkKksuCEEUejoJ7OMXB
          </Text>
          <Text>
            https://lh3.googleusercontent.com/qrRu8MMGCTHaIXhT134p-
            YRDQmA8VECbpVYZ4Ju3CQt29UMpUwH8mJBR_GDms4LUHk9RaBI90hgj_IHz4
            Bat3U5OBkTAU-YVbfwCidM
          </Text>
          <Text>
            https://lh3.googleusercontent.com/x534hIAhDsTLvyPIXBqxuGd1NMVCT1TFAGtjae
            Lsn38fCnEuQmIIonCaO8UavHGeDL6wa2j75fOF- _8BPfyaOr96mDPUmee1SNidmJ0
          </Text>
          <Text>
            https://lh3.googleusercontent.com/NBqydSb6PRnD7UnRR5IyTzGychhZ1NOkGMSy
            Jj5QbZ2G8SQz3cgaqxKzvtLe1U1gFDlWYqBeg8a0Ccm_Qh2w1r1r-
            nCt_qBVdvi3GlRe
          </Text>
        </Content>
        <VejaTambem post={2} />
        <Content className="forum">
          <Forum
            title="Compartilhe conosco onde você guarda seus medicamentos e como faz para lembrar de tomá-los.​"
            postNumber={4}
          />
        </Content>
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
