import { styled } from "@mui/system";
import { Box } from "@mui/material";


export const PostWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  height: "100%",
  backgroundColor: "var(--gray-bg)",
  padding: "2rem 0rem",
});

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "2rem",
  width: "calc(90% - 6rem)",
  backgroundColor: "var(--light-gb)",
  padding: "3rem",
  "&.forum": {
    alignItems: "center",
  },
  "@media (max-width: 780px)": {
    width: "calc(90% - 4rem)",
    padding: "2rem",
  },
  "@media (max-width: 550px)": {
    width: "calc(100% - 4rem)",
    padding: "2rem",
    borderRadius: "30px 30px 0px 0px",
  },
})


export const WrapperGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  maxWidth: "90%",
  padding: "2rem 0px",
  textAlign: "justify",
  gridAutoRows: "minmax(50px, auto)",
  rowGap: "1.5rem",
  justifyItems: "center",
  "& h1": {
    gridRow: "1/2",
    gridColumn: "1/2",
    textAlign: "left",
    "&.right-top": {
      gridColumn: "2/3",
    },
  },"&.left": {
    maxWidth: "100%",
    gridTemplateColumns: "3fr 1fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
      "& .image-container": {
        gridRow: "1/2",
        justifySelf: "center",

      }
    }
  },
  "&.right": {
    maxWidth: "100%",
    gridTemplateColumns: "1fr 3fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
    }
  },
  "&.left p and .right p": {
    textAlign: "justify",
    alignSelf: "center",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr",
    "& h1": {
      textAlign: "center",
      "&.right-top": {
        gridColumn: "1/2",
      },
    },
    
    "& .text-container": {
      gridRow: "3/4",
      gridColumn: "1/2",
    },
  },
  "@media (max-width: 480px)": {
    "& .image": {
      display: "none",
    },
  },
});

export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  maxWidth: "50%",
  minWidth: "500px",
  height: "100%",
  gap: "1rem",
  "& .container": {
    padding: "0px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
  },
});

export const Image = styled("img")({
  width: "90%",
  maxHeight: "700px",
  maxWidth: "700px",
  cursor: "pointer",
  objectFit: "contain",
  "&.modal": {
    height: "100%",
      width: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
  },
  "&.image_same_wrapper": {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "190px",
    width: "281px",
  },
});

export const WrapperTable = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1rem",
  width: "100%",
  height: "100%",
  "& .table-item": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    height: "100%",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 650px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});

export const NoticesCardsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "2rem",
  maxWidth: "100%",
  padding: "2rem 5%",
  background: "var(--gray-bg)",
  "&.about-me": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    padding: "2rem 10rem",
    "@media (max-width: 1500px)": {
      padding: "2rem 5rem",
    },
    "@media (max-width: 1200px)": {
      padding: "2rem 2rem",
    },
  },
  "& .title-pub": {
    justifySelf: "center",
    gridColumn: "1/4",
    padding: "2rem 0px",
    width: "60%",
  },
  "& .button-center": {
    justifySelf: "center",
    gridColumn: "1/4",
  },
  "@media (max-width: 880px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    "& .title-pub": {
      gridColumn: "1/3",
      width: "100%",
    },
    "& .button-center": {
      justifySelf: "center",
      gridColumn: "1/3",
    },
  },
  "@media (max-width: 550px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    "& .title-pub": {
      gridColumn: "1/2",
    },
    "& .button-center": {
      gridColumn: "1/2",
    },
  },
});
