import { Typography, styled } from "@mui/material";
import "utils/variables.css";

export const Text = styled(Typography)({
    fontFamily: "var(--font)",
    color: "var(--text-dark)",
    fontSize: "var(--text-size)",
    fontWeight: "var(--text-weight)",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    // wordBreak: "break-all",
    "&.bold": { fontWeight: "bold" },
    "& .bold": { fontWeight: "bold" },
    "&.title": {
        fontWeight: "var(--title-weight)",
    },
    "&.title-left": {
        fontWeight: "var(--title-weight)",
        textAlign: "left",
    },
    "&.white": {
        color: "var(--light-textcolor)",
    },
    "&.font":  {
        fontSize: "calc(var(--text-size)*0.95)",
        width: "80%",
        color: "#3b3b3b"
    },
    "&.center": {
        textAlign: "center",
    },
    "&.center-justify-grid": {
        textAlign: "justify",
        alignSelf: "center",
    },
    "&.justify": {
        textAlign: "justify",
    },
    "& a": {
        fontWeight: "600",
        padding: "0 0.5rem",
        color: "var(--light-green-bg)",
        wordBreak: "break-word",
    },
    "@media (min-width: 2000px)": {
        fontSize: "3.5rem",
    },
    "@media (min-width: 1600px)": {
        fontSize: "2.5rem",
    },
    "@media (min-width: 1500px)": {
        fontSize: "2rem",
    },
    "@media (min-width: 1400px)": {
        fontSize: "1.7rem",
    },
    "@media (min-width: 1300px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 630px)": {
        fontSize: "1.3rem",
    },
    "@media (max-width: 500px)": {
        textAlign: "center",
        "&.justify, &.center-justify-grid": {
            textAlign: "center",
        }
    },
});