import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const NoticeCardWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "var(--light-gb)",
  borderRadius: "var(--border-radius)", 
});
export const NoticeCardInfoWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem",
  height: "100%",
  gap: "1rem",
  "& h6": {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    lineClamp: 2,
  },
});
export const Image = styled("img")({
  width: "100%",
});
