import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const AboutWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "flex-start",
  padding: "2rem 2rem",
  "@media (max-width: 820px)": {
    flexDirection: "column",
    alignItems: "center",
  },
});
export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  maxWidth: "40%",
  height: "100%",
  gap: "1rem",
  "@media (max-width: 820px)": {
    maxWidth: "90%",
    alignItems: "center",
  },
});
export const Image = styled("img")({
  // maxWidth: "30%",
  objectFit: "cover",
  objectPosition: "center",
  padding: "0rem",
  // "@media (max-width: 950px)": {
  //   maxWidth: "25%",
  // },
  // "@media (max-width: 820px)": {
  //   maxWidth: "45%",
  //   padding: "1rem 0rem",
  // },
  // "@media (max-width: 500px)": {
  //   maxWidth: "70%",
  // },
  // "@media (max-width: 400px)": {
  //   maxWidth: "90%",
  // },
});
export const Title = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#605E5E",
  textAlign: "left",
  "@media (max-width: 820px)": {
    textAlign: "center",
  },
});
export const Text = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "1rem",
  fontWeight: "normal",
  color: "#605E5E",
  textAlign: "justify",
  "&.list": {
    textIndent: "1.5rem",
  },
  "@media (max-width: 820px)": {
    textAlign: "center",
  },
});
export const Legend = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "0.9rem",
  fontWeight: 400,
  color: "#605E5E",
  textAlign: "center",
  letterSpacing: "0.05rem",
  paddingBottom: "1rem",
  "& span.bold": {
    fontWeight: 600,
  },
  "@media (max-width: 820px)": {
    textAlign: "center",
  },
  "@media (max-width: 620px)": {
    fontSize: "0.8rem",
  },
  "@media (max-width: 320px)": {
    fontSize: "0.7rem",
  },
});
export const ImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  maxWidth: "20%",
  "@media (max-width: 950px)": {
    maxWidth: "25%",
  },
  "@media (max-width: 820px)": {
    maxWidth: "45%",
    padding: "1rem 0rem",
  },
  "@media (max-width: 500px)": {
    maxWidth: "70%",
  },
  "@media (max-width: 400px)": {
    maxWidth: "90%",
  },
});
export const Link = styled("a")({
  textDecoration: "none",
  color: "#605E5E",
  "&:hover": {
    textDecoration: "underline",
  },
});