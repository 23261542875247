import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  maxWidth: "100%",
  minWidth: "500px",
  padding: "1rem",
  "&.loading": {
    alignItems: "center",
  },
});
export const CommentsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  backgroundColor: "var(--gray-bg-dark)",
  "@media (max-width: 620px)": {
    backgroundColor: "var(--light-gb)",
  },
});
export const Divider = styled(Box)({
  width: "100%",
  height: "1px",
  backgroundColor: "var(--border-dark)",
  margin: "1rem 0",
});
