import React from "react";
import {
  Image,
  NoticeCardInfoWrapper,
  NoticeCardWrapper,
} from "./NoticeCard.styles";
import { ButtonLinkPost, Text } from "components";
import { To } from "react-router-dom";

interface NoticeCardProps {
  title: string;
  description: string;
  image: string;
  page: To;
  className?: string;
}

export const NoticeCard: React.FC<NoticeCardProps> = ({
  title,
  description,
  image,
  page,
  className
}) => {
  return (
    <NoticeCardWrapper className={className} component="section">
      <Image src={image} />
      <NoticeCardInfoWrapper className="noticecard-textcontainer">
        <Text className="title center" variant="h1">
          {title}
        </Text>
        <Text variant="subtitle2" className="justify">
          {description}
        </Text>
        <ButtonLinkPost to={page} bgColor="secondary">
          Saiba mais
        </ButtonLinkPost>
      </NoticeCardInfoWrapper>
    </NoticeCardWrapper>
  );
};
