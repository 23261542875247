import React from "react";
import { ButtonWrapper } from "./ButtonLinkPost.styles";
import { To } from "react-router";

interface ButtonProps {
  children: React.ReactNode;
  bgColor: "primary" | "secondary";
  onClick?: () => void;
  className?: string;
  to: To;
}
export const ButtonLinkPost: React.FC<ButtonProps> = ({
  children,
  bgColor,
  onClick,
  className,
  to
}) => {
  return (
    <ButtonWrapper to = {{
        pathname: `${to}`,
        hash: "#"
    }} className={`${className} ${bgColor}`} onClick={onClick}>
      {children}
    </ButtonWrapper>
  );
};
