import React from "react";
import Logo from "assets/icons/logo01.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { keyGenerator } from "utils/uniqueKey";
import {
  AppBarWrapper,
  ButtonWithIcon,
  ComputerWrapper,
  Image,
  ImgWrapperLink,
  Links,
  LinksContainer,
  MenuMobile,
  MenuMobileItemButton,
  MenuMobileItemText,
  MenuMobileList,
  MenuMobileListItem,
  MobileWrapper,
} from "./AppBar.styles";
import { PagesRoutes } from "features/constants";

interface AppBarProps {
  active: "inicio" | "sobre mim" | "referências" | "o projeto" | "";
  id?: "mobile" | "computer";
}

export const AppBar: React.FC<AppBarProps> = ({ active, id }) => {
  const links = [
    { text: "inicio", id: PagesRoutes.HOME, hash: "" },
    { text: "o projeto", id: `${PagesRoutes.HOME}`, hash: "#publicacoes" },
    { text: "sobre mim", id: PagesRoutes.HOME, hash: "#about" },
  ];
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const refAppBar = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (refAppBar.current) {
      if (active !== "o projeto") {
        refAppBar.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [active]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onButtonClick = (classname: string) => {
    const test = document.getElementsByClassName(classname)[0];
    test.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <AppBarWrapper id={id} ref={refAppBar} component="nav">
      <ComputerWrapper>
        <ImgWrapperLink to={PagesRoutes.HOME}>
          <Image src={Logo} />
        </ImgWrapperLink>
        <LinksContainer>
          {active === "inicio" ? (
            <React.Fragment>
              <Links to={PagesRoutes.HOME}>
                inicio
              </Links>
              <Links
                to={{
                  pathname: PagesRoutes.HOME,
                  hash: "#publicacoes",
                }}
                onClick={() => onButtonClick('publicacoes')}
              >
                o projeto
              </Links>
              <Links 
              to={{
                pathname: PagesRoutes.HOME,
                hash: "#about-me",
              }}
              onClick={() => onButtonClick('about-me')}
              >sobre mim</Links>
            </React.Fragment>
          ) : (
            links.map((link, index) => (
              <Links
                key={keyGenerator(index)}
                to={{
                  pathname: link.id,
                  hash: link.hash,
                }}
              >
                {link.text}
              </Links>
            ))
          )}
        </LinksContainer>
      </ComputerWrapper>
      <MobileWrapper>
        <ImgWrapperLink to={PagesRoutes.HOME}>
          <Image src={Logo} />
        </ImgWrapperLink>
        <ButtonWithIcon onClick={handleDrawerToggle} id="menu">
          <MenuIcon />
        </ButtonWithIcon>
      </MobileWrapper>
      <MenuMobile
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <ButtonWithIcon onClick={handleDrawerToggle}>
          <CloseIcon />
        </ButtonWithIcon>
        <MenuMobileList>
          {active === "inicio" ? (
            <React.Fragment>
              <MenuMobileListItem>
                <MenuMobileItemButton>
                  <MenuMobileItemText to={PagesRoutes.HOME} className="active">
                    inicio
                  </MenuMobileItemText>
                </MenuMobileItemButton>
              </MenuMobileListItem>
              <MenuMobileListItem>
                <MenuMobileItemButton>
                  <MenuMobileItemText
                    to={{
                      pathname: PagesRoutes.HOME,
                      hash: "#publicacoes",
                    }}
                    onClick={() => onButtonClick('publicacoes')}
                  >
                    o projeto
                  </MenuMobileItemText>
                </MenuMobileItemButton>
              </MenuMobileListItem>
              <MenuMobileListItem>
                <MenuMobileItemButton>
                  <MenuMobileItemText 
                  to={{
                    pathname: PagesRoutes.HOME,
                    hash: "#about-me",
                  }}
                  onClick={() => onButtonClick('about-me')}
                  >
                    sobre mim
                  </MenuMobileItemText>
                </MenuMobileItemButton>
              </MenuMobileListItem>
              
            </React.Fragment>
          ) : (
            links.map((link, index) => (
              <MenuMobileListItem key={keyGenerator(index)} disablePadding>
                <MenuMobileItemButton>
                  <MenuMobileItemText
                    className={active === link.text ? "active" : ""}
                    to={{
                      pathname: link.id,
                      hash: link.hash,
                    }}
                  >
                    {link.text}
                  </MenuMobileItemText>
                </MenuMobileItemButton>
              </MenuMobileListItem>
            ))
          )}
        </MenuMobileList>
      </MenuMobile>
    </AppBarWrapper>
  );
};
