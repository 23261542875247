import React from "react";
import AboutImg from "assets/images/sobre-mim.gif";
import ImgOrientadora from "assets/images/orientadora.jpeg";
import { AppBar, Footer } from "components";
import { AboutWrapper, Title, Text, TextWrapper, Image, ImageContainer, Link,Legend } from "./About.styles";

export const About: React.FC = () => {
  return (
    <React.Fragment>
      <AppBar active="sobre mim" />
      <AboutWrapper component="section">
        <TextWrapper>
          <Title variant="h1">Sobre mim</Title>

          <Text>
            Olá, sou Florência Gamileira Nascimento, enfermeira pela Universidade Estadual Vale do Acaraú (UVA), especialista em Educação Permanente: saúde e educação em uma perspectiva integradora pela Fundação Oswald Cruz (FIOCRUZ), mestranda do Programa de Pós-graduação em Cuidados Clínicos em Enfermagem e Saúde da Universidade Estadual do Ceará (UECE) e idealizadora do Informa Diabetes.
          </Text>
          <Text>
            Este site é destinado a você que tem diabetes e quer conviver melhor com sua doença, tomando as medidas e cuidados necessários. Você poderá consultá-lo sempre que tiver alguma dúvida ou quiser compartilhar alguma experiência acerca de sua convivência com o diabetes.
          </Text>
          <Text>
            O projeto também contou com a colaboração da Profa. Dra. Sherida Karanini Paz de Oliveira, que é enfermeira, educadora em diabetes e docente do programa de Pós-Graduação em Cuidados Clínicos em Enfermagem e Saúde da Universidade Estadual do Ceará (EUCE).
          </Text>
          <Text>
            Lattes: {' '}
            <Link href="http://lattes.cnpq.br/6883820810036825">http://lattes.cnpq.br/6883820810036825</Link>
          </Text>
          <Text>
            ORCID: {' '}
            <Link href="https://orcid.org/0000-0003-3902-8046">https://orcid.org/0000-0003-3902-8046</Link>
          </Text>
        </TextWrapper>
        <ImageContainer>
          <Image src={AboutImg} />
          <Legend><span className="bold">Florência Gamileira Nascimento </span></Legend>
          <Image src={ImgOrientadora} />
          <Legend><span className="bold">Dra. Sherida Karanini Paz de Oliveira </span></Legend>
        </ImageContainer>
      </AboutWrapper>
      <Footer page="about" />
    </React.Fragment>
  );
};
