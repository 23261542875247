import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export const ListPostsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "1rem",
  width: "97%",
  "@media (max-width: 780px)": {
    width: "90%",
    justifyContent: "space-between",
  },
});

export const LinkDesktop = styled(Link)({
  display: "grid",
  placeItems: "center",
  textDecoration: "none",
  fontFamily: "var(--font)",
  fontSize: "var(--button-size)",
  fontWeight: "var(--button-weight)",
  width: "180px",
  textAlign: "center",
  color: "var(--light-green-bg)",
  backgroundColor: "var(--light-gb)",
  border: "1px solid var(--border-green)",
  borderRadius: "var(--border-radius)",
  transition: "all 0.3s ease-in-out",
  padding: "0.5rem 0",
  "&.selected": {
    color: "var(--light-gb)",
    backgroundColor: "var(--light-green-bg)",
  },
  "&:hover": {
    fontWeight: "550",
  },
  "@media (max-width: 1300px)": {
    fontSize: "0.8rem",
    width: "150px",
  },
  "@media (max-width: 1100px)": {
    fontSize: "0.7rem",
    width: "120px",
  },
  "@media (max-width: 780px)": {
    display: "none",
  },
});

export const LinkMobile = styled(Link)({
  display: "none",
  "@media (max-width: 780px)": {
    display: "flex",
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
    "&.selected ": {
      "& path ": {
        fill: "var(--light-green-bg)",
      },
    },
    "@media (max-width: 780px)": {
      "& svg": {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
    "@media (max-width: 270px)": {
      "& svg": {
        width: "1.3rem",
        height: "1.3rem",
      },
    },
  },
});
