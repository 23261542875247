import { styled } from "@mui/system";
import "utils/variables.css";

export const ButtonWrapper = styled("button")({
  font: "var(--font)",
  color: "rgb(240, 240, 240)",
  border: "none",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  padding: "0.5rem 1rem",
  backgroundColor: "var(--light-gb)",
  borderRadius: "var(--border-radius)",
  fontSize: "var(--button-size)",
  fontWeight: "var(--button-weight)",

  "&.primary": {
    backgroundColor: "rgb(74, 168, 219)",
  },
  "&.secondary": {
    backgroundColor: "rgb(40, 38, 38)",
  },
  "&.main": {
    color: "var(--text-dark)",
  },
  "&.outline": {
    display: "grid",
    placeItems: "center",
    textDecoration: "none",
    color: "var(--text-light-dark)",
    border: "1px solid var(--text-light-dark)",
    transition: "all 0.3s ease-in-out",
    padding: "0.5rem 2rem",
    "&:hover": {
      backgroundColor: "rgb(240, 240, 240)",
    },
    "@media (max-width: 600px)": {
      width: "80%",
      padding: "0.5rem 0rem",
    },
  },
  "&.comment": {
    width: "100%",
    minHeight: "40px",
    backgroundColor: "var(--light-green-bg)",
    color: "var(--light-textcolor)",
    padding: "0.5rem 1rem",
  },
});
