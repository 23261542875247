import { AppBar, Footer, ListPosts, Text, Title, Modal, Forum, VejaTambem } from "components";
import React from "react";
import Imagem01 from "assets/images/posts/post06/imagem01.png";
import Image02 from "assets/images/posts/post06/image02.png";
import Image03 from "assets/images/posts/post06/image03.png";
import Image04 from "assets/images/posts/post06/image04.png";
import Image05 from "assets/images/posts/post06/image05.png";
import {
  Content,
  Image,
  PostWrapper,
  WrapperRow,
} from "./ResolverProblemas.styles";
import { Box } from "@mui/material";

export const ResolverProblemas: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={5} />
        <Content>
          <Title variant="h1">Resolver problemas</Title>
          <Text>
            O diabetes pode ocasionar algumas situações que lidam com problemas
            a serem enfrentados.
          </Text>
          <Text>
            Às vezes, por mais que se planeje, acontecem coisas inesperadas e é
            preciso resolver problemas e pensar em como evitar para que não
            ocorra de novo. Além disso, as necessidades podem mudar com o tempo
            e o período, exigindo ajustes porque as soluções anteriores não
            funcionam mais.
          </Text>
          <Text>É uma habilidade que pode ser desenvolvida em 3 etapas:</Text>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "1rem 0rem",
            }}
          >
            <Image onClick={handleOpenImg1} src={Imagem01} alt="Imagem 01" />
            <Modal open={openImg01} onClose={handleCloseImg1}>
              <Image className="modal" src={Imagem01} alt="Imagem 01" />
            </Modal>
          </Box>
          <Text>Vamos lá identificar algumas situações de risco e saber o que fazer em cada uma delas.</Text>
          <Text className="title" variant="h2">
            Hipoglicemia
          </Text>

          <WrapperRow className="hipoglicemia">
            <Box className="image-container">
              <Image onClick={handleOpenImg2} src={Image02} alt="Imagem 02" />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image className="modal" src={Image02} alt="Imagem 01" />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                A hipoglicemia é uma complicação muito comum no diabetes que
                acontece quando a glicose está abaixo de 70 mg/dl ou muito baixo
                de 54 mg/dl.
              </Text>
              <Text>
                Geralmente acontece quando o nível de glicose no sangue baixa
                demais e os sintomas são: taquicardia, tontura, suor, palidez,
                tremores, irritabilidade, palpitações no coração, fome.
              </Text>
              <Text>
                Se não for corrigida rapidamente podem aparecer: confusão
                mental, desmaio, convulsão.
              </Text>
              <Text>
                Acontece também quando ocorre superdosagens de insulina, ou
                seja, quando você toma uma quantidade de insulina a mais.
              </Text>
              <Text>
                A correção de hipoglicemia pode ser feita com 15 gramas de
                carboidratos simples que tem em 1 colher de sopa rasa de açúcar
                ou 1 sachê de mel ou um copo de 150 ml de suco de laranja ou
                ainda, três balas moles. A correção com líquidos é mais rápida.
                Após 15 minutos, deve-se medir novamente a glicemia para
                confirmar que a glicemia já está nos valores normais.
              </Text>
            </Box>
          </WrapperRow>
          <WrapperRow className="hipoglicemia-2">
            <Box className="image-container">
              <Image onClick={handleOpenImg3} src={Image03} alt="Imagem 03" />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image className="modal" src={Image03} alt="Imagem 01" />
              </Modal>
            </Box>

            <Box className="text-container">
              <Text>
                Alimentos como chocolates, sorvetes, biscoitos recheados,
                achocolatado, leite não devem ser usados para corrigir a
                hipoglicemia porque são alimentos com grande quantidade de
                gordura e lentifica a absorção do açúcar. Os refrigerantes podem
                ser usados, desde que não seja ZERO.
              </Text>
              <Text>
                Após a resolução desse problema, é importante entender o que
                ocasionou essa hipoglicemia para realizar os ajustes necessários
                e evitar novos episódios.
              </Text>
            </Box>
          </WrapperRow>

          <Text className="title" variant="h2">
            Hiperglicemia
          </Text>

          <WrapperRow className="hipoglicemia">
            <Box className="image-container">
              <Image onClick={handleOpenImg4} src={Image04} alt="Imagem 04" />
              <Modal open={openImg04} onClose={handleCloseImg4}>
                <Image className="modal" src={Image04} alt="Imagem 01" />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                A hiperglicemia é um fator crucial para o aparecimento de
                complicações micro e macrovasculares, especialmente se persistir
                por muito tempo. Logo, é importante que você realize o manejo do
                diabetes.
              </Text>
              <Text>
                Os valores de hiperglicemia são acima 180 mg/dl, sendo valores
                maiores que 250 mg/dl mais preocupantes e os sintomas que podem
                aparecer são: sede, boca seca, vontade de fazer xixi aumentada,
                fome, dor de cabeça, visão embaçada, náusea, vômito, respiração
                ofegante.
              </Text>
              <Text>
                Se você usa insulina, deve conhecer quanto que sua glicose
                diminui com uma unidade de insulina para fazer a correção. Se
                não souber, beba bastante água e procure um serviço de saúde
                rapidamente.
              </Text>
              <Text>
                Após a resolução desse problema, é importante entender o que
                ocasionou essa hiperglicemia para realizar os ajustes
                necessários e evitar novos episódios.
              </Text>
              <Text>Beber bastante água ajuda na redução da glicemia.</Text>
              <Text>
                Se isso acontecer com frequência, fale com sua equipe de saúde
                para avaliar o que pode ser ajustado no tratamento.
              </Text>
            </Box>
          </WrapperRow>

          <Text className="title" variant="h2">
            Cetoacidose Diabética
          </Text>

          <WrapperRow className="hipoglicemia-2">
            <Box className="image-container">
              <Image onClick={handleOpenImg5} src={Image05} alt="Imagem 04" />
              <Modal open={openImg05} onClose={handleCloseImg5}>
                <Image className="modal" src={Image05} alt="Imagem 01" />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Se a hiperglicemia não for tratada, pode evoluir para
                cetoacidose diabética. Apesar de estranho o nome, essa é uma
                condição que pode se apresentar em pessoas que tem o diabetes
                tipo 1 e tipo 2 e acontece quando, além da elevação da glicose
                no sangue, também há elevação de cetonas o que deixa o sangue
                ácido, causando uma acidose metabólica.
              </Text>
              <Text>
                Na presença dessa condição, a pessoa sente náuseas, vômitos, dor
                abdominal, desidratação, fraqueza, fadiga, coração acelerado,
                respiração rápida e irregular e a pessoa pode chegar ao coma,
                perdendo a consciência.
              </Text>
              <Text>
                O diagnóstico geralmente é confirmado por exames de sangue, que
                são realizados no hospital pela gravidade, chamado de
                gasometria. Caso se sinta assim, você deve procurar o hospital,
                o mais rápido possível.
              </Text>
            </Box>
          </WrapperRow>

          <Text className="title" variant="h2">
            REFERÊNCIAS
          </Text>
          <Text>
            American Diabetes Association. Disponível em:
            https://diabetes.org/healthy-living/recipes- nutrition. Acesso em 26
            set 2022.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
          <Text>
            SANTOMAURO, A.T; SANTOMAURO JUNIOR, A. G; RADUAN, R. A. Diagnóstico
            e Tratamento da Cetoacidose Diabética Euglicêmica in: Diretriz da
            Sociedade Brasileira de Diabetes. 20/01/2022 DOI:
            10.29327/557753.2022-22.
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Diretrizes da Sociedade Brasileira
            de Diabetes. SBD 2020-2021.
          </Text>
          <Text>
            https://lh3.googleusercontent.com/oMTFQEymVaJozP5ksRJlyL4vWjfhw6UnhIWKxNHAd4m1p5Zibb_7NpBPTUy2b82tNQLie7gPg4J-9lFL15mni-BicAerU6dbyosJtHT3
          </Text>
          <Text>
            https://lh3.googleusercontent.com/DWOeysx9avi3PDNFtDIhiq5SZVtMu5AU40yW1t7YWWx0YdSo53aEVluvT_J9j_5zmGeil4L3i1dLej6vmjcaoMp9RKw-Ci2HltwzMwdD5Q
          </Text>
          <Text>
            https://lh3.googleusercontent.com/DWOeysx9avi3PDNFtDIhiq5SZVtMu5AU40yW1t7YWWx0YdSo53aEVluvT_J9j_5zmGeil4L3i1dLej6vmjcaoMp9RKw-Ci2HltwzMwdD5Q
          </Text>
          <Text>
            https://lh3.googleusercontent.com/yjJI2Gs58shdeB1bz4aJqbaOJiOM7-shqGdjLNsP0d-Xv1kMIEkePrWh3F_G1-MnHJuDVokjPZ4YNYczp3IyrJ-9AuBY-6V5g-CFT4c
          </Text>
          <Text>
            https://lh3.googleusercontent.com/_07EubSH_HK9SdJ2szQNg7JQnlggS1rKkQrdjKTgtbhW1DTPD2_9j9B3x1trKEyEIKaOxlXhwy3KlMkv98SSy0ju4dpVHlPJmGDGQMStuw
          </Text>
        </Content>
        <VejaTambem post={5} />
        <Content className="forum">
          <Forum title="Se você estiver com sinais de que sua glicemia está baixa, o que você deve fazer?​" postNumber={7} />
        </Content>
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
