import React from "react";
import { api } from "api/api";
import { CommentType } from "utils/Interface";
import { Comment, SendComment, Snackbarr, Text } from "components";
import { CommentsWrapper, Container, Divider } from "./Forum.styles";
import { CircularProgress } from "@mui/material";

interface ForumProps {
  postNumber: number;
  title?: string;
}

export const Forum: React.FC<ForumProps> = ({ postNumber, title }) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [comments, setComments] = React.useState<CommentType[]>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

  const handleReload = (value: boolean) => {
    setReload(value);
  };
  const handleOpenSnackbar = (value: boolean) => {
    setOpenSnackbar(value);
  };
  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    if (reload) {
      handleOpenSnackbar(true);
    }
    setReload(false);
    const getComments = async () => {
      const response = await api.get(`comentarios/${postNumber}`);
      setComments(response.data.reverse());
      setLoaded(true);
    };
    getComments();
  }, [reload, postNumber]);
  return (
    <React.Fragment>
      <SendComment title={title} handleReload={handleReload} postNumber={postNumber} />
      <Divider></Divider>
      <CommentsWrapper>
      {loaded ? (
        comments.map((comment, index) => (
          <Comment key={index} comment={comment} handleReload={handleReload} />
        ))
      ) : (
        <Container className="loading">
          <CircularProgress />
          <Text>Carregando comentários...</Text>
        </Container>
      )}
      </CommentsWrapper>
      <Snackbarr
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message="Comentário enviado com sucesso!"
        color="success"
      />
    </React.Fragment>
  );
};
