import { styled } from "@mui/system";
import { Box } from "@mui/material";
import "utils/variables.css";

export const TextInHomeWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  "@media (max-width: 500px)": {
    padding: "1rem",
  },
});

export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  maxWidth: "70%",
  height: "100%",
  gap: "1rem",
  "& h1": {
    maxWidth: "70%",
    padding: "2rem 0px",
  },
  "&.text-center": {
    textAlign: "center",
  },
  "&.text-in-home-center": {
    maxWidth: "auto",
    margin: "20px",
  },
  "& .textleft": {
    textAlign: "left",
    alignItems: "flex-start",
    gap: "0",
  },
  "& .text": {
    textAlign: "justify",
  },
  "&.referencias": {
    maxWidth: "90%"
  },
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
    "& h1.title": {
      maxWidth: "90%",
      padding: "1rem 0px",
    },
  },
});

export const CardsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1rem",
  maxWidth: "90%",
  padding: "2rem 0px",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    minWidth: "80%",
  },
});

export const Image = styled("img")({
  width: "90%",
  maxHeight: "700px",
  maxWidth: "700px",
  objectFit: "contain",
  "&.image_perfil": {
    height: "auto",
    maxWidth: "280px",
  },
  "&.modal": {
    height: "100%",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

export const WrapperGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  maxWidth: "90%",
  padding: "2rem 0px",
  textAlign: "justify",
  gridAutoRows: "minmax(50px, auto)",
  rowGap: "1.5rem",
  justifyItems: "center",
  "& h1": {
    gridRow: "1/2",
    gridColumn: "1/2",
    textAlign: "left",
    "&.right-top": {
      gridColumn: "2/3",
    },
  },
  "& .image": {
    gridRow: "1/3",
    gridColumn: "2/3",
  },
  "& .image-possible": {
    gridRow: "1/3",
    gridColumn: "1/2",
  },
  "&.about": {
    gridTemplateColumns: "repeat(1, 1fr)",
    maxWidth: "100%",
    justifyItems: "center",
  },
  "&.left": {
    maxWidth: "100%",
    gridTemplateColumns: "3fr 1fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
      "& .image-container": {
        gridRow: "1/2",
        justifySelf: "center",
      },
    },
  },
  "&.right": {
    maxWidth: "100%",
    gridTemplateColumns: "1fr 3fr",
    gap: "1rem",
    "@media (max-width: 750px)": {
      gridTemplateColumns: "1fr",
    },
  },
  "&.left p and .right p": {
    textAlign: "justify",
    alignSelf: "center",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr",
    "& h1": {
      textAlign: "center",
      "&.right-top": {
        gridColumn: "1/2",
      },
    },
    "& .image": {
      display: "none",
    },
    "& .image-possible": {
      gridRow: "2/3",
      gridColumn: "1/2",
      width: "100%",
      alightSelf: "center",
      justifySelf: "center",
    },
    "& .text-container": {
      gridRow: "3/4",
      gridColumn: "1/2",
    },
  },
  "@media (max-width: 480px)": {
    "& .image": {
      display: "none",
    },
  },
});

export const ImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",
  height: "100%",
  minWidth: "250px",
  "@media (max-width: 360px)": {
    minWidth: "90%",
  },
});

export const DataWrapper = styled(Box)({
  display: "grid",
  placeItems: "center",
  background: "var(--dark-bg)",
  // gridTemplateColumns: "repeat(3, 1fr)",
  padding: "2rem 0.5rem 3rem 0.5rem",
  // alignItems: "center",
  // gap: "0rem",
  // justifyContent: "center",
  "@media(max-width: 680px)": {
    // gridTemplateColumns: "repeat(1, 1fr)",
    // gap: "5rem",
    padding: "2.5rem 0.5rem 3.5rem 0.5rem",
  },
});

export const DataContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "80px",
  textAlign: "center",
  "& h6": {
    lineHeight: "1rem",
  },
  "@media(max-width: 970px and min-width:680px)": {
    gap: "0.5rem",
    "& h1": {
      fontSize: "1.5rem",
    },
    "& h6": {
      fontSize: "0.75rem",
    },
  },
  "@media(max-width: 680px)": {
    "& h6": {
      fontSize: "1.3rem",
    },
  },
});

export const NoticesCardsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "2rem",
  maxWidth: "100%",
  padding: "2rem 5%",
  background: "var(--gray-bg)",
  "&.about-me": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "1px",
    padding: "2rem 10rem",
    "@media (max-width: 1500px)": {
      padding: "2rem 5rem",
    },
    "@media (max-width: 1200px)": {
      padding: "2rem 2rem",
    },
  },
  "& .title-pub": {
    justifySelf: "center",
    gridColumn: "1/4",
    padding: "2rem 0px",
    width: "60%",
  },
  "& .button-center": {
    justifySelf: "center",
    gridColumn: "1/4",
  },
  "@media (max-width: 880px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    "& .title-pub": {
      gridColumn: "1/3",
      width: "100%",
    },
    "& .button-center": {
      justifySelf: "center",
      gridColumn: "1/3",
    },
  },
  "@media (max-width: 550px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    "& .title-pub": {
      gridColumn: "1/2",
    },
    "& .button-center": {
      gridColumn: "1/2",
    },
  },
});

export const List = styled("ul")({
  listStyle: "disc",
  paddingLeft: "1rem",
});
export const ListItem = styled("li")({
  fontFamily: "var(--font)",
  color: "var(--text-dark)",
  fontSize: "var(--text-size)",
  fontWeight: "var(--text-weight)",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  padding: "1rem",
  "&.bold": { fontWeigh: "bold" },
  "&.title": {
    fontWeight: "var(--title-weight)",
  },
  "&.title-left": {
    fontWeight: "var(--title-weight)",
    textAlign: "left",
  },
  "&.white": {
    color: "var(--light-textcolor)",
  },
  "&.center": {
    textAlign: "center",
  },
  "&.center-justify-grid": {
    textAlign: "justify",
    alignSelf: "center",
  },
  "&.justify": {
    textAlign: "justify",
  },
  "@media (min-width: 2000px)": {
    fontSize: "3.5rem",
  },
  "@media (min-width: 1600px)": {
    fontSize: "2.5rem",
  },
  "@media (min-width: 1500px)": {
    fontSize: "2rem",
  },
  "@media (min-width: 1400px)": {
    fontSize: "1.7rem",
  },
  "@media (min-width: 1300px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width: 630px)": {
    fontSize: "1.3rem",
  },
  "@media (max-width: 500px)": {
    textAlign: "center",
  },
});

export const FlexRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  "& .text-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    gap: "1rem",
  },
  "& .image": {
    height: "70%",
  },
  "@media (max-width: 790px)": {
    flexDirection: "column",
    "& .image": {
      display: "none",
    },
  }
});
