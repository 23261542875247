import React from "react";
import Image01 from "assets/images/posts/post05/image01.png";
import Image02 from "assets/images/posts/post05/image02.png";
import Image03 from "assets/images/posts/post05/image03.png";
import Image04 from "assets/images/posts/post05/image04.png";
import Image05 from "assets/images/posts/post05/image05.png";
import Image06 from "assets/images/posts/post05/image06.png";
import Image07 from "assets/images/posts/post05/image07.png";
import Image08 from "assets/images/posts/post05/image08.png";
import Image09 from "assets/images/posts/post05/image09.png";
import { AppBar, Footer, Forum, ListPosts, Modal, PostLinkInPage, Text, Title, VejaTambem } from "components";
import { Content, Image, PostWrapper, TextWrapper, WrapperRow, WrapperTable } from "./ReduzaRiscos.style";
import { Box } from "@mui/material";

export const ReduzaRiscos: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  const [openImg05, setOpenImg05] = React.useState(false);
  const [openImg06, setOpenImg06] = React.useState(false);
  const [openImg07, setOpenImg07] = React.useState(false);
  const [openImg08, setOpenImg08] = React.useState(false);
  const [openImg09, setOpenImg09] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  const handleOpenImg5 = () => setOpenImg05(true);
  const handleOpenImg6 = () => setOpenImg06(true);
  const handleOpenImg7 = () => setOpenImg07(true);
  const handleOpenImg8 = () => setOpenImg08(true);
  const handleOpenImg9 = () => setOpenImg09(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  const handleCloseImg5 = () => setOpenImg05(false);
  const handleCloseImg6 = () => setOpenImg06(false);
  const handleCloseImg7 = () => setOpenImg07(false);
  const handleCloseImg8 = () => setOpenImg08(false);
  const handleCloseImg9 = () => setOpenImg09(false);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={4} />
        <Content>
          <Title variant="h1">Reduzir riscos</Title>
          <Text>
            Aqui vamos falar sobre alguns pontos importantes para prevenir
            possíveis complicações do diabetes e que são cuidados que você deve ter
            diariamente e outros, anualmente ou conforme orientação da equipe de saúde.
          </Text>

          <Text className="title">
            1. Fique atento aos pés
          </Text>
          <WrapperTable>
            <Box className="item">
              <Image onClick={handleOpenImg1} src={Image01} alt="Imagem 01" />
              <Modal open={openImg01} onClose={handleCloseImg1}>
                <Image className="modal" src={Image01} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                Como você já sabe, os pés precisam de uma atenção especial para
                evitar alterações e ferimentos que possam ser complicados. Para isso, é
                preciso realizar alguns cuidados diários.
              </Text>
            </Box>
            <Box className="item">
              <Image onClick={handleOpenImg2} src={Image02} alt="Imagem 01" />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image className="modal" src={Image02} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                Observe seus pés diariamente, abra entre os dedos e observe presença de
                umidade, descamação, cortes entre os dedos, bolhas, rachaduras, arranhões,
                calos, inchaço, vermelhidão ou aumento de temperatura nos pés ou pernas .
              </Text>
            </Box>
            <Box className="item">
              <Image onClick={handleOpenImg3} src={Image03} alt="Imagem 01" />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image className="modal" src={Image02} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                Durante o banho, lave bem os pés e os enxugue bem, incluindo os dedos e o
                espaço entre os dedos.
              </Text>
            </Box>
            <Box className="item">
              <Image onClick={handleOpenImg4} src={Image04} alt="Imagem 01" />
              <Modal open={openImg04} onClose={handleCloseImg4}>
                <Image className="modal" src={Image04} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                Hidrate os seus pés com um creme para a pele, todos os dias. Não passe entre
                os dedos, pois pode ficar úmido e favorecer o aparecimento de fungos
                (micose).
              </Text>
            </Box>
            <Box className="item">
              <Image onClick={handleOpenImg5} src={Image05} alt="Imagem 01" />
              <Modal open={openImg05} onClose={handleCloseImg5}>
                <Image className="modal" src={Image05} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                Fique atento para as unhas. O corte das unhas deve ser no formato anatômico,
                levemente quadrado, sem remover os cantos ou cutículas.
              </Text>
            </Box>
            <Box className="item">
              <Image onClick={handleOpenImg6} src={Image06} alt="Imagem 01" />
              <Modal open={openImg06} onClose={handleCloseImg6}>
                <Image className="modal" src={Image06} alt="Imagem 01" />
              </Modal>
              <Text className="center">
                {/* Evite calçados de plástico, o solado deve ser semi–rígido, não pode ser mole capaz de dobrar ao meio.
                Se tiver uma plataforma ou sola de borracha será melhor para não escorregar e não permitir que objetos
                pontiagudos perfurem o solado e atinjam seus pés. */}
                 {/*Evite andar descalço(a) e antes de calçar os calçados, olhe dentro deles para tirar qualquer objeto que pode te perfurar. 
                Use preferencialmente calçados fechados do tipo tênis e com meias. Os calçados devem ser ajustados aos seus pés, nem largos e 
                nem apertados; podem ser fechados com velcro ou cadarço para acomodar os pés inchados; o bico deve ser quadrado ou levemente arredondado 
                de forma que todos os dedos no interior do calçado consigam se mexer. */}
                Evite andar descalço(a). Os calçados devem ser ajustados aos pés; podem ser fechados com velcro ou cadarço para acomodar os pés inchados
                e o bico deve ser quadrado ou levemente arredondado de forma que todos os dedos consigam se mexer.
              </Text>
            </Box>
          </WrapperTable>

          <Text className="title">
            2. Cuide de seus olhos
          </Text>
          <WrapperRow >
            <Box className="image-container">
              <Image onClick={handleOpenImg7} src={Image07} alt="Imagem 01" />
              <Modal open={openImg07} onClose={handleCloseImg7}>
                <Image className="modal" src={Image07} alt="Imagem 01" />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                É importante realizar consulta com o médico oftalmologista, pelo menos
                uma vez ao ano ou conforme a indicação do médico, que fará exames, como o
                Exame do Fundo de olho e o Mapeamento de retina para evitar complicações
                nos olhos.
              </Text>
              <Text>
                A retinopatia diabética é uma complicação microvascular do diabetes que
                afeta os olhos e a capacidade da visão. No começo, a retinopatia não tem
                sintomas que podem demorar um pouco para aparecer e às vezes pode ser
                tarde demais. Por isso, a avaliação dos olhos periodicamente é importante.
              </Text>
            </Box>
          </WrapperRow>

          <Text className="title">
            3. Vacinação
          </Text>

          <WrapperRow className="reverse" >
            <Box className="image-container">
              <Image onClick={handleOpenImg8} src={Image08} alt="Imagem 01" />
              <Modal open={openImg08} onClose={handleCloseImg8}>
                <Image className="modal" src={Image08} alt="Imagem 01" />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Existem vacinas especialmente recomendadas para as pessoas com
                diabetes, pois a hiperglicemia pode afetar o sistema imunológico, justificando o
                risco aumentado para infecções nas pessoas com diabetes.
              </Text>
              <Text>
                As infecções em pessoas com DM costumam ser semelhantes à da
                população em geral e devem ser tratadas de modo semelhante, mas muitas
                vezes, apresentam maior gravidade e mortalidade.
              </Text>
            </Box>
          </WrapperRow>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Image onClick={handleOpenImg9} className="full" src={Image09} alt="Imagem 01" />
            <Modal open={openImg09} onClose={handleCloseImg9}>
              <Image className="modal" src={Image09} alt="Imagem 01" />
            </Modal>
          </Box>
          <Text className="font">Fonte: Calendários de vacinação SBIm pacientes especiais – 2022-2023 SBIm</Text>
          <Text>
            Vale lembrar que as vacinas contra influenza devem ser aplicadas todos
            os anos, pois a cada ano há um tipo de vacina diferente para os vírus da
            influenza.
          </Text>
          <Text>
            Vacinas contra COVID estão disponíveis nos serviços de saúde. As
            pessoas com diabetes devem receber todas as doses e todos os reforços
            disponíveis, pois são consideradas grupo de risco e devem ser vacinadas antes
            mesmo da faixa etária a que pertencem.
          </Text>

          <Text className="title">
            REFERÊNCIAS
          </Text>
          <Text>
            MALERBI, F.; ANDRADE, R., MORALES, P. et al. Manejo da retinopatia diabética. Diretriz da
            Sociedade Brasileira de Diabetes. Última revisão em: 21/12/2021 DOI: 10.29327/557753.2022-
            17.
          </Text>
          <Text>
            OLIVEIRA, S. K. P. Cuidados com os pés: o que o enfermeiro deve orientar e a pessoa com
            diabetes precisa saber? Disponível em: Cuidados com os pés: o que o enfermeiro deve orientar
            e a pessoa com diabetes precisa saber? - Sociedade Brasileira de Diabetes. Acesso em 01 dez
            2020.
          </Text>
          <Text>
            ROLIM,L. C.; THYSSEN, P. J.; FLUMIGNAN, R.L.G et al. Diagnóstico e tratamento da
            neuropatia periférica diabética. Diretriz da Sociedade Brasileira de Diabetes. Ultima revisão
            em: 03/03/2022 DOI: 10.29327/557753.2022-14
          </Text>
          <Text>
            Sociedade Brasileira de Imunização. Calendários de vacinação SBIm pacientes especiais –
            2022-2023 SBIm – Sociedade Brasileira de Imunizações sbim.org.br/calendarios-de-vacinacao.
            Disponível em: https://sbim.org.br/images/calendarios/calend-sbim-pacientes-especiais.pdf.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of diabetes care
            and education: revising the AADE7 Self-Care Behaviors®. The Diabetes Educator, v. 46, n. 2,
            p. 139-160, 2020.
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Diretrizes da Sociedade Brasileira de Diabetes. SBD
            2020-2021.
          </Text>
          <Text>
            https://lh3.googleusercontent.com/vYvoruUS1FhV7IvyoWoip4I2OQzQRt2KYXNClUHmdQ6mZJkWNudWgCWP6mQGC1cGosr_A2GSThAGBXrL24xUf8f4gzZB5cBFWKTE2UoM
          </Text>
          <Text>
            https://lh3.googleusercontent.com/EBiMzecoheczSRiIVch63KfUOemb4RI_kuMYdwBVI3xJNhzQr0SJEXbZ7He-06UdlZyJ4NEVb1gV4i89ytM-A4w7fF8uA7EUds-xD8ZWBw
          </Text>
          <Text>
           https://lh3.googleusercontent.com/lJvbt7LZq3UzdiPkTqY3HvbtC4BaNTq5XDe79qG1ekjwLWoVoo9BYG5wVZ2GNRq8-mTpEkZu_cJ9X6UcyoPb7pY2j7e10GMXER5a0Jvn
          </Text>
          <Text>
            https://lh3.googleusercontent.com/dUDCmkchEdr9JC6B3CholYN19HEq8Dn5xR9dCsK7egI-kyASqxxNW_I_3wi7w4ZZ26Z6m-1y_HUd7OJRX9Cfi-Ojvmft8N3hh9y-qcmewg
          </Text>
          <Text>
            https://lh3.googleusercontent.com/CNTpiAp72QZY5IXoZhTrxYVQIxhXb7MVEAhv53JgacC4aZSJxZZj4Xj3WS46MR94IM82TA50ci_ycP7Jn0sqRwD1accZJ10gnG93kK0
          </Text>
        </Content>

        <VejaTambem post={4} />
        <Content className="forum">
          <Forum title="Qual foi a última vez que você realizou algum desses exames? Está com a vacina atualizada?​" postNumber={6} />
        </Content>
      </PostWrapper>

      <Footer page="publications" />
    </React.Fragment>
  );
};
