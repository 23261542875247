import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const PostWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  height: "100%",
  backgroundColor: "var(--gray-bg)",
  padding: "2rem 0rem",
});

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "2rem",
  width: "calc(90% - 6rem)",
  backgroundColor: "var(--light-gb)",
  padding: "3rem",
  "&.forum": {
    alignItems: "center",
  },
  "@media (max-width: 780px)": {
    width: "calc(90% - 4rem)",
    padding: "2rem",
  },
  "@media (max-width: 550px)": {
    width: "calc(100% - 4rem)",
    padding: "2rem",
    borderRadius: "30px 30px 0px 0px",
  },
})

export const WrapperGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  maxWidth: "90%",
  padding: "2rem 0px",
  textAlign: "justify",
  gridAutoRows: "minmax(50px, auto)",
  rowGap: "1.5rem",
  "&.content-container": {
    gridTemplateColumns: "1fr 3fr",
  },
  "& h1": {
    gridRow: "1/2",
    gridColumn: "1/2",
    textAlign: "left",
  },
  "& .text-container-left": {
    gridRow: "2/3",
    gridColumn: "1/2",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "&.text-container-right": {
    gridRow: "1/3",
    gridColumn: "2/3",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "60% 40%",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr",
    "& h1": {
      textAlign: "center",
    },
    "& .image": {
      gridRow: "2/3",
      gridColumn: "1/2",
      width: "40%",
      alightSelf: "center",
      justifySelf: "center",
    },
    "& .text-container": {
      gridRow: "3/4",
      gridColumn: "1/2",
    },
  },
  "@media (max-width: 480px)": {
    "& .image": {
      display: "none",
    },
    "& .text-container": {
      gridRow: "2/3",
      gridColumn: "1/2",
    },
  }
});

export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  maxWidth: "70%",
  height: "100%",
  gap: "1rem",
  "& h1": {
    maxWidth: "70%",
    padding: "2rem 0px",
  },
  "&.text-center": {
    maxWidth: "100%",
    height: "90%",

  },
  "&.text-in-home-center": {
    maxWidth: "auto",
    margin: "20px",
  },
  "& .text-left": {
    textAlign: "left",
    alighSelf: "flex-start",
  },
  "& .text": {
    textAlign: "justify",
  },
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
    "& h1.title": {
      maxWidth: "90%",
      padding: "1rem 0px",
    },
  },
});

export const Image = styled("img")({
  width: "100%",
  maxHeight: "800px",
  maxWidth: "800px",
  cursor: "pointer",
  "&.modal": {
    height: "100%",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
});

export const WrapperRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  "&.hipoglicemia": {
    gap: "2rem",

    "& .text-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "1rem",
      width: "60%"
    },
    "& .image-container": {
      width: "40%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
    "@media (max-width: 980px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
      "& .text-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "1rem",
        width: "100%"
      },
      "& .image-container": {
        width: "80%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      },
    },
    "@media (max-width: 550px)": {
      "& .image-container": {
        width: "100%",
      },
    },
  },
  "&.hipoglicemia-2": {
    gap: "2rem",
    flexDirection: "row-reverse",
    "& .text-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "1rem",
      width: "60%"
    },
    "& .image-container": {
      width: "35%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // gap: "1rem",
    },
    "@media (max-width: 980px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
      "& .text-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "1rem",
        width: "100%"
      },
      "& .image-container": {
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      },
    },
    "@media (max-width: 550px)": {
      "& .image-container": {
        width: "100%",
      },
    },
  },
})