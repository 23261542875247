import React from "react";
import {
  FooterWrapper,
  InfosContainer,
  Text,
} from "./Footer.styles";
// import LogoImage from "assets/icons/logo01.png";
// import { PagesRoutes } from "features/constants";

interface FooterProps {
  page: "home" | "about" | "references" | "publications";
}

export const Footer: React.FC<FooterProps> = ({ page }) => {
  return (
    <FooterWrapper id="footer" component="footer">
 
      <InfosContainer>
        <Text className="author">Florência Gamileira Nascimento</Text>
        <Text>Enfermeira pela Universidade Estadual Vale do Acaraú</Text>
        <Text>Mestranda do PPCCLIS- UECE</Text>
        <Text className="copywriter">Todos os direitos reservados.</Text>
      </InfosContainer>
    </FooterWrapper>
  );
};
