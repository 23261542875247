import React from "react";
import { ButtonWrapper } from "./Button.styles";

interface ButtonProps {
  children: React.ReactNode;
  bgColor?: "primary" | "secondary" | "main" | "white" | "green" | "outline" | "comment";
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}
export const Button: React.FC<ButtonProps> = ({
  children,
  bgColor,
  onClick,
  className,
  disabled,
}) => {
  return (
    <React.Fragment>
      <ButtonWrapper
        className={`${className} ${bgColor}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </ButtonWrapper>
    </React.Fragment>
  );
};
