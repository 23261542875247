import React from "react";
import { Box, Modal as ModalContainer } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  height: "75vh",
  bgcolor: "#FFFFFF",
  border: "none",
  boxShadow: 10,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& .close": {
    color: "#5a5a5a",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    position: "absolute" as "absolute",
    top: "0",
    right: "0",
    padding: "12px 16px 12px 16px",
    "&:hover": {
        color: "#000",
        textDecoration: "none",
        cursor: "pointer",
    },
  }
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <React.Fragment>
          <span className="close" onClick={onClose}>
            &times;
          </span>
          {children}
        </React.Fragment>
      </Box>
    </ModalContainer>
  );
};
