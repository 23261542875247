import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const NoticesWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  "@media (max-width: 500px)": {
    padding: "0rem",
  },
});

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  maxWidth: "80%",
  "@media (max-width: 500px)": {
    maxWidth: "100%",
  },
});
