import React from "react";
import Image01 from "assets/images/posts/post04/imagem01.png";
import Image02 from "assets/images/posts/post04/imagem02.png";

import {
  Button,
  AppBar,
  Footer,
  ListPosts,
  Text,
  Title,
  NoticeCard,
  Modal,
  VejaTambem,
  Forum,
} from "components";
import {
  PostWrapper,
  TextWrapper,
  Content,
  WrapperGrid,
  NoticesCardsWrapper,
  Image,
  List,
} from "./Monitoramento.styles";
import { PagesRoutes } from "features/constants";
import { Link } from "react-router-dom";
import { NoticesList } from "utils/NoticesList";
import { keyGenerator } from "utils/uniqueKey";
import { ListItem } from "features/Home/Home.styles";
export const Monitoramento: React.FC = () => {
  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const refHome = React.useRef<HTMLDivElement | null>(null);
  const [maxPosts, setMaxPosts] = React.useState(3);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);

  const onLoadMorePostsClick = () => {
    setMaxPosts(6);
  };

  React.useEffect(() => {
    if (refHome.current) {
      if (window.location.hash === "#publicacoes") {
        refHome.current
          .getElementsByClassName("publicacoes")[0]
          .scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={3} />
        <Content>
          <Title>Vigiar as taxas </Title>
          <Text>
            <span className="bold">
              É preciso aprender a verificar, registrar e compreender os
              níveis de glicose no sangue e outros números importantes, como
              pressão arterial, para o autocuidado do diabetes
            </span>
            , começando a cumprir os cuidados necessários para viver bem!
          </Text>
          <Image onClick={handleOpenImg1} src={Image01} alt="Imagem 01" />
          <Modal open={openImg01} onClose={handleCloseImg1}>
            <Image className="modal" src={Image01} alt="Imagem 01" />
          </Modal>

          <Text className="title">Monitorar a glicose</Text>
          <Text>
            Checar a glicemia regularmente oferece informações importantes para
            o manejo do diabetes, ajuda fazer escolhas para comer e ser ativo e
            antecipa ações. É importante verificar a glicemia capilar, a famosa
            ponta de dedo, especialmente: em jejum, antes das refeições e 2
            horas após as refeições.
          </Text>
          <Text>
            A punção deve ser feita com lancetas na lateral de algum dos dedos
            da mão, para que venha uma quantidade de sangue adequada.
          </Text>
          <WrapperGrid>
            <Image onClick={handleOpenImg2} src={Image02} alt="Imagem 02" />
            <Modal open={openImg02} onClose={handleCloseImg2}>
              <Image className="modal" src={Image02} alt="Imagem 02" />
            </Modal>
            <TextWrapper>
              <Text>Os valores normais considerados metas são:</Text>
              <Text>
                ​​→ Glicemia em jejum e pré-prandial (antes das refeições): 80 -
                130 mg/dl​{" "}
              </Text>
              <Text>
                ​​→ Glicemia 2 horas após as refeições: menor que 180 mg/dl​{" "}
              </Text>
              <Text>
                É preciso que você tenha em casa seu próprio glicosímetro para
                verificar a glicose continuamente, principalmente se não estiver
                se sentindo bem, pois pode estar alterada para mais ou para
                menos.
              </Text>
              <List>
                <ListItem>
                  Se estiver menor do que 70mg/dl a glicose está baixa
                </ListItem>
                <ListItem>
                  Se estiver acima de 180 mg/dl a glicose está alta
                </ListItem>
              </List>
              <Text className="title">
                Saiba o que fazer quando a glicemia está alterada aqui 🡪{" "}
                <a href="/posts/resolver-problemas">clicando aqui</a>
              </Text>
            </TextWrapper>
          </WrapperGrid>
          <Text className="title">
            {" "}
            Outras taxas que você precisa vigiar são:{" "}
          </Text>

          <Text className="title"> Pressão arterial</Text>
          <Text>
            Aproveite para verificar a pressão arterial semanalmente, assim fica
            mais fácil verificar como está seu coração e evitar outras
            complicações como Acidente Vascular Cerebral, hipertensão e infarto.
          </Text>
          <Text className="title"> Colesterol</Text>
          <Text>
            É preciso manter controle sobre o colesterol, pois a gordura
            presente no sangue é fator de risco para complicações. Faça exames
            regulares para avaliar os níveis de colesterol!
          </Text>
          <Text className="title"> Função renal</Text>
          <Text>
            O rim pode ser avaliado por meios dos exames de sangue que verificam
            seu funcionamento: ureia, creatinina, albumina. Mas você pode
            observar alterações no xixi como a presença de espuma ou a cor mais
            escurecida. Caso isso aconteça, converse com seu médico, pois uma
            das complicações do diabetes é a nefropatia diabética que é uma
            alteração nos rins. Esse exame deve ser feito anualmente ou sempre
            que necessário.
          </Text>
          <Text className="title"> Peso</Text>
          <Text>Controle seu peso avaliando-o mensalmente.</Text>
          <br></br>
          <br></br>
          <Text className="title"> REFERÊNCIAS</Text>
          <Text>
            Pititto B, Dias M, Moura F, Lamounier R, Calliari S, Bertoluci M.
            Metas no tratamento do diabetes. Diretriz Oficial da Sociedade
            Brasileira de Diabetes (2022). DOI: 10.29327/557753.2022-3, ISBN:
            978-65-5941-622-6.
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Diretrizes da Sociedade Brasileira
            de Diabetes. SBD 2020-2021.
          </Text>
          <Text>
            SÁ, J. R.; CANANI, L. H.; RANGEL, E. B., et al. Doença renal do
            diabetes. Diretriz da Sociedade Brasileira de Diabetes. Última
            revisão em: 28/12/2021 DOI: 10.29327/557753.2022-18.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
        </Content>
        <VejaTambem post={3} />

        <Content>
          <Forum
            title="​E aí, já passou por alguma situação difícil e que gostaria de
            compartilhar ou esclarecer suas dúvidas?​"
            postNumber={5}
          />
        </Content>
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
