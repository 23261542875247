import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const PostWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  height: "100%",
  backgroundColor: "var(--gray-bg)",
  padding: "2rem 0rem",
  "&.postwrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    height: "100%",
    backgroundColor: "var(--light-gb)",
    padding: "2rem 0rem",
  },
});

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "2rem",
  width: "calc(90% - 6rem)",
  backgroundColor: "var(--light-gb)",
  padding: "3rem",
  "&.forum": {
    alignItems: "center",
  },
  "@media (max-width: 780px)": {
    width: "calc(90% - 4rem)",
    padding: "2rem",
  },
  "@media (max-width: 550px)": {
    width: "calc(100% - 4rem)",
    padding: "2rem",
    borderRadius: "30px 30px 0px 0px",
  },
});

export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  maxWidth: "70%",
  height: "100%",
  gap: "1rem",
  "& .bold": {
    fontWeight: "bold",
  },
  "& h1": {
    maxWidth: "70%",
    padding: "2rem 0px",
  },
  "&.text-center": {
    maxWidth: "100%",
    height: "90%",
  },
  "&.text-in-home-center": {
    maxWidth: "auto",
    margin: "20px",
  },
  "& .text-left": {
    textAlign: "left",
    alighSelf: "flex-start",
  },
  "& .text": {
    textAlign: "justify",
  },
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
    "& h1.title": {
      maxWidth: "90%",
      padding: "1rem 0px",
    },
  },
});

export const Image = styled("img")({
  width: "100%",
  maxHeight: "800px",
  maxWidth: "800px",
  objectFit: "contain",
  "&.center_image": {
    alignItems: "center",
  },
  "&.small_size": {
    width: "100%",
    maxHeight: "400px",
    maxWidth: "400px",
    "@media (max-width: 780px)": {
      maxWidth: "100%",
    },
  },
  "&.medium_size": {
    width: "90%",
    maxHeight: "800px",
    maxWidth: "80%",
    "@media (max-width: 780px)": {
      maxWidth: "100%",
    },
  },
  "&.full": {
    width: "100%",
    maxHeight: "800px",
    maxWidth: "80%",
    "@media (max-width: 780px)": {
      maxWidth: "100%",
    },
  },
});

export const WrapperImage = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 2fr 2fr 2fr",
  gap: "1rem",
  width: "100%",
  height: "100%",
});

export const WrapperRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "2rem",

  "&.reverse": {
    flexDirection: "row-reverse",
    "@media (max-width: 1050px)": {
      flexDirection: "column",
    },
  },

  "& .text-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "1rem",
    width: "60%",
  },
  "&.image-container": {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  "@media (max-width: 1050px)": {
    flexDirection: "column",
    alignItems: "center",
    "& .text-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "1rem",
      width: "100%",
    },
    "& .image-container": {
      width: "80%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
  },
  "@media (max-width: 550px)": {
    "& .image-container": {
      width: "100%",
    },
  },
});

export const WrapperTable = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "1rem",
  width: "100%",
  height: "100%",
  "& .table-item": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    height: "100%",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 650px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});
export const List = styled("ul")({
  listStyle: "disc",
  // paddingLeft: "1rem",
});
export const ListItem = styled("li")({
  fontFamily: "var(--font)",
  color: "var(--text-dark)",
  fontSize: "var(--text-size)",
  fontWeight: "var(--text-weight)",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  padding: "0.5rem",
  lineHeight: "2rem",
  "&.bold": { fontWeigh: "bold" },
  "&.title": {
    fontWeight: "var(--title-weight)",
  },
  "&.title-left": {
    fontWeight: "var(--title-weight)",
    textAlign: "left",
  },
  "&.white": {
    color: "var(--light-textcolor)",
  },
  "&.center": {
    textAlign: "center",
  },
  "&.center-justify-grid": {
    textAlign: "justify",
    alignSelf: "center",
  },
  "&.justify": {
    textAlign: "justify",
  },
  "@media (min-width: 2000px)": {
    fontSize: "3.5rem",
  },
  "@media (min-width: 1600px)": {
    fontSize: "2.5rem",
  },
  "@media (min-width: 1500px)": {
    fontSize: "2rem",
  },
  "@media (min-width: 1400px)": {
    fontSize: "1.7rem",
  },
  "@media (min-width: 1300px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width: 630px)": {
    fontSize: "1.3rem",
  },
  "@media (max-width: 500px)": {
    textAlign: "center",
  },
});

export const Table = styled("table")({
  width: "100%",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#605E5E",
  borderCollapse: "collapse",
  "@media (max-width: 740px)": {
    display: "none",
  },
  "@media (max-width: 300px)": {
    width: "90%",
  },
  "& th": {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#605E5E",
    padding: "0.5rem",
    textAlign: "center",
    fontFamily: "Avenir, sans-serif",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#605E5E",
    "@media (max-width: 820px)": {
      textAlign: "center",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width: 320px)": {
      fontSize: "0.8rem",
    },
  },
  "& td": {
    borderStyle: "none solid",
    borderWidth: "1px",
    borderColor: "#605E5E",
    // padding: "0.5rem",
    fontFamily: "Avenir, sans-serif",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "#605E5E",
    "& ul": {
      listStyle: "none",
    },
    "@media (max-width: 820px)": {
      textAlign: "center",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.9rem",
      padding: "0.3rem",
    },
    "@media (max-width: 320px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width: 295px)": {
      fontSize: "0.7rem",
    },
  },
});

export const TableMobileGrid = styled(Box)({
  display: "none",
  "@media (max-width: 740px)": {
    display: "grid",
    gridTemplateColumns: "1fr",
    border: "1px solid #605E5E",
    "& .title": {
      textAlign: "center",
    },
    "& .main": {
      borderTop: "1px solid #605E5E",
      borderBottom: "1px solid #605E5E",
      "& ul": {
        listStyle: "none",
      },
    },
  },
});

