import { AppBar, Footer, ListPosts, Text, Title, VejaTambem } from "components";
import React from "react";
import {Content, PostWrapper } from "./Glossario.styles";

export const Glossario: React.FC = () => {
  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
      <ListPosts selected={8} />
        <Content>
          
          <Title variant="h1">Dicionário Diabético</Title>

          <Text>
            Existe algum termo que você não conhece ou não sabe o que significa?
            Não se preocupe, aqui você pode encontrar algumas informações sobre
            alguns termos técnicos.
          </Text>

          <Text>
            <span className="bold">Diabetes - </span>
            condição crônica caracterizada pelo aumento de glicose na corrente
            sanguínea.
          </Text>
          <Text>
            <span className="bold">Glicose - </span>
            açúcar.
          </Text>
          <Text>
            <span className="bold">Glicemia - </span>
            nível de açúcar na corrente sanguínea.
          </Text>
          <Text>
            <span className="bold">Glicosímetro - </span>
            aparelho que mede a glicose no sangue.
          </Text>
          <Text>
            <span className="bold">Hiperglicemia - </span>
            aumento no nível da glicose sanguínea.
          </Text>
          <Text>
            <span className="bold">Hipoglicemia - </span>
            diminuição do nível de glicose sanguínea.
          </Text>
          <Text>
            <span className="bold">Pico de hiperglicemia - </span>
            situação que ocorre aumento progressivo da glicose sanguínea e pode
            apresenta alterações como visão borrada, suor excessivo, sede
            excessiva e desmaio.
          </Text>
          <Text>
            <span className="bold">Insulina - </span>
            hormônio responsável por colocar a glicose dentro das células do
            nosso corpo.
          </Text>
          <Text>
            <span className="bold">Vertigem - </span>
            sensação de mal-estar e desmaio.
          </Text>
          <Text>
            <span className="bold">Espasmos - </span>
            tremores.
          </Text>
          <Text>
            <span className="bold">Albuminúria - </span>
            presença de albumina na urina.
          </Text>
          <Text>
            <span className="bold">Hipertensão arterial - </span>
            condição crônica caracterizada pelo aumento do nível da pressão
            arterial.
          </Text>
          <Text>
            <span className="bold">Glicemia de jejum - </span>
            exame de sangue realizado para verificação os níveis de glicose e a
            presença ou não de diabetes. Este exame é realizado coma pessoa em
            jejum de pelo menos 8 horas.
          </Text>
          <Text>
            <span className="bold">Nefropatia - </span>
            complicação do diabetes que afeta os rins e sua função.
          </Text>
          <Text>
            <span className="bold">Retinopatia - </span>
            complicação do diabetes que afeta os olhos e a capacidade da visão.
          </Text>
          <Text>
            <span className="bold">Neuropatia - </span>
            complicação do diabetes que afeta os nervos em especial dos pés.
          </Text>
          <Text>
            <span className="bold">Cetoacidose - </span>
            complicação que causa o desequilíbrio hidroeletrolítico e
            acidometabólico e que causam desordenamento nas funções do
            organismo.
          </Text>
          <Text>
            <span className="bold">Polidipsia - </span>
            sede excessiva.
          </Text>
          <Text>
            <span className="bold">Poliúria - </span>
            vontade de urinar com frequência.
          </Text>
          <Text>
            <span className="bold">Polifagia - </span>
            aumento excessivo da vontade de comer ou fome excessiva.
          </Text>

      </Content>
      <VejaTambem post={8} />
    </PostWrapper>
  <Footer page="publications" />
    </React.Fragment >
  );
};
