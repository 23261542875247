import { AppBar, Text, Title, ListPosts, Footer, VejaTambem, Modal } from "components";
import React from "react";
import {
  Image,
  PostWrapper,
  Content,
  List,
  ListItem,
  WrapperRow,
} from "./VoceSabia.styles";
import Image01 from "assets/images/posts/post07/image01.jpg";
import Image02 from "assets/images/posts/post07/image02.jpg";
import Image03 from "assets/images/posts/post07/image03.jpg";
import Image04 from "assets/images/posts/post07/image04.png";
import { Box } from "@mui/material";

export const VoceSabia: React.FC = () => {

  const [openImg01, setOpenImg01] = React.useState(false);
  const [openImg02, setOpenImg02] = React.useState(false);
  const [openImg03, setOpenImg03] = React.useState(false);
  const [openImg04, setOpenImg04] = React.useState(false);
  // const [openImg05, setOpenImg05] = React.useState(false);

  const handleOpenImg1 = () => setOpenImg01(true);
  const handleOpenImg2 = () => setOpenImg02(true);
  const handleOpenImg3 = () => setOpenImg03(true);
  const handleOpenImg4 = () => setOpenImg04(true);
  // const handleOpenImg5 = () => setOpenImg05(true);

  const handleCloseImg1 = () => setOpenImg01(false);
  const handleCloseImg2 = () => setOpenImg02(false);
  const handleCloseImg3 = () => setOpenImg03(false);
  const handleCloseImg4 = () => setOpenImg04(false);
  // const handleCloseImg5 = () => setOpenImg05(false);
  return (
    <React.Fragment>
      <AppBar active="" />
      <PostWrapper>
        <ListPosts selected={6} />
        <Content>
          <Title variant="h1">Você Sabia?</Title>
          <Text>
            Existem muitas dúvidas sobre o que é recomendado ou não para quem tem diabetes.
            Isso vai desde a alimentação até alguns hábitos normais como o fato de dormir.
            Veja como isso impacta no  diabetes!
          </Text>
          <PostWrapper className="postwrapper">
            <Image onClick={handleOpenImg4} className="small_size" src={Image04} alt="Imagem 04." />
            <Modal open={openImg04} onClose={handleCloseImg4}>
              <Image className="modal" src={Image04} alt="Imagem 04." />
            </Modal>
          </PostWrapper>
          <Text className="title">Álcool</Text>
          <Text>
            O álcool, assim como outros vícios, não faz bem à saúde. Para quem tem diabetes aqui vale a mesma dica: o equilíbrio.
            Consumir muito álcool pode causar uma diminuição brusca da glicose, levando até o coma.  Por isso, o recomendado é que você
            consuma bebida alcoólica com moderação: uma taça de vinho, uma cerveja de lata pequena.
          </Text>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg1} src={Image01} alt="Imagem 01." />
              <Modal open={openImg01} onClose={handleCloseImg1}>
                <Image className="modal" src={Image01} alt="Imagem 01." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                Ah e lembre-se de verificar a glicemia antes e durante o consumo do álcool e tenha algum carboidrato de rápida absorção para correção de hipoglicemia, caso aconteça.
              </Text>
              <Text>
                Vale destacar que se estiver em jejum e for consumir bebida alcoólica, poderá ter hipoglicemia mais rápido, pois a absorção do álcool aumenta quando está em jejum.
              </Text>
            </Box>
          </WrapperRow>
          <Title className="title">Sono</Title>
          <Text>
            Manter um sono equilibrado é tão importante quanto a alimentação e impacta diretamente no manejo do diabetes.
            Não dormir bem faz com que o organismo não descanse e isso altera a glicemia. Além disso, afeta o bem estar físico, mental e emocional.
            Para dormir melhor, aqui tem algumas dicas:
          </Text>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg2} src={Image02} alt="Imagem 02." />
              <Modal open={openImg02} onClose={handleCloseImg2}>
                <Image className="modal" src={Image02} alt="Imagem 02." />
              </Modal>
            </Box>
            <Box className="text-container">
              <List>
                <ListItem>
                  Evite café e qualquer bebida estimulante depois das 18h;
                </ListItem>
                <ListItem>
                  Desligue a tela do celular, TV e computadores, pelo menos, 30 minutos antes de ir para cama;
                </ListItem>
                <ListItem>
                  Evite luzes intensas à noite;
                </ListItem>
                <ListItem>
                  Faça uma meditação já na cama para acalmar a mente;
                </ListItem>
                <ListItem>
                  Pratique exercício físico durante o dia;
                </ListItem>
                <ListItem>
                  Evite dormir longos períodos durante o dia;
                </ListItem>
                <ListItem>
                  Procure criar uma rotina para dormir.
                </ListItem>
              </List>
            </Box>
          </WrapperRow>
          <Text className="title">Peso</Text>
          <WrapperRow>
            <Box className="image-container">
              <Image onClick={handleOpenImg3} src={Image03} alt="Imagem 03." />
              <Modal open={openImg03} onClose={handleCloseImg3}>
                <Image className="modal" src={Image03} alt="Imagem 03." />
              </Modal>
            </Box>
            <Box className="text-container">
              <Text>
                O ganho de peso dificulta a ação da insulina e absorção da glicose nas células e, consequentemente, aumenta a glicemia.
                Por isso, recomenda-se uma alimentação equilibrada e saudável, a prática regular de atividade física para manter peso ideal e
                evitar o sobrepeso e obesidade, dentre outros inúmeros benefícios.
              </Text>
            </Box>
          </WrapperRow>
          <br></br>
          <Text className="title">
            REFERÊNCIAS
          </Text>
          <Text>
            ZADGURY,R. Podcast Diabetes - Sociedade Brasileira de Diabetes. Sono in: Walk to a better night of sleep: testing the relationship between physical activity and sleep. Sleep Health. 2019 Oct;5(5):487-494. Disponível em: SBDCast - tudo sobre diabetes para você (libsyn.com)
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Diretrizes da Sociedade Brasileira de Diabetes. SBD 2020-2021.
          </Text>
        </Content>
        <VejaTambem post={6} />
      </PostWrapper>
      <Footer page="publications" />
    </React.Fragment>
  );
};
