import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    "@media (max-width: 1100px)": {
        gap: '1rem',
        width: '350px',
    },
    "@media (max-width: 820px)": {
        width: '90%',
    },
})