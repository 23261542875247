import React from "react";
import { NoticesListHome } from "utils/NoticesListHome";
import {
  LinkDesktop,
  LinkMobile,
  ListPostsWrapper,
} from "./ListPosts.style";
import { ReactComponent as Icon01 } from "assets/icons/posts/01.svg";
import { ReactComponent as Icon02 } from "assets/icons/posts/02.svg";
import { ReactComponent as Icon03 } from "assets/icons/posts/03.svg";
import { ReactComponent as Icon04 } from "assets/icons/posts/04.svg";
import { ReactComponent as Icon05 } from "assets/icons/posts/05.svg";
import { ReactComponent as Icon06 } from "assets/icons/posts/06.svg";
import { ReactComponent as Icon07 } from "assets/icons/posts/07.svg";
import { ReactComponent as Icon08 } from "assets/icons/posts/08.svg";
import { ReactComponent as Icon09 } from "assets/icons/posts/09.svg";
import { keyGenerator } from "utils/uniqueKey";

interface ListPostsProps {
  selected?: number;
}
export const ListPosts: React.FC<ListPostsProps> = ({ selected }) => {
  return (
    <ListPostsWrapper>
      {NoticesListHome.map((notice, index) => (
        <React.Fragment>
          <LinkDesktop
            className={selected === index ? "selected" : ""}
            key={keyGenerator(index)}
            to={notice.page}
          >
            {notice.span}
          </LinkDesktop>
          <LinkMobile
            className={selected === index ? "selected" : ""}
            key={keyGenerator(index)}
            to={notice.page}
          >
            {index === 0 && <Icon01 />}
            {index === 1 && <Icon02 />}
            {index === 2 && <Icon03 />}
            {index === 3 && <Icon04 />}
            {index === 4 && <Icon05 />}
            {index === 5 && <Icon06 />}
            {index === 6 && <Icon07 />}
            {index === 7 && <Icon08 />}
            {index === 8 && <Icon09 />}
          </LinkMobile>
        </React.Fragment>
      ))}
    </ListPostsWrapper>
  );
};
