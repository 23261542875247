import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const ReferencesWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "2rem 6rem",
  "@media (max-width: 820px)": {
    padding: "2rem 2rem",
    flexDirection: "column",
    alignItems: "center",
  },
});
export const TextWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  maxWidth: "50%",
  minWidth: "630px",
  height: "100%",
  gap: "1rem",
  "@media (max-width: 820px)": {
    width: "90%",
    minWidth: "90%",
    alignItems: "center",
  },
});
export const Title = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#605E5E",
  textAlign: "left",
  "@media (max-width: 820px)": {
    textAlign: "center",
  },
});
export const Text = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "1rem",
  fontWeight: "normal",
  color: "#605E5E",
  textAlign: "justify",
  "&.bold": {
    fontWeight: "bold",
  },
  "& a": {
    color: "#605E5E",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& span": {
    "&.bold": {
      fontWeight: "bold",
    },
  },
  "@media (max-width: 820px)": {
    textAlign: "center",
  },
});
