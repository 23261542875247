import { styled } from "@mui/system";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { Link } from "react-router-dom";

export const AppBarWrapper = styled(Box)({
  maxWidth: "100%",
  height: "99px",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 780px)": {
    height: "80px",
  },
  "&#mobile": {
    display: "none",
    "&@media (min-width: 780px)": {
      display: "flex",
    },
  },
  "&#computer": {
    display: "flex",
    "&@media (max-width: 780px)": {
      display: "none",
    },
  },
});
export const ComputerWrapper = styled(Box)({
  width: "85%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  "@media (max-width: 780px)": {
    display: "none",
  },
});
export const ImgWrapperLink = styled(Link)({
  padding: "0",
  margin: "0",
  textDecoration: "pointer",
});
export const Image = styled("img")({
  width: "220px",
  // height: "63.92px",
  "@media (max-width: 780px)": {
    width: "144px",
    height: "51.136px",
  },
});
export const LinksContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "2rem",
  alignItems: "center",
  width: "clamp(300px, 100%, 447px)",
});
export const Links = styled(Link)({
  fontFamily: "avenir",
  fontSize: "1.5rem",
  fontWeight: "bold",
  textDecoration: "none",
  textTransform: "uppercase",
  color: "rgb(26,25,25)",
  textShadow: "0px 0px 20px rgba(255, 255, 255, 1)",
  "&.active": {
    color: "rgb(3,171,217)",
  },
  "&:hover": {
    color: "rgb(3,171,217)",
  },
});

export const MobileWrapper = styled(Box)({
  display: "none",
  "@media (max-width: 780px)": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "80px",
    padding: "0 2rem",
  },
  "@media (max-width: 300px)": {
    padding: "0 0.3rem",
  },
});
export const ButtonWithIcon = styled(IconButton)({
  color: "rgb(3,171,217)",
  "& svg": {
    fontSize: "2.5rem",
  },
  "&#menu": {
    position: "relative",
    top: "0",
    right: "0",
    zIndex: 999,
  },
});
export const MenuMobile = styled(Drawer)({
  "& .MuiDrawer-paper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "1.5rem",
    width: "30%",
    minWidth: "150px",
    "@media (max-width: 600px)": {
      width: "50%",
    },
    "@media (max-width: 400px)": {
      width: "60%",
    },
  },
});
export const MenuMobileList = styled(List)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0",
});

export const MenuMobileListItem = styled(ListItem)({});

export const MenuMobileItemButton = styled(ListItemButton)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0.6rem 2rem",
  borderBottom: "1px solid #FFFFFF",
  "&:hover": {
    backgroundColor: "transparent",
    borderBottom: "1px solid #d3d4d5",
  },
});
export const MenuMobileItemText = styled(Link)({
  fontFamily: "avenir, sans-serif",
  color: "rgb(26,25,25)",
  fontSize: "1rem",
  textTransform: "uppercase",
  textDecoration: "none",
  textAlign: "right",
  "&.active": {
    color: "rgb(32, 126,169)",
  },
  "&:hover": {
    color: "rgb(32, 126,169)",
  },
});
