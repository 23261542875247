import { PagesRoutes } from "features/constants";
import Image01 from "assets/images/capas-homepage/post01.png";
import Image02 from "assets/images/capas-homepage/post02.png";
import Image03 from "assets/images/capas-homepage/post03.png";
import Image04 from "assets/images/capas-homepage/post04.png";
import Image05 from "assets/images/capas-homepage/post05.png";
import Image06 from "assets/images/capas-homepage/post06.png";
import icon01 from "assets/icons/posts/01.svg";
import icon02 from "assets/icons/posts/02.svg";
import icon03 from "assets/icons/posts/03.svg";
import icon04 from "assets/icons/posts/04.svg";
import icon05 from "assets/icons/posts/05.svg";
import icon06 from "assets/icons/posts/06.svg";

export const NoticesList = [
  {
    title: "Alimentação saudável",
    span: "Alimentação",
    description:
      "Para muitas pessoas, diabetes é sinônimo de restrição alimentar. Na verdade, ter diabetes não significa que você não pode comer seu alimento favorito ou ter uma dieta restritiva. Não há nada que você não possa comer!",
    image: Image01,
    icon: icon01,
    page: PagesRoutes.POST01,
  },
  {
    title: "Atividade física",
    span: "Atividade física",
    description:
      "A atividade física é importantíssima para o ser humano ser mais saudável e no diabetes ela também traz resultados importantes, pois melhora a qualidade de vida, traz benefícios ao coração, diminui o peso, melhora a captação da glicose e fortalece o sistema imunológico.",
    image: Image02,
    icon: icon02,
    page: PagesRoutes.POST02,
  },
  {
    title: "Medicamentos",
    span: "Medicamentos",
    description:
      "O tratamento do diabetes requer que você seja fiel às suas medicações. Temos os comprimidos e a injeção de insulina que diariamente devem ser tomados, de acordo com a recomendação médica.",
    image: Image03,
    icon: icon03,
    page: PagesRoutes.POST03,
  },
  {
    title: "Vigiar as taxas",
    span: "Vigiar as taxas",
    description:
      "É preciso que você esteja sempre atento para se automonitorar, ou seja, você será seu próprio observador e terá que controlar sua glicose, além de outros sinais como a pressão arterial, começando a cumprir os cuidados necessários para viver bem!",
    image: Image04,
    icon: icon04,
    page: PagesRoutes.POST04,
  },
  {
    title: "Reduzindo riscos",
    span: "Reduzir riscos",
    description:
      "Aqui vamos te dizer alguns pontos importantes para prevenir possíveis complicações do diabetes e que são cuidados que você deve ter diariamente e outros, anualmente ou conforme orientação da equipe de saúde.",
    image: Image05,
    icon: icon05,
    page: PagesRoutes.POST05,
  },
  {
    title: "Resolvendo Problemas",
    span: "Resolver problemas",
    description:
      "A pessoa que tem diabetes pode passar por algumas situações que devem enfrentar alguns problemas. Vamos lá identificar essas situações de risco e saber o que fazer em cada uma delas.",
    image: Image06,
    icon: icon06,
    page: PagesRoutes.POST06,
  },
];
