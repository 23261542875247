import React from "react";
import { AppBar, Footer } from "components";
import {
  ReferencesWrapper,
  Text,
  TextWrapper,
  Title,
} from "./References.styles";

export const References: React.FC = () => {
  return (
    <React.Fragment>
      <AppBar active="referências" />
      <ReferencesWrapper>
        <TextWrapper>
          <Title>Referências</Title>

          <Text>
            American Diabetes Association. Disponível em:{" "}
            <a href="https://diabetes.org/healthy-living/recipes-nutrition">
              https://diabetes.org/healthy-living/recipes-nutrition
            </a>
            . Acesso em 26 set 2022.
          </Text>
          <Text>
            AMERICAN ASSOCIATION OF DIABETES EDUCATORS. An effective model of
            diabetes care and education: revising the AADE7 Self-Care
            Behaviors®. The Diabetes Educator, v. 46, n. 2, p. 139-160, 2020.
          </Text>
          <Text>
            BANCA, R. O; MARRONI, M. S; OLIVEIRA, M. C. et al. Técnicas de
            aplicação de insulina. Editor chefe: Marcello Bertoluci. Última
            revisão em: 03/02/2022 DOI: 10.29327/557753.2022-4.
          </Text>
          <Text>
            Cardio DF – Cardiologia e saúde cardiovascular em Brasília (DF). 10
            melhores frutas para diabéticos (e 5 proibidas na diabetes). Vídeo
            no youtube:{" "}
            <a href="https://www.youtube.com/watch?v=Zb-wV3PuX84">
              https://www.youtube.com/watch?v=Zb-wV3PuX84
            </a>
            .
          </Text>
          <Text>
            E-books SBD. Autocuidado e diabetes em tempos de COVID-19.
            Disponível em:{" "}
            <a href="https://diabetes.org.br/e-books-publico/">
              E-books Público - Sociedade Brasileira de Diabetes
            </a>
            .
          </Text>
          <Text>
            LARA, P; COONSOLI, M. Entendendo os rótulos do alimentos. Folder da
            Sociedade Brasileira de Diabetes. Disponível em:{" "}
            <a href="https://diabetes.org.br/e-books-publico/">
              E-books Público - Sociedade Brasileira de Diabetes
            </a>
            .
          </Text>
          <Text>
            MALERBI, F.; ANDRADE, R., MORALES, P. et al. Manejo da retinopatia
            diabética. Diretriz da Sociedade Brasileira de Diabetes. Última
            revisão em: 21/12/2021 DOI: 10.29327/557753.2022-17.
          </Text>
          <Text>
          MINISTÉRIO DA SAÚDE (BR). Secretaria de Atenção Básica. Departamento de Atenção Básica. Guia alimentar para a população brasileira [Internet]. 
          2. ed. Brasília: Ministério da Saúde; 2014 [citado 2020 jan 9]. 156 p. Disponível em: Disponível em:{" "}
            <a href="https://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdf">
            https://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdf
            </a>
            .
          </Text>
          <Text>
            OLIVEIRA, S. K. P. Cuidados com os pés: o que o enfermeiro deve
            orientar e a pessoa com diabetes precisa saber? Disponível em:
            <a href="https://diabetes.org.br/cuidados-com-os-pes-o-que-o-enfermeiro-deve-orientar-e-a-pessoa-com-diabetes-precisa-saber/">
              Cuidados com os pés: o que o enfermeiro deve orientar e a pessoa
              com diabetes precisa saber? - Sociedade Brasileira de Diabetes
            </a>
            . Acesso em 01 dez 2020.
          </Text>
          <Text>
            OLIVEIRA, S. M. S.; GUIMARÃES, D. B.; WARDI, M. M et al. Orientações
            Nutricionais para analfabetos. Instituto de ensino e pesquisa santa
            casa BH centro de diabetes - santa casa bh. E-book Sociedade
            Brasileira de Diabetes. Disponível em:{" "}
            <a href="https://materiais.diabetes.org.br/orientacoes-nutricionais-para-analfabetos#rd-box-joq3m2vv">
              Orientações nutricionais para analfabetos (diabetes.org.br)
            </a>
            . Acesso em 01 dez 2020.
          </Text>
          <Text>
            ROLIM,L. C.; THYSSEN, P. J.; FLUMIGNAN, R.L.G et al. Diagnóstico e
            tratamento da neuropatia periférica diabética. Diretriz da Sociedade
            Brasileira de Diabetes. Ultima revisão em: 03/03/2022 DOI:
            10.29327/557753.2022-14
          </Text>
          <Text>
            SÁ, J. R.; CANANI, L. H.; RANGEL, E. B., et al. Doença renal do
            diabetes. Diretriz da Sociedade Brasileira de Diabetes. Última
            revisão em: 28/12/2021 DOI: 10.29327/557753.2022-18.
          </Text>
          <Text>
            SANTOMAURO, A.T; SANTOMAURO JUNIOR, A. G; RADUAN, R. A. Diagnóstico
            e Tratamento da Cetoacidose Diabética Euglicêmica in: Diretriz da
            Sociedade Brasileira de Diabetes. 20/01/2022 DOI:
            10.29327/557753.2022-22.
          </Text>
          <Text>
            Sociedade Brasileira de Diabetes. Diretrizes da Sociedade Brasileira
            de Diabetes. SBD 2020-2021.
          </Text>
          <Text>
            Sociedade Brasileira de Imunização. Calendário vacinal SBIm
            2022/2023 Do nascimento à terceira idade. Disponível em:{" "}
            <a href="https://sbim.org.br/calendarios-de-vacinacao">
              Calendários de Vacinação - SBIm
            </a>
            .
          </Text>
          <Text>
          Sociedade Brasileira de Imunização. Calendários de vacinação SBIm pacientes especiais – 2022-2023 SBIm – 
          Sociedade Brasileira de Imunizações sbim.org.br/calendarios-de-vacinacao. Disponível em:{" "}
            <a href="https://sbim.org.br/images/calendarios/calend-sbim-pacientes-especiais.pdf.">
            https://sbim.org.br/images/calendarios/calend-sbim-pacientes-especiais.pdf.
            </a>
          </Text>
          <Text>
            ZADGURY,R. Podcast Diabetes - Sociedade Brasileira de Diabetes. Sono
            in: Walk to a better night of sleep: testing the relationship
            between physical activity and sleep. Sleep Health. 2019
            Oct;5(5):487-494. Disponível em:{" "}
            <a href="https://podcastsbd-publico.libsyn.com/">
              SBDCast - tudo sobre diabetes para você (libsyn.com)
            </a>
            .
          </Text>
        </TextWrapper>
      </ReferencesWrapper>
      <Footer page="references" />
    </React.Fragment>
  );
};
