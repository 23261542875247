export const keyGenerator = (index: number) => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const hash: { [key: string]: string } = {};

  // Gera uma tabela hash aleatória
  for (let i = 0; i < caracteres.length; i++) {
    hash[caracteres[i]] = caracteres[Math.floor(Math.random() * caracteres.length)];
  }

  let chave = '';
  const timestamp = new Date().getTime().toString();

  // Combina caracteres aleatórios, tempo e índice para formar a chave
  for (let i = 0; i < timestamp.length; i++) {
    const char = timestamp.charAt(i);
    chave += hash[char] || char;
  }

  chave += '_' + index.toString();

  return chave;
}