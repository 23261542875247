import { Button, Snackbarr, Title } from "components";
import { api } from "api/api";
import React from "react";
import {
  CommentInput,
  CommentWrapper,
  Container,
  HelperText,
  Input,
  Row,
} from "./SendComment.styles";
import { validateEmail } from "utils/regex";
import { Box } from "@mui/material";

interface SendCommentProps {
  handleReload: (value: boolean) => void;
  postNumber: number;
  title?: string;
}

export const SendComment: React.FC<SendCommentProps> = ({
  handleReload,
  postNumber,
  title = "Deixe seu comentário",
}) => {
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  let emailValid = validateEmail(email);
  let nameValid = name.length > 0;
  let textValid = comment.length > 0;
  const emailHelp = React.useRef<HTMLParagraphElement>(null);
  const nameHelp = React.useRef<HTMLParagraphElement>(null);
  const textHelp = React.useRef<HTMLParagraphElement>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

  const handleOpenSnackbar = (value: boolean) => {
    setOpenSnackbar(value);
  };
  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  const onButtonClick = async () => {
    if (emailValid && nameValid && textValid) {
      let styleName = "";
      let separeName = name.split(" ");
      separeName.forEach((name) => {
        if (name) styleName += name[0].toUpperCase() + name.substring(1) + " ";
      });
      let styleEmail = email.toLowerCase();
      let styleComment = comment[0].toUpperCase() + comment.substring(1);
      const sendPost = {
        nome: styleName,
        email: styleEmail,
        texto_comentario: styleComment,
        idPost: postNumber,
      };
      await api
        .post("comentarios/cadastros", sendPost)
        .finally(() => {
          setEmail("");
          setName("");
          setComment("");
          handleReload(true);
        });
    } else {
      handleOpenSnackbar(true);
    }
  };

  return (
    <Container component="section">
      <CommentWrapper>
        <Title variant="h1" className="center">{title}</Title>
        <Row sx={{padding: "2rem 0 0 0"}}>
          <Box className="name">
            <Input
              placeholder="Seu nome"
              value={name}
              onChange={handleNameChange}
              type="text"
              onBlur={(_) => {
                if (!nameValid) {
                  if (nameHelp.current) {
                    nameHelp.current.style.display = "block";
                  }
                } else {
                  if (nameHelp.current) {
                    nameHelp.current.style.display = "none";
                  }
                }
              }}
              onFocus={(_) => {
                if (nameHelp.current) {
                  nameHelp.current.style.display = "block";
                }
              }}
            />
            <HelperText ref={nameHelp} className={nameValid ? "valid" : "invalid"}>
              {nameValid ? "" : "nome invalido"}
            </HelperText>
          </Box>

          <Box className="email">
            <Input
              placeholder="Seu email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              onBlur={(_) => {
                if (!emailValid) {
                  if (emailHelp.current) {
                    emailHelp.current.style.display = "block";
                  }
                } else {
                  if (emailHelp.current) {
                    emailHelp.current.style.display = "none";
                  }
                }
              }}
              onFocus={(_) => {
                if (emailHelp.current) {
                  emailHelp.current.style.display = "block";
                }
              }}
            />
            <HelperText
              ref={emailHelp}
              className={emailValid ? "valid" : "invalid"}
            >
              {emailValid ? "" : "email invalido"}
            </HelperText>
          </Box>
        </Row>

        <Box className="comment-input">
          <CommentInput
            placeholder="Digite seu comentário"
            value={comment}
            onChange={handleCommentChange}
            onBlur={(_) => {
              if (!textValid) {
                if (textHelp.current) {
                  textHelp.current.style.display = "block";
                }
              } else {
                if (textHelp.current) {
                  textHelp.current.style.display = "none";
                }
              }
            }}
            onFocus={(_) => {
              if (textHelp.current) {
                textHelp.current.style.display = "block";
              }
            }}
          />
          <HelperText ref={textHelp} className={textValid ? "valid" : "invalid"}>
            {textValid ? "" : "comentário invalido"}
          </HelperText>
        </Box>
        <Button bgColor="comment" onClick={onButtonClick} disabled={false}>
          Enviar
        </Button>
      </CommentWrapper>
      <Snackbarr
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        color="error"
        message="Preeencha todas as informações corretamente!"
      />
    </Container>
  );
};
