import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex",
  alignItems: "flex-end",
  maxWidth: "100%",
});
export const CommentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "calc(100% - 4rem)",
  padding: "2rem",
  "@media (max-width: 620px)": {
    padding: "1rem",
    width: "calc(100% - 2rem)",
  },
});

export const ResponseToggleButton = styled("button")({
  alignSelf: "flex-end",
  fontFamily: "var(--font)",
  fontSize: "calc(var(--text-size) * 0.8)",
  fontWeight: "var(--button-weight)",
  color: "var(--text-dark)",
  textAlign: "right",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});
export const ReplyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  width: "85%",
  gap: "2rem",
  "&.hide": {
    display: "none",
  },
});
export const ReplyWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--light-gb)",
  width: "70%",
  borderRadius: "var(--border-radius)",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
  padding: "1rem",
});
export const Input = styled("input")({
  width: "100%",
  fontFamily: "var(--font)",
  padding: "0.2rem",
  borderRadius: "var(--border-radius)",
  margin: "0.5rem 0",
  fontSize: "calc(var(--text-size) * 0.8)",
});
export const CommentContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "150px calc(100% - 150px)",
  gridTemplateRows: "auto auto",
  gridTemplateAreas: `
    "profile text"
    "profile time"
  `,
  width: "100%",
  gap: "1rem",
  "& .comment-profile": {
    gridArea: "profile",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "flex-start",
    alignSelf: "flex-start",
    width: "150px",
    "& .response": {
      fontSize: "calc(var(--text-size) * 0.8)",
    },
    "& .comment": {
      fontSize: "calc(var(--text-size) * 0.8)",
    },
  },
  "& .comment-text": {
    gridArea: "text",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    justifySelf: "flex-start",
    alignSelf: "center",
    width: "90%",
    "& .comment": {
      fontSize: "calc(var(--text-size) * 0.9)",
    },
  },
  "& .comment-time": {
    gridArea: "time",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    justifySelf: "flex-end",
    width: "100%",
    "& p": {
      fontSize: "calc(var(--text-size) * 0.8)",
    },
  },
  "@media (max-width: 520px)": {
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto auto auto",
    gridTemplateAreas: `
      "profile"
      "text"
      "time"
    `,
    "& .comment-profile": {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      "& .response": {
        fontSize: "calc(var(--text-size) * 0.8)",
      },
      "& .comment": {
        fontSize: "calc(var(--text-size) * 0.8)",
        textAlign: "center !important",
      },
    },
    "& .comment-text": {
      width: "100%",
      "& .comment": {
        fontSize: "calc(var(--text-size) * 0.9)",
      },
    },
    "& .comment-time": {
      width: "100%",
      "& p": {
        fontSize: "calc(var(--text-size) * 0.8)",
      },
    },
  },
});
export const CommentInput = styled("textarea")({
  width: "100%",
  fontFamily: "var(--font)",
  padding: "0.2rem",
  borderRadius: "var(--border-radius)",
  margin: "0.5rem 0",
  fontSize: "calc(var(--text-size) * 0.8)",
});
export const HelperText = styled("span")({
  alignSelf: "flex-start",
  fontFamily: "var(--font)",
  color: "#454545",
  display: "none",
  fontSize: "calc(var(--text-size) * 0.8)",
  "&.valid": {
    color: "green",
  },
  "&.invalid": {
    color: "red",
  },
});
