import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ItemContainer = styled(Link)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  backgroundColor: "#fff",
  height: "100px",
  width: "100%",
  maxWidth: "100%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  textDecoration: "none",
  transition: "all 0.5s ease-in-out",
  "&:hover": {
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    "& h1": {
      color: "#000",
    },
  },
  "@media (max-width: 985px)": {
    height: "75px",
  },
  "@media (max-width: 820px)": {
    height: "120px",
  },
  "@media (max-width: 620px)": {
    height: "100px",
  },
  "@media (max-width: 360px)": {
    height: "75px",
  },
  "@media (max-width: 320px)": {
    height: "60px",
  },
});

export const Image = styled("img")({
  width: "150px",
  height: "100px",
  objectPosition: "center",
  objectFit: "cover",
  "@media (max-width: 985px)": {
    width: "112.5px",
    height: "75px",
  },
  "@media (max-width: 820px)": {
    width: "180px",
    height: "120px",
  },
  "@media (max-width: 620px)": {
    width: "150px",
    height: "100px",
  },
  "@media (max-width: 360px)": {
    width: "112.5px",
    height: "75px",
  },
  "@media (max-width: 320px)": {
    width: "90px",
    height: "60px",
  },
});

export const TextContainer = styled(Box)({
  width: "100%",
  display: "grid",
  placeItems: "center",
  placeContent: "center",
  padding: "0.5rem",
});
export const Title = styled(Typography)({
  fontFamily: "Avenir, sans-serif",
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: "#605e5e",
  textAlign: "left",
  transition: "all 0.5s ease-in-out",
  "@media (max-width: 1100px)": {
    fontSize: "1rem",
  },
  "@media (max-width: 820px)": {
    fontSize: "1.2rem",
  },
  "@media (max-width: 620px)": {
    fontSize: "1rem",
  },
  "@media (max-width: 360px)": {
    fontSize: "0.8rem",
  },
  "@media (max-width: 320px)": {
    fontSize: "0.7rem",
  },
});
